import { Card, TextField } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';

type Props = {
    today: string,
    setToday: (date: string) => void;
}

export function DateCard({ today, setToday }: Props) {
    const history = useHistory();

    return <Card style={{width:'200px', height:'50px'}}>
        <TextField
            variant="standard"
            type="date"
            defaultValue={today}
            InputLabelProps={{
                shrink: true,
            }}
            data-testid="date-input"
            onChange={(event) => {
                history.push(`?date=${event.target.value}`);
                setToday(event.target.value);
            }} />
    </Card>;
}