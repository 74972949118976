import { Edit } from '@mui/icons-material';
import { Button, Card } from '@mui/material';
import { EditableMarkdown } from 'common/EditableMarkdown';
import { InputTextField } from 'common/InputTextField';
import { updateCurrentUser, updateShopItem } from 'helpers/DataStoreHelper';
import { ShopItem } from 'models';
import React, { useState } from 'react';

type Props = {
    shopItem: ShopItem,
    currentCoins: number|null|undefined,
    username: string,
    setCurrentCoins: (newCoins: number) => void,
    pushError: (error: string) => void
}

function getNewCoinAmount(currentCoins: number|null|undefined, cost: number|null|undefined) {
    if (!currentCoins || currentCoins <= 0 ) {
        return 0;
    }

    if (!cost) {
        return currentCoins;
    }

    return currentCoins - cost;
}

export default function ShopReward({ shopItem, currentCoins, username, setCurrentCoins, pushError }: Props) {
    const [ editing, setEditing ] = useState(false);
    const [ unsaved, setUnsaved ] = useState(false);
    const [ currentName, setCurrentName ] = useState(shopItem.name);
    const [ currentCost, setCurrentCost ] = useState(shopItem.cost);

    return <Card style={{ backgroundColor: 'white', minWidth: '300px' }}>
        {unsaved && <><Button
            style={{backgroundColor: 'green'}}
            onClick={() => {
                updateShopItem(shopItem.id, {
                    name: currentName,
                    cost: currentCost
                }, pushError);
                setUnsaved(false);
                setEditing(false);
            }}
        >
            save
        </Button><br/></>}
        <Button color="primary" onClick={() => setEditing(!editing)} style={{float:'left'}}>
            <Edit/>
        </Button>
        <Button color="primary"
            onClick={() => {
                const newCoinAmount = getNewCoinAmount(currentCoins, currentCost);
                updateCurrentUser({ coins: newCoinAmount }, username, pushError).then(() => {
                    setCurrentCoins(newCoinAmount);
                });
            }}
            disabled={!currentCoins || currentCoins <= 0 || !currentCost}
        >
            Redeem
        </Button>
        <EditableMarkdown
            isEditing={editing}
            current={currentName ? currentName : 'Unnamed'}
            setCurrent={(newName) => {
                setCurrentName(newName);
                setUnsaved(true);
            }}
            label="Name"
            multiline={false}
        />
        {
            editing
                ? <InputTextField
                    name="shopRewardCost"
                    label="Cost"
                    style={{width:'15%'}}
                    value={currentCost}
                    onChangeEvent={(value) => {
                        setCurrentCost(parseFloat(value));
                        setUnsaved(true);
                    }}
                    isNumber={true}
                />
                : <p>Costs {currentCost ? currentCost : 0} coins</p>
        }
    </Card>;
}