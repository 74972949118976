import React, { ReactNode, useState } from 'react';
import Popover from '@mui/material/Popover';
import { Box, Card } from '@mui/material';
import { testIds } from 'constants/TestConstants';

type Props = {
    height: number,
    text: string|ReactNode,
    popoverText: string|ReactNode
};

export default function MouseOverPopover({ height, text, popoverText }: Props) {
    const [ anchorEl, setAnchorEl ] = useState<Element | null>(null);

    const handlePopoverOpen = (event: React.SyntheticEvent) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    return <div>
        <Box
            width={'100%'}
            height={height}
            aria-haspopup="true"
            onMouseEnter={(event: React.SyntheticEvent) => handlePopoverOpen(event)}
            onMouseLeave={() => handlePopoverClose()}
            data-testid={testIds.mouseOverBox}
        >
            {text}
        </Box>
        <Popover
            id="mouse-over-popover"
            style={{ pointerEvents: 'none'}}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            onClose={() => handlePopoverClose()}
            disableRestoreFocus
            data-testid={testIds.mouseOverPopover}
        >
            <Box>
                <Card style={{width:600, height:100, overflow:'hidden'}}>{popoverText}</Card>
            </Box>
        </Popover>
    </div>;
}
