import { SortEntry } from 'models';

export const moveItemInList = (list: SortEntry[], startIndex: number, endIndex: number): SortEntry[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
};

// Use to convert (string|null)[] to string[]
export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined;
}