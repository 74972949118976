import { Fridge, MealType, Preference, Recipe } from 'models';
import React from 'react';
import MealScheduleBlock from './MealScheduleBlock';

type Props = {
    recipesResponse: Recipe[],
    fridgeResponse: Fridge,
    setRecipeId: (id: string|null, type: MealType) => void,
    preferences: Preference,
    selectedBreakfastRecipeId: string,
    selectedLunchRecipeId: string,
    selectedDinnerRecipeId: string
}

export default function ScheduledInformationComponent({
    recipesResponse,
    fridgeResponse,
    setRecipeId,
    preferences,
    selectedBreakfastRecipeId,
    selectedLunchRecipeId,
    selectedDinnerRecipeId
}: Props) {
    return <div style={{textAlign: 'center'}}>
        It looks like this is the first time youve scheduled today, would you like to schedule any of the following? <br/>
        <MealScheduleBlock
            recipesResponse={recipesResponse}
            fridgeResponse={fridgeResponse}
            setRecipeId={(id: string|null) => setRecipeId(id, MealType.BREAKFAST)}
            mealType={MealType.BREAKFAST}
            preferences={preferences}
            scheduledRecipeId={selectedBreakfastRecipeId}
        />
        <MealScheduleBlock
            recipesResponse={recipesResponse}
            fridgeResponse={fridgeResponse}
            setRecipeId={(id: string|null) => setRecipeId(id, MealType.LUNCH)}
            mealType={MealType.LUNCH}
            preferences={preferences}
            scheduledRecipeId={selectedLunchRecipeId}
        />
        <MealScheduleBlock
            recipesResponse={recipesResponse}
            fridgeResponse={fridgeResponse}
            setRecipeId={(id: string|null) => setRecipeId(id, MealType.DINNER)}
            mealType={MealType.DINNER}
            preferences={preferences}
            scheduledRecipeId={selectedDinnerRecipeId}
        />
    </div>;
}