import React, { useCallback, useEffect, useState } from 'react';
import 'App.css';
import { Redirect, useHistory } from 'react-router-dom';
import Preferences from './Preferences';
import { DataStore } from 'aws-amplify/datastore';
import { Preference } from 'models';
import { convertCategories, updatePreference } from 'helpers/DataStoreHelper';
import { useErrorHook } from 'common/ErrorHook';
import { ErrorPopUps } from 'common/ErrorPopUps';
import {
    AppBar,
    Chip,
    CircularProgress,
    CssBaseline,
    IconButton,
    Toolbar
} from '@mui/material';
import { AppBarProfile } from 'common/AppBarProfile';
import { ArrowBack } from '@mui/icons-material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import SettingsDrawer from './SettingsDrawer';
import { testIds } from 'constants/TestConstants';

const tabNames = [
    'General',
    'Categories',
    'Scheduling'
];

export default function Settings({ ...props }) {
    const { username, triggerAuth, isBetaUser } = props;
    const { errors, pushError, cancelError } = useErrorHook([]);
    const [ upperUnsaved, setUpperUnsaved ] = useState(false);
    const [ preferenceResponse, setPreferenceResponse ] = useState(null);
    const [ tab, setTab ] = useState(0);
    const history = useHistory();

    const savePreferenceUpdates = useCallback((preferenceUpdates) => {
        updatePreference(preferenceResponse.id, preferenceUpdates, pushError);
    }, [ preferenceResponse ]);

    useEffect(() => {
        if (username) {
            DataStore.query(Preference)
                .then((response) => {
                    if (response.length < 1) {
                        const newPreference = {
                            user: username
                        };
                        DataStore.save(new Preference(newPreference))
                            .catch((error) => pushError(error));
                        setPreferenceResponse(newPreference);
                    } else {
                        setPreferenceResponse(convertCategories(response[0]));
                    }
                })
                .catch((response) => {
                    console.log(response);
                    setPreferenceResponse(response);
                    pushError(response);
                });
        }

        if (isBetaUser && !tabNames.includes('Meal Prep')) {
            tabNames.push('Meal Prep');
        }
    }, []);

    if (!username) {
        return <div data-testid={testIds.redirect}>
            <Redirect to='/info'/>
        </div>;
    }

    return <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div className="App">
            <div style={{ display: 'flex' }}>   
                <header className="App-header">
                    <CssBaseline />
                    <AppBar
                        position="fixed"
                        style={{
                            backgroundColor: '#303538',
                            zIndex: 1,
                        }}
                    >
                        <Toolbar>
                            <IconButton
                                style={{width: '50px', display: 'inline-block',  textAlign:'left', color:'white'}}
                                onClick={() => {
                                    if (upperUnsaved) {
                                        if (confirm('Unsaved changes, are you sure you want to leave the page?')) {
                                            history.push('/');
                                        }
                                    } else {
                                        history.push('/');
                                    }
                                }}
                                size="large">
                                <ArrowBack/>
                            </IconButton>
                            { isBetaUser && <Chip label="Beta User" style ={{ backgroundColor: '#F06058', float: 'left'}} /> }
                            <AppBarProfile isBetaUser={isBetaUser} username={username} triggerAuth={triggerAuth} submitAnIssue={true}/>
                        </Toolbar>
                    </AppBar>
                </header>
                <SettingsDrawer
                    tabNames={tabNames}
                    tab={tab}
                    setTab={setTab}
                />
                <main style={{ 
                    flexGrow: 1,
                    padding: '32px',
                    verticalAlign: 'top',
                    textAlign: 'left',
                    color: '#E6E6E6',
                    backgroundColor: '#282c34'
                }}>
                    <Toolbar/>
                    <ErrorPopUps errors={errors} cancelError={cancelError} />
                    { preferenceResponse ? <Preferences
                        username={username}
                        tab={tab}
                        preferences={preferenceResponse}
                        // TODO do we need to change preference response? It doesnt even get saved
                        // onPreferencesChanged={(preferences) => setPreferenceResponse(preferences)}
                        savePreferences={(updates) => savePreferenceUpdates(updates)}
                        isBetaUser={isBetaUser}
                        pushError={(error, isSuccess=false) => pushError(error, isSuccess)}
                        setUpperUnsaved={(unsaved) => setUpperUnsaved(unsaved)}
                    /> : <CircularProgress />}
                </main>
            </div>
        </div>
    </LocalizationProvider>;
}
