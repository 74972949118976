import React from 'react';
import { Box } from '@mui/material';
import { isDueDateClose } from 'helpers/TaskHelpers';
import { ErrorOutlined } from '@mui/icons-material';
import { formatMinutes } from 'helpers/TimeHelper';
import { getTaskColor } from 'helpers/ColorHelper';
import { CALENDAR_TASK_OFFSET } from 'constants/TaskConstants';
import MouseOverPopover from 'common/MouseOverPopover';
import CategoryChip from './CategoryChip';
import { Category, Task } from 'models';

const TASK_POPOVER_THRESOLD = 45;

type Props = {
    task: Task,
    taskTime: number,
    today: string,
    timeOfDayMinutes: number,
    categories: Category[]
}

export default function CalendarTask({ task, taskTime, today, timeOfDayMinutes, categories }: Props) {
    const dueDateText = isDueDateClose(task, today) // TODO styling on this is kinda messed up
        ? <div style={{display: 'flex', alignItems: 'center', paddingTop: 0, marginTop: 0}}><ErrorOutlined/><p>TASK IS APPROACHING DUE DATE</p></div> 
        : '';
    const categoryTag = <CategoryChip categoryId={task.category} categories={categories} />;

    const taskText = <div style={{justifyContent: 'center', textAlign: 'center'}}>
        {dueDateText}
        {`${task.taskName} at ${formatMinutes(taskTime)}`}
        {categoryTag}
    </div>;

    const taskDuration = task.duration ? task.duration : 0;
    const brightness = taskTime + taskDuration < timeOfDayMinutes ? '90%' : '100%';
    
    // TODO blockedOnTask
    return <Box
        key={task.taskName}
        bgcolor={getTaskColor(task, today)}
        borderRadius={1}
        border={1}
        borderColor="text.primary"
        position="absolute"
        width="min(35vw, 70%)"
        height={task.duration + 'px'}
        top={taskTime+CALENDAR_TASK_OFFSET}
        style={{overflow: 'hidden', filter: `brightness(${brightness})`}}
    >
        <MouseOverPopover text={taskDuration >= TASK_POPOVER_THRESOLD ? taskText : ''} popoverText={taskText} height={taskDuration}/>
    </Box>;
}