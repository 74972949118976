import { IngredientUnit } from 'constants/MealConstants';
import { ValueUnits } from 'mealprep/Common/ValueUnits';
import { Ingredient, Recipe } from 'models';

export function getRecipe(recipeId: string|null|undefined, recipesResponse: Recipe[]) {
    if (recipeId) {
        const recipesWithId = [...recipesResponse].filter((recipe) => recipe.id === recipeId);
        if (recipesWithId.length < 1) {
            throw 'No recipes found with scheduled id!';
        }
        return recipesWithId[0];
    }
    return null;
}

export type FridgeNameToIng = {
    [name: string]: Ingredient
}
export function getOwnedIngredientsPercent(ingredients: (Ingredient|null)[]|null|undefined, fridgeNameToIng: FridgeNameToIng) {
    let totalRequired = 0;
    let totalOwned = 0;

    if (!ingredients) {
        return 0;
    }

    ingredients.forEach((ingredient) => {
        if (ingredient) {
            totalRequired += 1;
        
            const ingredientUnit = ingredient.unit && IngredientUnit[ingredient.unit] ? IngredientUnit[ingredient.unit] : ingredient.unit;
            const ingName = ingredient.name ? ingredient.name.toLowerCase() : 'unknown';
            const fridgeIng = fridgeNameToIng[ingName];

            let unitizedAmount = 0;
            if (ingredientUnit && fridgeIng && fridgeIng.amount) {
                if (fridgeIng.unit) {
                    const valUnits = new ValueUnits(fridgeIng.amount, fridgeIng.unit);
                    unitizedAmount = valUnits.toUnits(ingredientUnit).value;
                } else {
                    console.log('Fridge ingredient does not have unit.');
                    unitizedAmount = fridgeIng.amount;
                }
            }

            // the percentage will just be the number of individual ingrdients a user
            //  has enough of
            totalOwned += unitizedAmount < (ingredient.amount || 0) ? 0 : 1;
        }
    });

    return Math.round((totalOwned / totalRequired) * 100);
}