
import { Add } from '@mui/icons-material';
import { Button, Card, CircularProgress, List, ListItemButton, Modal } from '@mui/material';
import { DataStore } from 'aws-amplify/datastore';
import { modalStyle } from 'constants/StylingConstants';
import { testIds } from 'constants/TestConstants';
import { Task } from 'models';
import React, { useEffect, useMemo, useState } from 'react';
import { convertDailys } from 'tasks/taskCommon/ConfigCommon';
import Divider from '@mui/material/Divider';

type Props = {
    parentId: string,
    handleClose: () => void,
    addSubtask: (task: null | string) => void,
    pushError: (error: string) => void
}

export default function AddSubtaskModal({ parentId, handleClose, addSubtask, pushError }: Props) {
    const [ selectedTask, setSelectedTask ] = useState<null | string>(null);
    const [ tasks, setTasks ] = useState<null | Task[]>();

    useEffect(() => {
        DataStore.query(Task, t => t.archived.ne(true))
            .then((response) => {
                setTasks(
                    convertDailys(response).filter((task: Task) => task.id !== parentId)
                );
            })
            .catch((response) => pushError(response));
    }, [parentId]);

    const modalContent = useMemo(() => { 
        if(!tasks) {
            return <div style={{width:'100%'}}><CircularProgress data-testid={testIds.circularProgress}/></div>;
        } else {
            return <div>
                <h3>Choose subtask</h3>
                <List>
                    <ListItemButton
                        key={'noneblocker'}
                        selected={selectedTask === null}
                        onClick={() => setSelectedTask(null)}
                    >
                        <Add/> Create new task
                    </ListItemButton>
                    <Divider />
                    {tasks && tasks.map((task) => <ListItemButton
                        key={task.id + 'blocker'}
                        selected={selectedTask === task.id}
                        onClick={() => setSelectedTask(task.id)}
                    >
                        {task.taskName}
                    </ListItemButton>)}
                </List>
                <div style={{ position: 'sticky', bottom: 0, background: 'white' }}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => addSubtask(selectedTask)}
                    >
                        Add Subtask
                    </Button>
                </div>
            </div>;
        }
    }, [ tasks, selectedTask ]);

    return <Modal
        open={true}
        onClose={handleClose}
        data-testid={testIds.modal}
    >
        <Card style={modalStyle}>
            {modalContent}
        </Card>
    </Modal>;
}