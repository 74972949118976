import { ErrorBoundary } from 'information/ErrorBoundary';
import React from 'react';
import { createRoot } from 'react-dom/client';
import Routing from 'Routing';
import './index.css';

const root = createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <ErrorBoundary>
            <Routing />
        </ErrorBoundary>
    </React.StrictMode>
);
