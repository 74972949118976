import { Card, MenuItem, Paper, Select, Button } from '@mui/material';
import { CalendarSortTypes } from 'constants/PreferenceConstants';
import React, { useCallback, useMemo, useState } from 'react';
import DraggableList from 'common/DraggableList';
import { testIds } from 'constants/TestConstants';
import { notEmpty, moveItemInList } from './Common/PreferencesCommonFunctions';
import { Preference, SortEntry } from 'models';
import { DropResult } from 'react-beautiful-dnd';
import { Delete } from '@mui/icons-material';
import { OrderButton } from 'common/OrderButton';
import { SortType } from 'models';
import { v4 as uuidv4 } from 'uuid';

type Props = {
    preferences: Preference,
    updatePreferences: (key: string, item: string|SortEntry[]) => void
}

export default function Prioritization({ preferences, updatePreferences }: Props) {
    const [ sortTypeOpen, setSortTypeOpen ] = useState(false);
    const [ sortEntries, setSortEntries ] = useState<SortEntry[]>(preferences.sortEntryOrder ?
        // sortOrder in prefs allows nulls, removing any nulls here
        preferences.sortEntryOrder.filter(notEmpty): []);

    const onDragEnd = (result: DropResult) => {
        // dropped outside the list
        if (!result.destination) return;
    
        const newItems = moveItemInList(sortEntries, result.source.index, result.destination.index);
    
        setSortEntries(newItems);
        updatePreferences('sortEntryOrder', newItems);
    };

    const deleteItem = useCallback((index: number) => {
        const newSortOrder = [...sortEntries];
        newSortOrder.splice(index, 1);
        setSortEntries(newSortOrder);
        updatePreferences('sortEntryOrder', newSortOrder);
    }, [ sortEntries, setSortEntries, updatePreferences ]);

    const sortTypes = useMemo(() => sortEntries.map(sortEntry => sortEntry.type), [sortEntries]);

    const changeItem = useCallback((index: number, variableName: string, value: boolean) => {
        const newSortOrder = [...sortEntries];
        const newItem = {
            ...newSortOrder[index],
            [variableName]: value
        };
        newSortOrder.splice(index, 1, newItem);
        setSortEntries(newSortOrder);
        updatePreferences('sortEntryOrder', newSortOrder);
    }, [ sortEntries, setSortEntries, updatePreferences ]);

    const prioritizationItems = useMemo(() => {
        return sortEntries.map((sorting, index) => {
            return {
                item: sorting.type,
                children: [
                    <OrderButton key={'sortButton'+sorting.sortEntryId} ascending={sorting.ascending === true} onClick={(ascending) => changeItem(index, 'ascending', ascending)}/>,
                    <Button key={'deleteButton'+sorting.sortEntryId} style={{ float: 'left' }} onClick={() => deleteItem(index)}>
                        <Delete/>
                    </Button>
                ]
            };
        });
    }, [ sortEntries, deleteItem ]);

    return <Card style={{backgroundColor:'grey', overflow:'visible'}}>
        <div style={{
            display: 'flex',
            flexWrap: 'wrap'
        }}>
            {sortEntries && <Paper style={{
                flex: 1,
                margin: 16,
                minWidth: 150
            }} data-testid={testIds.draggableList}>
                <DraggableList
                    items={prioritizationItems}
                    onDragEnd={onDragEnd}
                    canAdd={sortEntries.length < Object.values(CalendarSortTypes).length}
                    addFunction={() => setSortTypeOpen(true)}
                />
            </Paper>
            }
            {(!sortEntries || sortEntries.length < Object.values(CalendarSortTypes).length) &&
                <>
                    <Select
                        variant="standard"
                        style={{ display: 'none !important', visibility: 'hidden' }}
                        open={sortTypeOpen}
                        onClose={() => setSortTypeOpen(false)}
                        onChange={(event) => {
                            const tempSortOrder = sortEntries ? [ ...sortEntries ] : [];
                            // For some reason there is some weird logic in amplify with any field called "id" especially with non models
                            // So to fix this for now added a new sortId field that is populated with a uuid
                            const newSortEntry = new SortEntry({
                                type: event.target.value as SortType,
                                ascending: true,
                                sortEntryId: uuidv4()
                            });
                            console.log(newSortEntry);
                            tempSortOrder.push(newSortEntry);
                            setSortEntries(tempSortOrder);
                            updatePreferences('sortEntryOrder', tempSortOrder);
                        }}>
                        {Object.values(SortType)
                            .filter((sortType) => sortTypes ? !sortTypes.includes(sortType as SortType) : true)
                            .map(sortType =>
                                <MenuItem value={sortType} key={sortType + 'option'}>
                                    {sortType}
                                </MenuItem>)
                        }
                    </Select>
                </>
            }
        </div>
    </Card>;
}
