import { Whatshot } from '@mui/icons-material';
import { DailyDateTimeMap } from 'models/CommonTypes';
import React from 'react';
import { getHighScore, getStreak } from './DailysCommon';

type Props = {
    dateTimes: DailyDateTimeMap,
    today: string,
    completed: boolean,
    isHighScore?: boolean
}

export default function StreakIcon({ dateTimes, today, completed, isHighScore = false }: Props) {
    let streak = 0;
    if (isHighScore) {
        streak = getHighScore(dateTimes);
    } else {
        streak = getStreak(dateTimes, today);
    }
    const streakColor = completed ? 'orange' : 'grey';

    return <>
        <Whatshot style={{ color: streakColor, marginRight: '0px' }}/>
        {streak}
    </>;
}