export function recipeDateComparator(a, b) {
    return getLatestDate(a) - getLatestDate(b);
}

//TODO find a better way to do this
//TODO handle timezones
export function getLatestDate(recipe) {
    const newScheduledDays = recipe.scheduledDays ? [ ...recipe.scheduledDays ] : [];
    const latestDay = newScheduledDays.sort(function sortByDate(a, b) {
        return new Date(b.date) - new Date(a.date);
    });
    return latestDay.length > 0 ? new Date(latestDay[0]) : new Date(1, 1, 1);
}

export function recipeFavoriteComparator(a, b) {
    const scheduledDaysLengthA = a.scheduledDays ? a.scheduledDays.length : 0;
    const scheduledDaysLengthB = b.scheduledDays ? b.scheduledDays.length : 0;
    return scheduledDaysLengthB - scheduledDaysLengthA;
}