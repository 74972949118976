import Alert from '@mui/material/Alert';
import { defaultBreakfastEnd, defaultBreakfastStart, defaultDinnerEnd, defaultDinnerStart, defaultLunchEnd, defaultLunchStart } from 'constants/MealConstants';
import { MealType, Preference } from 'models';
import React from 'react';

type Props = {
    mealType: MealType,
    preferences: Preference
}

export default function MealTimeWindowPreferenceAlert({ mealType, preferences }: Props) {
    if (mealType === MealType.BREAKFAST) {
        return !preferences.breakfastTimeWindow || !preferences.breakfastTimeWindow.startTime || !preferences.breakfastTimeWindow.endTime 
            ? <Alert severity="warning">No time window preference configured for meal type, algorithm will use default start of {defaultBreakfastStart} and end of {defaultBreakfastEnd}.</Alert>
            : <></>;
    } else if(mealType === MealType.LUNCH) {
        return !preferences.lunchTimeWindow || !preferences.lunchTimeWindow.startTime || !preferences.lunchTimeWindow.endTime 
            ? <Alert severity="warning">No time window preference configured for meal type, algorithm will use default start of {defaultLunchStart} and end of {defaultLunchEnd}.</Alert>
            : <></>;
    } else if(mealType === MealType.DINNER) {
        return !preferences.dinnerTimeWindow || !preferences.dinnerTimeWindow.startTime || !preferences.dinnerTimeWindow.endTime 
            ? <Alert severity="warning">No time window preference configured for meal type, algorithm will use default start of {defaultDinnerStart} and end of {defaultDinnerEnd}.</Alert>
            : <></>;
    } else {
        return <Alert severity="error">Meal type is not recognized.</Alert>;
    }
}