export const testRoles = {
    textbox: 'textbox',
    header: 'header',
    alert: 'alert',
    button: 'button',
    checkbox: 'checkbox',
    listitem: 'listitem',
    combobox: 'combobox', // Applies to autocomplete as well
    option: 'option',
    tooltip: 'tooltip',
    menuitem: 'menuitem'
};

export const testIds = {
    drawerButton: 'drawer-button',
    mouseOverBox: 'mouse-over-box',
    mouseOverPopover: 'mouse-over-popover',
    betaChip: 'beta-chip',
    categoryChip: 'category-chip',
    passwordInput: 'password-input',
    dailyChecked: 'daily-checked',
    dailyUnchecked: 'daily-unchecked',
    issueTagChip: 'issue-tag-chip',
    upvoteIcon: 'upvote-icon',
    upvoteSelectedIcon: 'upvote-selected-icon',
    deleteButton: 'delete-button',
    completeButton: 'complete-button',
    uncompleteButton: 'uncomplete-button',
    recipeComponent: 'recipe-component',
    nutritionFactComponent: 'nutrition-fact-component',
    circularProgress: 'circular-progress',
    taskComponent: 'task-component',
    parentTaskComponent: 'parent-task-component',
    draggableList: 'draggable-list',
    asapInputs: 'asap-inputs',
    scheduledInputs: 'scheduled-inputs',
    createAccount: 'create-account',
    login: 'login',
    accountManagementCard: 'account-management-card',
    preferencesPage: 'preferences-page',
    redirect: 'redirect',
    mainAppDrawer: 'main-app-drawer',
    taskTextInput: 'task-text-input',
    taskTextInputLine: 'task-text-input-line',
    calendar: 'calendar',
    recipes: 'recipes',
    nutritionFacts: 'nutrition-facts',
    tasks: 'tasks',
    mealDiary: 'meal-diary',
    fridgeComponent: 'fridge-component',
    goalPage: 'goal-page',
    app: 'app',
    taskTypeSelector: 'task-type-selector',
    categoryWrapper: 'category-wrapper',
    groupCategorySwitch: 'group-category-switch',
    timeWindowStartInput: 'time-window-start-input',
    timeWindowEndInput: 'time-window-end-input',
    dateInput: 'date-input',
    timeInput: 'time-input',
    selector: 'selector',
    chip: 'chip',
    modal: 'modal',
    goalComponent: 'goalComponent',
    draggableArea: 'draggable-area',
    calendarBox: 'calendar-box',
    autocomplete: 'autocomplete',
    currentCoinsText: 'currentCoinsText',
    nutritionInfo: 'nutritionInfo'
};