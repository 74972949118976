export const IngredientUnit: {[key:string]: string;} = {
    UNIT: 'x',
    TEASPOON: 'tsp',
    TABLESPOON: 'tbsp',
    OUNCE: 'oz',
    MILLILITER: 'ml',
    LITER: 'l',
    CUP: 'cup',
    PINT: 'pt',
    QUART: 'qt',
    POUND: 'lb',
    GRAM: 'g',
    MILLIGRAM: 'mg'
};

export const defaultBreakfastStart = '08:00';
export const defaultBreakfastEnd = '10:00';
export const defaultLunchStart = '11:00';
export const defaultLunchEnd = '14:00';
export const defaultDinnerStart = '17:00';
export const defaultDinnerEnd = '19:00';