import React, { useState } from 'react';
import 'App.css';
import { Box, Button, Card, Modal } from '@mui/material';
import { useCallback } from 'react';
import { PasswordInput } from 'common/PasswordInput';
import { modalStyle } from 'constants/StylingConstants';
import { testIds } from 'constants/TestConstants';
import { confirmSignIn } from 'aws-amplify/auth';

/*const style = {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '25%',
    height: '25%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    textAlign: 'center',
    justifyContent: 'center'
};*/

type Props = {
    handleClose: () => void,
    pushError: (error: string) => void
}

export default function NewPasswordRequiredModal({ handleClose, pushError }: Props) {
    const [ password, setPassword ] = useState('');
    const [ confirmPassword, setConfirmPassword ] = useState('');

    const setNewPassword = useCallback(() => {
        confirmSignIn({
            challengeResponse: password // new password
        }).then(() => {
            handleClose();
        }).catch(e => pushError(e));
    }, [ password ]);

    return <Modal
        open={true}
        onClose={handleClose}
        data-testid={testIds.modal}
    >
        <Card style={modalStyle}>
            <Box
                component="form"
                onSubmit={(e) => {
                    e.preventDefault();
                    setNewPassword();
                }}
            >
                <PasswordInput
                    password={password}
                    setPassword={setPassword}
                    label='Password'
                    error={password.length < 1}
                />
                <p style={{ fontSize: '10px' }}>Password must contain 1 uppercase and lowercase character, 1 special character and 1 number.</p>
                <PasswordInput
                    password={confirmPassword}
                    setPassword={setConfirmPassword}
                    label='Confirm Password'
                    error={confirmPassword !== password || confirmPassword.length < 1}
                />
                <Button
                    type="submit"
                    disabled={confirmPassword !== password || confirmPassword.length < 1 || password.length < 1}
                >
                        Set Password
                </Button>
            </Box>
        </Card>
    </Modal>;
}