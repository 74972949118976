import { Card, CardActionArea } from '@mui/material';
import { Add } from '@mui/icons-material';
import React from 'react';
import { taskTypes } from 'constants/TaskConstants';
import { testIds } from 'constants/TestConstants';

type Props = {
    children: string | JSX.Element | JSX.Element[],
    isBetaUser: boolean,
    addSubTask: () => void,
    color: string,
    type: string
}

export default function ParentTaskComponents({ children, isBetaUser, addSubTask, color, type }: Props) {
    // grocery tasks cannot have subtasks
    if (type == taskTypes.GROCERY) {
        return <></>;
    }

    // Card should be smaller if no existing children
    const addStyle = children
        ? { minWidth: 250, minHeight: 300 }
        : { minWidth: 250, minHeight: 30 };
    
    return <>
        {!children && <p style={{ margin: 0, fontSize: 16 }}>Subtasks</p>}
        <Card
            style={{ backgroundColor: '#282c34', alignItems: 'center' }}
            data-testid={children ? testIds.parentTaskComponent : ''}
        >
            {children}
            { isBetaUser && 
            <Card style={{ ...addStyle, paddingBottom: 5, margin: 5, textAlign: 'center', display:'inline-block', backgroundColor: color }}>
                <CardActionArea
                    onClick={addSubTask}
                    style={addStyle}
                >
                    <Add style={{ position: 'relative', top: '50%' }} fontSize="large" />
                </CardActionArea>
            </Card>}
        </Card>
    </>;
}