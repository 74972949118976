import React, { useMemo } from 'react';
import { Button } from '@mui/material';
import { ThumbUp, ThumbUpOutlined } from '@mui/icons-material';
import { testIds } from 'constants/TestConstants';
import { Issue } from 'models';

type Props = {
    issue: Issue,
    username: string,
    upvote: () => void
}

export function UpvoteButton({ issue, username, upvote }: Props) {
    const upvotes = useMemo(() => issue.upvotes ? issue.upvotes : [], [ issue ]);

    if (issue.approved) {
        return <Button 
            style={{width: '65px', display: 'inline-block', float: 'right', textAlign:'right'}}
            onClick={upvote}
            disabled={Boolean(!username || issue.completed)}
        >
            {upvotes ? upvotes.length : 0} {username && upvotes.includes(username)
                ? <ThumbUp data-testid={testIds.upvoteIcon} />
                : <ThumbUpOutlined data-testid={testIds.upvoteSelectedIcon} />}
        </Button>;
    } else {
        return <></>;
    }
}