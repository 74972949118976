import { getFormattedTime } from './TimeHelper';

// Keeps track of the time it takes for a function to execute for debugging purposes
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function logTimeToExecute(func: () => any, funcName: string) {
    const start = Date.now();
    const outputIfAny = func();
    const timeTaken = Date.now() - start;
    console.log(`${getFormattedTime(new Date())}: Total time taken to execute ${funcName}: ${timeTaken} milliseconds`);
    return outputIfAny;
}