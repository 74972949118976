import React, { useState } from 'react';
import 'App.css';
import { Button, Card, Modal, TextField, Typography } from '@mui/material';
import { useCallback } from 'react';
import { InputTextField } from 'common/InputTextField';
import { useHistory } from 'react-router-dom';
import { PasswordInput } from 'common/PasswordInput';
import { modalStyle } from 'constants/StylingConstants';
import { resetPassword, confirmResetPassword, signIn } from 'aws-amplify/auth';
import { testIds } from 'constants/TestConstants';

type Props = {
    handleClose: () => void,
    triggerAuth: (username: string) => void,
    pushError: (error: string) => void
}

export default function ForgotPasswordModal({ handleClose, triggerAuth, pushError }: Props) {
    const [ emailSent, setEmailSent ] = useState(false);
    const [ username, setUsername ] = useState('');
    const [ code, setCode ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ confirmPassword, setConfirmPassword ] = useState('');

    const history = useHistory();

    const sendEmail = useCallback(() => {
        resetPassword({ username })
            .then(() => setEmailSent(true))
            .catch((err) => pushError(err));
    }, [username]);

    const newPassword = useCallback(() => {
        confirmResetPassword({ username, confirmationCode: code, newPassword: password })
            .then(() => {
                signIn({ username, password }).then(() => {
                    triggerAuth(username);
                    history.push('/');
                }).catch((error) => {
                    pushError(error);
                    handleClose();
                });
            })
            .catch((err) => pushError(err));
    }, [username, code, password]);

    return (
        <Modal
            open={true}
            onClose={handleClose}
            data-testid={testIds.modal}
        >
            <Card style={modalStyle}>
                <h2>
                    Reset Password
                </h2>
                {emailSent ?
                    <>
                        <Typography id="modal-modal-description" >
                            Please enter code sent to email along with your new password
                        </Typography>
                        <TextField
                            variant="standard"
                            name={'RecoveryCode'}
                            label={'Recovery Code'}
                            value={code}
                            error={code.length < 1}
                            onChange={(e) => setCode(e.target.value)} />
                        <br/>
                        <PasswordInput
                            password={password}
                            setPassword={setPassword}
                            label='Password'
                            error={password.length < 1}
                            autoComplete="productivity-password"
                        />
                        <br/>
                        <p style={{ fontSize: '10px' }}>Password must contain 1 uppercase and lowercase character, 1 special character and 1 number.</p>
                        <PasswordInput
                            password={confirmPassword}
                            setPassword={setConfirmPassword}
                            label='Confirm Password'
                            error={confirmPassword !== password || confirmPassword.length < 1}
                        />
                        <br/>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button disabled={password.length < 1 || code.length < 1 || confirmPassword !== password || confirmPassword.length < 1} onClick={() => newPassword()}>Submit</Button>
                    </>
                    :<>
                        <Typography id="modal-modal-description" >
                            Enter your username below, an email will be sent with a confirmation code to your registered email if there is one.
                        </Typography>
                        <InputTextField
                            name="username"
                            label="Username"
                            value={username}
                            onChangeEvent={(value) => setUsername(value)}
                        />
                        <br/>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={sendEmail}>Submit</Button>
                    </>
                }
            </Card>
        </Modal>
    );
}