import React from 'react';
import { calculateMinutesFromTime } from 'helpers/TimeHelper';
import { toCategoryInstances } from 'preferences/Category/CategoryCommon';
import { startTimeComparator } from 'preferences/Category/CategoryComparators';
import CategoryCalendarBox from './CategoryCalendarBox';
import DraggableArea, { DraggableAreaEvent } from 'common/DraggableArea';
import { CategoryInstance } from 'models/CommonTypes';

type Props = {
    categoriesInput: CategoryInstance[],
    today: string,
    onCreate: (event: DraggableAreaEvent) => void,
    isBetaUser: boolean
}

export const hourHeight = 60;

export default function CalendarCategories({ categoriesInput, today, onCreate, isBetaUser }: Props) {
    const categoryElements = [];
    /*const categories = [...categoriesInput].filter((filteringCat) =>
        isCategoryScheduledToday(filteringCat, today));*/
    // dynamic can have multiple scheduled times in a day so split up into individual instances
    const categoryInstances = [...categoriesInput].map(cat => toCategoryInstances(cat, today)).flat();
    categoryInstances.sort(startTimeComparator);
    let maxOffsett = 0;
    
    for (let i = 0; i < categoryInstances.length; i++) {
        const categoryInstance = categoryInstances[i];
        const scheduledTime = calculateMinutesFromTime(categoryInstance.startTime || '00:00');

        // TODO find a better way to do this (maybe calculate the rows first then render each row)
        // This is to determine what offset we should put category at so nothing is overlapping
        ////////////
        let leftOffsett = 0;
        let previousOverlappingTasks = 0;
        // Find spaces until current task has a free space
        while (i - (previousOverlappingTasks + 1) > -1 && scheduledTime <= calculateMinutesFromTime(categoryInstances[i - (previousOverlappingTasks + 1)].endTime || '00:00')) {
            previousOverlappingTasks += 1;
        }
        // Find how many spaces out we should put the current task
        if (previousOverlappingTasks > 0) {
            const lastIndexed = i - previousOverlappingTasks;
            while (lastIndexed - (leftOffsett + 1) > -1 
                        && scheduledTime <= calculateMinutesFromTime(categoryInstances[lastIndexed - (leftOffsett + 1)].endTime || '00:00')) {
                leftOffsett += 1;
            }
            if (leftOffsett !== 0 || lastIndexed === 0) {
                leftOffsett += previousOverlappingTasks;
            }
        }
        const left = 70 + (leftOffsett * 25);

        if (maxOffsett < left) {
            maxOffsett = left;
        }
        ////////////////

        categoryElements.push(<CategoryCalendarBox
            key={categoryInstance.categoryId + categoryInstance.instanceNumber}
            categoryInstance={categoryInstance}
            left={left}
            scheduledTime={scheduledTime}
            onCreate={onCreate}
        />);
    }

    if (isBetaUser) {
        return <div style={{ width: maxOffsett + 60 }}>
            <DraggableArea
                increment={hourHeight / 4} // increments of 15 minutes
                width={maxOffsett + 60}
                height={hourHeight * 24}
                onCreate={(event) => onCreate({
                    ...event,
                    // multiply by 15 to get the actual minutes
                    start: event.start * 15,
                    end: event.end * 15
                })}
            >
                {categoryElements}
            </DraggableArea>
        </div>;
    } else {
        return <div style={{ width: maxOffsett + 60 }}>
            {categoryElements}
        </div>;
    }
}