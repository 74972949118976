import React, { useState } from 'react';
import Login from './login/Login';
import CreateAccount from './createaccount/CreateAccount';
import ForgotPasswordModal from './createaccount/ForgotPasswordModal';

type Props = {
    pushError: (error: string) => void,
    triggerAuth: () => void
}

export default function AccountManagementCard({ pushError, triggerAuth }: Props) {
    const [ hasAccount, setHasAccount ] = useState(false);
    const [ resetPasswordOpen, setResetPasswordOpen ] = useState(false);

    return <>
        { resetPasswordOpen && 
            <ForgotPasswordModal
                handleClose={() => setResetPasswordOpen(false)}
                triggerAuth={triggerAuth}
                pushError={pushError}
            />
        }
        {hasAccount ? 
            <Login
                pushError={pushError}
                updateHasAccount={() => setHasAccount(!hasAccount)}
                triggerAuth={triggerAuth}
                resetPassword={() => setResetPasswordOpen(true)}
            /> 
            : <CreateAccount
                pushError={pushError}
                updateHasAccount={() => setHasAccount(!hasAccount)}
                resetPassword={() => setResetPasswordOpen(true)}
            />}
    </>;
}