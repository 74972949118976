import React, { useCallback, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { InputTextField } from '../../common/InputTextField';
import { Card, Checkbox, FormControlLabel, IconButton, Switch, Tooltip } from '@mui/material';
import DayOfWeekBox from '../DayOfWeekBox';
import { isTimeBefore } from 'helpers/TimeHelper';
import { HelpOutline } from '@mui/icons-material';
import TimeWindowInput from 'preferences/TimeWindowInput';
import { Category, Preference } from 'models';
import { CategoryBlob } from 'models/CommonTypes';
import { CategoryDaysToObject, CategoryDaysToString } from './CategoryCommon';
import { MuiColorInput } from 'mui-color-input';

const staticText = 'Static categories are scheduled at the same time on given weekdays.';
const dynamicText = 'Dynamic categories can be scheduled by dragging on the calendar.';

const daysOfTheWeek = [ 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun' ];

type Props = {
    category: Category,
    preferences: Preference,
    updateCategory: (category: Category) => void,
    deleteCategory: () => void,
    isBetaUser: boolean
}

export default function CategoryComponent({ category, preferences, updateCategory, deleteCategory, isBetaUser }: Props) {
    // TODO might be better to convert category days from string to object at the start
    const [ stateCategory, setStateCategory ] = useState<CategoryBlob>(CategoryDaysToObject(category));
    const [ categoryName, setCategoryName ] = useState(category.name);

    const handleCategoryChange = useCallback((variableName: string, value: string|boolean|number|null) => {
        let newCategory = { ...stateCategory };

        if (variableName.startsWith('day-')) {
            const key = variableName.split('-')[1];
            newCategory = {
                ...stateCategory,
                day: {
                    ...stateCategory.day,
                    [key]: value,
                }
            };
        } else {
            newCategory[variableName] = value;
        }

        setCategoryName(newCategory.name);
        setStateCategory(newCategory);
        updateCategory(CategoryDaysToString(newCategory));
    }, [ preferences, updateCategory, stateCategory ]);

    const color = stateCategory.color || '#FFFFFF';
    return <Card
        style={{ maxWidth: 300, minHeight:150, padding:5, margin:5, backgroundColor:color, display: 'inline-block', overflow:'visible', verticalAlign: 'top'}}
    >
        <IconButton onClick={deleteCategory} size="large">
            <DeleteIcon/>
        </IconButton>
        <InputTextField
            name="name"
            label="Category Name"
            value={categoryName || ''}
            onChangeEvent={(value) => handleCategoryChange('name', value)}
        />
        <br/>
        <MuiColorInput
            style={{color:'black !important', backgroundColor: 'grey'}}
            name="color"
            label="color"
            value={color}
            onChange={(value: string) => {
                if (value) {
                    handleCategoryChange('color', value);
                }
            }}
        />
        {isBetaUser && <FormControlLabel
            control={<Switch
                onChange={(event) => handleCategoryChange('static', event.target.checked)}
                checked={stateCategory.static === false ? false : true}
            />}
            label={<>
                {stateCategory.static === false ? 'Dynamic ' : 'Static '}
                <Tooltip title={stateCategory.static === false ? dynamicText : staticText}><HelpOutline fontSize='small'/></Tooltip>
            </>}
        />}
        {stateCategory.static !== false && <>
            <br/>
            <TimeWindowInput
                setStartTime={(startTime) => handleCategoryChange('startTime', startTime)}
                setEndTime={(endTime) => handleCategoryChange('endTime', endTime)}
                startTime={stateCategory.startTime} 
                endTime={stateCategory.endTime}
                title="Category Time Window"
                startTimeError={Boolean(!stateCategory.startTime || 
                        (stateCategory.endTime && !isTimeBefore(stateCategory.startTime, stateCategory.endTime)))}
                endTimeError={Boolean(!stateCategory.endTime || 
                        (stateCategory.startTime && !isTimeBefore(stateCategory.startTime, stateCategory.endTime)))}
            />
            <>
                <br/>
                Days of the week
                <br/>
                {daysOfTheWeek.map(day => <DayOfWeekBox
                    key={day}
                    boxDay={day}
                    handleCategoryChange={handleCategoryChange}
                    currentDays={stateCategory.day}
                />)}
            </>
        </>}
        <br/>
        {isBetaUser && <><FormControlLabel
            control={<Checkbox
                onChange={(event) => handleCategoryChange('restrict', event.target.checked)}
                checked={stateCategory.restrict !== false}
            />}
            label={<>
                    Restrict {' '}
                <Tooltip title="When enabled, tasks with this category will not be scheduled outside of category scheduled time."><HelpOutline fontSize='small'/></Tooltip>
            </>}
        />
        <br/></>}
        {isBetaUser && <FormControlLabel
            control={<Checkbox
                onChange={(event) => handleCategoryChange('prioritize', event.target.checked)}
                checked={stateCategory.prioritize !== false}
            />}
            label={<>
                    Prioritize {' '}
                <Tooltip title="When enabled, tasks with this category are prioritized during scheduled time."><HelpOutline fontSize='small'/></Tooltip>
            </>}
        />}
    </Card>;
}