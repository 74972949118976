// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function paginate(list: any[], size: number) {
    const res = [];
    for(let i = 0; i < list.length; i++) {
        if(i % size === 0){
            // Push a new array containing the current value to the res array
            res.push([list[i]]);
        }
        else{
            // Push the current value to the current array
            res[res.length-1].push(list[i]);
        }
    }
    return res;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function groupByField(list: any[], fieldName: string, unknownField:string = 'Unknown', useLowercase:boolean = false) {
    return list.reduce((previous, currentItem) => {
        if (currentItem) {
            let fieldValue = currentItem[fieldName] ? currentItem[fieldName] : unknownField;
            fieldValue = useLowercase ? fieldValue.toLowerCase() : fieldValue;
            return { ...previous, [fieldValue]: currentItem};
        } else {
            return previous;
        }
    }, {});
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function replaceById(id: string, list: any[], newItem: any) {
    return list.map(t => {
        if (t.id === id) {
            return newItem;
        }
        return t;
    });
}

type UnknownDict = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [id: string|number]: any
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function addToListDictionary(item: any, index: string|number, dictionary: UnknownDict) {
    dictionary[index] = dictionary[index]
        ? [ ...dictionary[index], item ]
        : [ item ];
    return dictionary;
}