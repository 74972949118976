import { Card, Box, Button, TextField } from '@mui/material';
import React from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import NewPasswordRequiredModal from './NewPasswordRequiredModal';
import { PasswordInput } from 'common/PasswordInput';
import { processLoginError } from 'accountManagement/common/AccountManagementCommonFunctions';
import { testIds } from 'constants/TestConstants';
import { signIn, SignInOutput } from 'aws-amplify/auth';

type Props = {
    updateHasAccount: () => void,
    triggerAuth: (username: string) => void,
    resetPassword: () => void,
    pushError: (error: string) => void
}

export default function Login({ updateHasAccount, triggerAuth, resetPassword, pushError }: Props) {
    const [ username, setUsername ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ newPasswordModalOpen, setNewPasswordModalOpen ] = useState(false);
    const history = useHistory();

    const loginCallback = useCallback(() => {
        signIn({ username, password }).then((output: SignInOutput) => {
            if (output.nextStep && output.nextStep.signInStep === 'RESET_PASSWORD') {
                setNewPasswordModalOpen(true);
            } else {
                triggerAuth(username);
                history.push('/');
            }
        }).catch((error) => {
            const errorString = error.message ? error.message : error;
            pushError(processLoginError(errorString));
        });
    }, [username, password]);

    return <>
        {newPasswordModalOpen && <NewPasswordRequiredModal
            handleClose={() => setNewPasswordModalOpen(false)}
            pushError={pushError}
        />}
        <Card
            style={{width:300, minHeight:150, padding:5, margin:5, display: 'inline-block', overflow:'visible', verticalAlign: 'top'}}
            data-testid={testIds.login}
        >
            Login
            <Box
                component="form"
                onSubmit={(e) => {
                    e.preventDefault();
                    loginCallback();
                }}
            >
                <TextField
                    variant="standard"
                    name={'ProductivityUsername'}
                    label={'Username'}
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    autoComplete="productivity-username" />
                <PasswordInput
                    password={password}
                    setPassword={setPassword}
                    label='Password'
                    autoComplete="productivity-password"
                />
                <br/>
                <Button
                    type="submit"
                >
                Login
                </Button>
            </Box>
            <div style={{ fontSize: '15px' }}>Dont have an account? <a href="" onClick={(event) => {event.preventDefault();updateHasAccount();}}>Create one now!</a></div>
            <div style={{ fontSize: '15px' }}><a href="" onClick={(event) => {event.preventDefault();resetPassword();}}>Forgot Password?</a></div>
        </Card>
    </>;
}