import React, { useState } from 'react';
import { Checkbox, FormControlLabel, MenuItem, Select, TextField, Container } from '@mui/material';
import { testIds } from 'constants/TestConstants';

export default function ASAPInputs({ handleConfigChange, daily, priority, categoryId, preferences, dueDate, dueDateDate }) {
    const categories = preferences.categories || [];
    // If category is deleted its possible we dont find any categories with that id. Should set category to None in that case
    const categoryObjects = categoryId && categoryId !== 'None' ? categories.filter((category) => category.categoryId === categoryId) : [];
    const categoryColor = categoryObjects.length > 0 ? categoryObjects[0].color : null;
    const [ isDailyEnabled, setIsDailyEnabled ] = useState(daily && daily.enabled);

    return <Container data-testid={testIds.asapInputs}>
        <FormControlLabel
            control={<Checkbox
                onChange={(event) => {
                    setIsDailyEnabled(event.target.checked);
                    handleConfigChange('daily', event.target.checked);
                }}
                value={isDailyEnabled}
                checked={isDailyEnabled}
            />}
            label="Daily"
        />
        <br/>
        <Select
            variant="standard"
            value={priority}
            onChange={(event) => handleConfigChange('priority', event.target.value)}>
            <MenuItem value="High">High</MenuItem>
            <MenuItem value="Medium">Medium</MenuItem>
            <MenuItem value="Low">Low</MenuItem>
        </Select>
        <br/>
        <Select
            variant="standard"
            style={{backgroundColor: categoryColor}}
            label="Category"
            value={categoryObjects.length > 0 ? categoryId : 'None'}
            onChange={(event) => handleConfigChange('category', event.target.value)}>
            <MenuItem value="None">None</MenuItem>
            {categories.map((category) => <MenuItem value={category.categoryId} key={category.categoryId}>
                {category.name}
            </MenuItem>)}
        </Select>
        <br/>
        {!isDailyEnabled &&
            <FormControlLabel
                control={<Checkbox
                    onChange={(event) => handleConfigChange('dueDate', event.target.checked)}
                    value={dueDate}
                    checked={dueDate}
                />}
                label="Due Date?"
            />
        }
        {dueDate && 
            <TextField
                variant="standard"
                label="Due Date"
                type="date"
                error={dueDate && (!dueDateDate || dueDateDate.length < 1)}
                defaultValue={dueDateDate}
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={(event) => handleConfigChange('dueDateDate', event.target.value)}
                data-testid={testIds.dateInput}    
            />
        }
    </Container>;
}