
import { Button, Grid, Typography } from '@mui/material';
import { ShopItem, User } from 'models';
import AddIcon from '@mui/icons-material/Add';
import React, { useState } from 'react';
import { DataStore } from 'aws-amplify/datastore';
import ShopReward from './ShopReward';
import { testIds } from 'constants/TestConstants';

type Props = {
    shopItems: ShopItem[],
    user: User,
    username: string,
    pushError: (error: string) => void
}

export default function Shop({ shopItems, user, username, pushError }: Props) {
    const [ localShopItems, setLocalShopItems ] = useState(shopItems);
    const [ currentCoins, setCurrentCoins ] = useState(user.coins);

    return <div>
        Under Construction <br/>
        <Typography data-testid={testIds.currentCoinsText}>Current coins: {currentCoins}</Typography>
        <Button onClick={() => {
            const newShopItems = localShopItems ? [ ...localShopItems ] : [];
            const newShopItem = new ShopItem({
                owner: username
            });
            DataStore.save(newShopItem).catch((error) => pushError(error));
            newShopItems.unshift(newShopItem);
            setLocalShopItems(newShopItems);
        }}><AddIcon style={{ position: 'relative', top: '50%' }} fontSize="large" /> Add Shop Item</Button>
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
        >
            {localShopItems.map((shopItem) =>
                <ShopReward
                    key={shopItem.id}
                    shopItem={shopItem}
                    currentCoins={currentCoins}
                    username={username}
                    setCurrentCoins={setCurrentCoins}
                    pushError={pushError}
                />
            )}
        </Grid>
    </div>;
}