import { TextField } from '@mui/material';
import { styled } from '@mui/system';
import { testIds } from 'constants/TestConstants';
import React, { useCallback, useEffect, useState } from 'react';

const StyledInput = styled(TextField)(({ textcolor, textDecoration }) => ({
    // 90% so that there is space for the bullet, prolly a better way to do this
    width: '90%',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    whiteSpace: 'normal',
    '& div > textarea': {
        color: textcolor,
        paddingBottom: '1px',
        paddingTop: '6px',
        textDecoration: textDecoration,
        overflowWrap: 'break-word',
        wordWrap: 'break-word',
        whiteSpace: 'normal',
    },
}),
);

export function TaskTextInput({ handler, color, value, onChangeEvent, newLine, completed, focused, deleteLine = () => {}, makeChild = () => {}, isNumber=false}) {
    const [ localValue, setLocalValue ] = useState(value);

    // If value is updated externally make sure local value is also updated
    useEffect(() => {
        if (localValue !== value) {
            setLocalValue(value);
        }
    }, [value]);

    const handleKeyDown = useCallback((event) => {
        // Enter on both mobile and desktop
        if (event.keyCode === 13 || event.key === 'Enter' || event.code === 'Enter') {
            event.preventDefault();
            newLine();
        } else if (event.key === 'Backspace' && localValue.length < 1) {
            deleteLine();
        } else if (event.key === 'Tab') {
            console.log('making child');
            event.preventDefault();
            makeChild();
        }
    }, [ newLine, localValue ]);

    return <form
        onSubmit={(e)=>{e.preventDefault(); newLine();}}
        style={{ display: 'inline', wordWrap: 'break-word' }}
        data-testid={testIds.taskTextInput}
    >
        { handler }
        <StyledInput
            data-testid={testIds.taskTextInputLine}
            InputProps={{ disableUnderline: true }}
            value={localValue}
            variant="standard"
            textcolor={completed ? 'grey' : color }
            // Focuses to element (for example when we add a new line it should focus on new line)
            inputRef={input => input && focused && input.focus()}
            textDecoration={completed ? 'line-through' : 'none'}
            multiline
            onChange={(event) => {
                if (isNumber && (isNaN(event.target.value) || isNaN(parseInt(event.target.value)))) {
                    if (event.target.value === '') {
                        setLocalValue('0');
                        onChangeEvent('0');
                    } else {
                        console.error(`Not able to update duration, input ${event.target.value} is not a number.`);
                    }
                } else {
                    setLocalValue(event.target.value);
                    onChangeEvent(event.target.value);
                }
            }}
            onKeyDown={handleKeyDown}
        />
    </form>;
}