export const schema = {
    'models': {
        'Note': {
            'name': 'Note',
            'fields': {
                'id': {
                    'name': 'id',
                    'isArray': false,
                    'type': 'ID',
                    'isRequired': true,
                    'attributes': []
                },
                'title': {
                    'name': 'title',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': []
                },
                'content': {
                    'name': 'content',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': []
                },
                'owner': {
                    'name': 'owner',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': true,
                    'attributes': []
                },
                'createdAt': {
                    'name': 'createdAt',
                    'isArray': false,
                    'type': 'AWSDateTime',
                    'isRequired': false,
                    'attributes': [],
                    'isReadOnly': true
                },
                'updatedAt': {
                    'name': 'updatedAt',
                    'isArray': false,
                    'type': 'AWSDateTime',
                    'isRequired': false,
                    'attributes': [],
                    'isReadOnly': true
                }
            },
            'syncable': true,
            'pluralName': 'Notes',
            'attributes': [
                {
                    'type': 'model',
                    'properties': {}
                },
                {
                    'type': 'auth',
                    'properties': {
                        'rules': [
                            {
                                'allow': 'private',
                                'operations': [
                                    'create',
                                    'update',
                                    'delete',
                                    'read'
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        'ShopItem': {
            'name': 'ShopItem',
            'fields': {
                'id': {
                    'name': 'id',
                    'isArray': false,
                    'type': 'ID',
                    'isRequired': true,
                    'attributes': []
                },
                'name': {
                    'name': 'name',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': []
                },
                'owner': {
                    'name': 'owner',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': true,
                    'attributes': []
                },
                'cost': {
                    'name': 'cost',
                    'isArray': false,
                    'type': 'Int',
                    'isRequired': false,
                    'attributes': []
                },
                'timesRedeemed': {
                    'name': 'timesRedeemed',
                    'isArray': false,
                    'type': 'Int',
                    'isRequired': false,
                    'attributes': []
                },
                'createdAt': {
                    'name': 'createdAt',
                    'isArray': false,
                    'type': 'AWSDateTime',
                    'isRequired': false,
                    'attributes': [],
                    'isReadOnly': true
                },
                'updatedAt': {
                    'name': 'updatedAt',
                    'isArray': false,
                    'type': 'AWSDateTime',
                    'isRequired': false,
                    'attributes': [],
                    'isReadOnly': true
                }
            },
            'syncable': true,
            'pluralName': 'ShopItems',
            'attributes': [
                {
                    'type': 'model',
                    'properties': {}
                },
                {
                    'type': 'auth',
                    'properties': {
                        'rules': [
                            {
                                'allow': 'private',
                                'operations': [
                                    'create',
                                    'update',
                                    'delete',
                                    'read'
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        'Goal': {
            'name': 'Goal',
            'fields': {
                'id': {
                    'name': 'id',
                    'isArray': false,
                    'type': 'ID',
                    'isRequired': true,
                    'attributes': []
                },
                'date': {
                    'name': 'date',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': true,
                    'attributes': []
                },
                'type': {
                    'name': 'type',
                    'isArray': false,
                    'type': {
                        'enum': 'GoalType'
                    },
                    'isRequired': true,
                    'attributes': []
                },
                'associatedTasks': {
                    'name': 'associatedTasks',
                    'isArray': true,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': [],
                    'isArrayNullable': true
                },
                'description': {
                    'name': 'description',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': true,
                    'attributes': []
                },
                'owner': {
                    'name': 'owner',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': true,
                    'attributes': []
                },
                'createdAt': {
                    'name': 'createdAt',
                    'isArray': false,
                    'type': 'AWSDateTime',
                    'isRequired': false,
                    'attributes': [],
                    'isReadOnly': true
                },
                'updatedAt': {
                    'name': 'updatedAt',
                    'isArray': false,
                    'type': 'AWSDateTime',
                    'isRequired': false,
                    'attributes': [],
                    'isReadOnly': true
                }
            },
            'syncable': true,
            'pluralName': 'Goals',
            'attributes': [
                {
                    'type': 'model',
                    'properties': {}
                },
                {
                    'type': 'auth',
                    'properties': {
                        'rules': [
                            {
                                'allow': 'private',
                                'operations': [
                                    'create',
                                    'read'
                                ]
                            },
                            {
                                'provider': 'userPools',
                                'ownerField': 'owner',
                                'allow': 'owner',
                                'identityClaim': 'cognito:username',
                                'operations': [
                                    'create',
                                    'update',
                                    'delete',
                                    'read'
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        'MealDiaryEntry': {
            'name': 'MealDiaryEntry',
            'fields': {
                'id': {
                    'name': 'id',
                    'isArray': false,
                    'type': 'ID',
                    'isRequired': true,
                    'attributes': []
                },
                'owner': {
                    'name': 'owner',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': true,
                    'attributes': []
                },
                'date': {
                    'name': 'date',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': true,
                    'attributes': []
                },
                'name': {
                    'name': 'name',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': []
                },
                'amount': {
                    'name': 'amount',
                    'isArray': false,
                    'type': 'Int',
                    'isRequired': false,
                    'attributes': []
                },
                'unit': {
                    'name': 'unit',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': []
                },
                'createdAt': {
                    'name': 'createdAt',
                    'isArray': false,
                    'type': 'AWSDateTime',
                    'isRequired': false,
                    'attributes': [],
                    'isReadOnly': true
                },
                'updatedAt': {
                    'name': 'updatedAt',
                    'isArray': false,
                    'type': 'AWSDateTime',
                    'isRequired': false,
                    'attributes': [],
                    'isReadOnly': true
                }
            },
            'syncable': true,
            'pluralName': 'MealDiaryEntries',
            'attributes': [
                {
                    'type': 'model',
                    'properties': {}
                },
                {
                    'type': 'auth',
                    'properties': {
                        'rules': [
                            {
                                'provider': 'userPools',
                                'ownerField': 'owner',
                                'allow': 'owner',
                                'identityClaim': 'cognito:username',
                                'operations': [
                                    'create',
                                    'update',
                                    'delete',
                                    'read'
                                ]
                            },
                            {
                                'allow': 'private',
                                'operations': [
                                    'create',
                                    'read'
                                ],
                                'provider': 'iam'
                            }
                        ]
                    }
                }
            ]
        },
        'ReleaseNotes': {
            'name': 'ReleaseNotes',
            'fields': {
                'id': {
                    'name': 'id',
                    'isArray': false,
                    'type': 'ID',
                    'isRequired': true,
                    'attributes': []
                },
                'date': {
                    'name': 'date',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': []
                },
                'description': {
                    'name': 'description',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': []
                },
                'createdAt': {
                    'name': 'createdAt',
                    'isArray': false,
                    'type': 'AWSDateTime',
                    'isRequired': false,
                    'attributes': [],
                    'isReadOnly': true
                },
                'updatedAt': {
                    'name': 'updatedAt',
                    'isArray': false,
                    'type': 'AWSDateTime',
                    'isRequired': false,
                    'attributes': [],
                    'isReadOnly': true
                }
            },
            'syncable': true,
            'pluralName': 'ReleaseNotes',
            'attributes': [
                {
                    'type': 'model',
                    'properties': {}
                },
                {
                    'type': 'auth',
                    'properties': {
                        'rules': [
                            {
                                'allow': 'private',
                                'operations': [
                                    'read'
                                ]
                            },
                            {
                                'groupClaim': 'cognito:groups',
                                'provider': 'userPools',
                                'allow': 'groups',
                                'groups': [
                                    'admins'
                                ],
                                'operations': [
                                    'read',
                                    'create',
                                    'update',
                                    'delete'
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        'ScheduledInformation': {
            'name': 'ScheduledInformation',
            'fields': {
                'id': {
                    'name': 'id',
                    'isArray': false,
                    'type': 'ID',
                    'isRequired': true,
                    'attributes': []
                },
                'day': {
                    'name': 'day',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': true,
                    'attributes': []
                },
                'breakfastRecipe': {
                    'name': 'breakfastRecipe',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': []
                },
                'lunchRecipe': {
                    'name': 'lunchRecipe',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': []
                },
                'dinnerRecipe': {
                    'name': 'dinnerRecipe',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': []
                },
                'owner': {
                    'name': 'owner',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': true,
                    'attributes': []
                },
                'scheduledMeals': {
                    'name': 'scheduledMeals',
                    'isArray': true,
                    'type': {
                        'nonModel': 'ScheduledMeal'
                    },
                    'isRequired': false,
                    'attributes': [],
                    'isArrayNullable': true
                },
                'createdAt': {
                    'name': 'createdAt',
                    'isArray': false,
                    'type': 'AWSDateTime',
                    'isRequired': false,
                    'attributes': [],
                    'isReadOnly': true
                },
                'updatedAt': {
                    'name': 'updatedAt',
                    'isArray': false,
                    'type': 'AWSDateTime',
                    'isRequired': false,
                    'attributes': [],
                    'isReadOnly': true
                }
            },
            'syncable': true,
            'pluralName': 'ScheduledInformations',
            'attributes': [
                {
                    'type': 'model',
                    'properties': {}
                },
                {
                    'type': 'auth',
                    'properties': {
                        'rules': [
                            {
                                'provider': 'userPools',
                                'ownerField': 'owner',
                                'allow': 'owner',
                                'identityClaim': 'cognito:username',
                                'operations': [
                                    'create',
                                    'update',
                                    'delete',
                                    'read'
                                ]
                            },
                            {
                                'allow': 'private',
                                'operations': [
                                    'create',
                                    'read'
                                ],
                                'provider': 'iam'
                            }
                        ]
                    }
                }
            ]
        },
        'FoodInformation': {
            'name': 'FoodInformation',
            'fields': {
                'id': {
                    'name': 'id',
                    'isArray': false,
                    'type': 'ID',
                    'isRequired': true,
                    'attributes': []
                },
                'name': {
                    'name': 'name',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': []
                },
                'owner': {
                    'name': 'owner',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': true,
                    'attributes': []
                },
                'calories': {
                    'name': 'calories',
                    'isArray': false,
                    'type': 'Float',
                    'isRequired': false,
                    'attributes': []
                },
                'totalFat': {
                    'name': 'totalFat',
                    'isArray': false,
                    'type': 'Float',
                    'isRequired': false,
                    'attributes': []
                },
                'saturatedFat': {
                    'name': 'saturatedFat',
                    'isArray': false,
                    'type': 'Float',
                    'isRequired': false,
                    'attributes': []
                },
                'cholesterol': {
                    'name': 'cholesterol',
                    'isArray': false,
                    'type': 'Float',
                    'isRequired': false,
                    'attributes': []
                },
                'sodium': {
                    'name': 'sodium',
                    'isArray': false,
                    'type': 'Float',
                    'isRequired': false,
                    'attributes': []
                },
                'protein': {
                    'name': 'protein',
                    'isArray': false,
                    'type': 'Float',
                    'isRequired': false,
                    'attributes': []
                },
                'totalCarbohydrate': {
                    'name': 'totalCarbohydrate',
                    'isArray': false,
                    'type': 'Float',
                    'isRequired': false,
                    'attributes': []
                },
                'sugar': {
                    'name': 'sugar',
                    'isArray': false,
                    'type': 'Float',
                    'isRequired': false,
                    'attributes': []
                },
                'dietaryFiber': {
                    'name': 'dietaryFiber',
                    'isArray': false,
                    'type': 'Float',
                    'isRequired': false,
                    'attributes': []
                },
                'servingSize': {
                    'name': 'servingSize',
                    'isArray': false,
                    'type': 'Float',
                    'isRequired': false,
                    'attributes': []
                },
                'servingSizeUnit': {
                    'name': 'servingSizeUnit',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': []
                },
                'createdAt': {
                    'name': 'createdAt',
                    'isArray': false,
                    'type': 'AWSDateTime',
                    'isRequired': false,
                    'attributes': [],
                    'isReadOnly': true
                },
                'updatedAt': {
                    'name': 'updatedAt',
                    'isArray': false,
                    'type': 'AWSDateTime',
                    'isRequired': false,
                    'attributes': [],
                    'isReadOnly': true
                }
            },
            'syncable': true,
            'pluralName': 'FoodInformations',
            'attributes': [
                {
                    'type': 'model',
                    'properties': {}
                },
                {
                    'type': 'auth',
                    'properties': {
                        'rules': [
                            {
                                'provider': 'userPools',
                                'ownerField': 'owner',
                                'allow': 'owner',
                                'identityClaim': 'cognito:username',
                                'operations': [
                                    'create',
                                    'update',
                                    'delete',
                                    'read'
                                ]
                            },
                            {
                                'allow': 'private',
                                'operations': [
                                    'create',
                                    'read'
                                ],
                                'provider': 'iam'
                            }
                        ]
                    }
                }
            ]
        },
        'User': {
            'name': 'User',
            'fields': {
                'id': {
                    'name': 'id',
                    'isArray': false,
                    'type': 'ID',
                    'isRequired': true,
                    'attributes': []
                },
                'username': {
                    'name': 'username',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': true,
                    'attributes': []
                },
                'daysScheduled': {
                    'name': 'daysScheduled',
                    'isArray': true,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': [],
                    'isArrayNullable': true
                },
                'coins': {
                    'name': 'coins',
                    'isArray': false,
                    'type': 'Int',
                    'isRequired': false,
                    'attributes': []
                },
                'beta': {
                    'name': 'beta',
                    'isArray': false,
                    'type': 'Boolean',
                    'isRequired': false,
                    'attributes': []
                },
                'profilePicUrl': {
                    'name': 'profilePicUrl',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': []
                },
                'createdAt': {
                    'name': 'createdAt',
                    'isArray': false,
                    'type': 'AWSDateTime',
                    'isRequired': false,
                    'attributes': [],
                    'isReadOnly': true
                },
                'updatedAt': {
                    'name': 'updatedAt',
                    'isArray': false,
                    'type': 'AWSDateTime',
                    'isRequired': false,
                    'attributes': [],
                    'isReadOnly': true
                }
            },
            'syncable': true,
            'pluralName': 'Users',
            'attributes': [
                {
                    'type': 'model',
                    'properties': {}
                },
                {
                    'type': 'auth',
                    'properties': {
                        'rules': [
                            {
                                'provider': 'userPools',
                                'ownerField': 'username',
                                'allow': 'owner',
                                'operations': [
                                    'create',
                                    'read',
                                    'update'
                                ],
                                'identityClaim': 'cognito:username'
                            },
                            {
                                'groupClaim': 'cognito:groups',
                                'provider': 'userPools',
                                'allow': 'groups',
                                'groups': [
                                    'admins'
                                ],
                                'operations': [
                                    'read',
                                    'create',
                                    'update',
                                    'delete'
                                ]
                            },
                            {
                                'allow': 'private',
                                'operations': [
                                    'read',
                                    'create'
                                ],
                                'provider': 'iam'
                            },
                            {
                                'allow': 'private',
                                'operations': [
                                    'create',
                                    'read'
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        'Fridge': {
            'name': 'Fridge',
            'fields': {
                'id': {
                    'name': 'id',
                    'isArray': false,
                    'type': 'ID',
                    'isRequired': true,
                    'attributes': []
                },
                'owner': {
                    'name': 'owner',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': true,
                    'attributes': []
                },
                'ingredients': {
                    'name': 'ingredients',
                    'isArray': true,
                    'type': {
                        'nonModel': 'Ingredient'
                    },
                    'isRequired': false,
                    'attributes': [],
                    'isArrayNullable': true
                },
                'createdAt': {
                    'name': 'createdAt',
                    'isArray': false,
                    'type': 'AWSDateTime',
                    'isRequired': false,
                    'attributes': [],
                    'isReadOnly': true
                },
                'updatedAt': {
                    'name': 'updatedAt',
                    'isArray': false,
                    'type': 'AWSDateTime',
                    'isRequired': false,
                    'attributes': [],
                    'isReadOnly': true
                }
            },
            'syncable': true,
            'pluralName': 'Fridges',
            'attributes': [
                {
                    'type': 'model',
                    'properties': {}
                },
                {
                    'type': 'auth',
                    'properties': {
                        'rules': [
                            {
                                'provider': 'userPools',
                                'ownerField': 'owner',
                                'allow': 'owner',
                                'operations': [
                                    'create',
                                    'read',
                                    'update'
                                ],
                                'identityClaim': 'cognito:username'
                            },
                            {
                                'allow': 'private',
                                'operations': [
                                    'create',
                                    'read'
                                ],
                                'provider': 'iam'
                            }
                        ]
                    }
                }
            ]
        },
        'Recipe': {
            'name': 'Recipe',
            'fields': {
                'id': {
                    'name': 'id',
                    'isArray': false,
                    'type': 'ID',
                    'isRequired': true,
                    'attributes': []
                },
                'duration': {
                    'name': 'duration',
                    'isArray': false,
                    'type': 'Int',
                    'isRequired': false,
                    'attributes': []
                },
                'owner': {
                    'name': 'owner',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': true,
                    'attributes': []
                },
                'ingredients': {
                    'name': 'ingredients',
                    'isArray': true,
                    'type': {
                        'nonModel': 'Ingredient'
                    },
                    'isRequired': false,
                    'attributes': [],
                    'isArrayNullable': true
                },
                'name': {
                    'name': 'name',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': []
                },
                'types': {
                    'name': 'types',
                    'isArray': true,
                    'type': {
                        'enum': 'MealType'
                    },
                    'isRequired': false,
                    'attributes': [],
                    'isArrayNullable': true
                },
                'scheduledDays': {
                    'name': 'scheduledDays',
                    'isArray': true,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': [],
                    'isArrayNullable': true
                },
                'servings': {
                    'name': 'servings',
                    'isArray': false,
                    'type': 'Int',
                    'isRequired': false,
                    'attributes': []
                },
                'description': {
                    'name': 'description',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': []
                },
                'createdAt': {
                    'name': 'createdAt',
                    'isArray': false,
                    'type': 'AWSDateTime',
                    'isRequired': false,
                    'attributes': [],
                    'isReadOnly': true
                },
                'updatedAt': {
                    'name': 'updatedAt',
                    'isArray': false,
                    'type': 'AWSDateTime',
                    'isRequired': false,
                    'attributes': [],
                    'isReadOnly': true
                }
            },
            'syncable': true,
            'pluralName': 'Recipes',
            'attributes': [
                {
                    'type': 'model',
                    'properties': {}
                },
                {
                    'type': 'auth',
                    'properties': {
                        'rules': [
                            {
                                'provider': 'userPools',
                                'ownerField': 'owner',
                                'allow': 'owner',
                                'identityClaim': 'cognito:username',
                                'operations': [
                                    'create',
                                    'update',
                                    'delete',
                                    'read'
                                ]
                            },
                            {
                                'allow': 'private',
                                'operations': [
                                    'read',
                                    'create'
                                ],
                                'provider': 'iam'
                            }
                        ]
                    }
                }
            ]
        },
        'Issue': {
            'name': 'Issue',
            'fields': {
                'id': {
                    'name': 'id',
                    'isArray': false,
                    'type': 'ID',
                    'isRequired': true,
                    'attributes': []
                },
                'type': {
                    'name': 'type',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': []
                },
                'title': {
                    'name': 'title',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': []
                },
                'description': {
                    'name': 'description',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': []
                },
                'user': {
                    'name': 'user',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': []
                },
                'upvotes': {
                    'name': 'upvotes',
                    'isArray': true,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': [],
                    'isArrayNullable': false
                },
                'comments': {
                    'name': 'comments',
                    'isArray': true,
                    'type': {
                        'nonModel': 'Comment'
                    },
                    'isRequired': false,
                    'attributes': [],
                    'isArrayNullable': true
                },
                'approved': {
                    'name': 'approved',
                    'isArray': false,
                    'type': 'Boolean',
                    'isRequired': false,
                    'attributes': []
                },
                'completed': {
                    'name': 'completed',
                    'isArray': false,
                    'type': 'Boolean',
                    'isRequired': false,
                    'attributes': []
                },
                'dateCompleted': {
                    'name': 'dateCompleted',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': []
                },
                'tags': {
                    'name': 'tags',
                    'isArray': true,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': [],
                    'isArrayNullable': true
                },
                'createdAt': {
                    'name': 'createdAt',
                    'isArray': false,
                    'type': 'AWSDateTime',
                    'isRequired': false,
                    'attributes': [],
                    'isReadOnly': true
                },
                'updatedAt': {
                    'name': 'updatedAt',
                    'isArray': false,
                    'type': 'AWSDateTime',
                    'isRequired': false,
                    'attributes': [],
                    'isReadOnly': true
                }
            },
            'syncable': true,
            'pluralName': 'Issues',
            'attributes': [
                {
                    'type': 'model',
                    'properties': {}
                },
                {
                    'type': 'auth',
                    'properties': {
                        'rules': [
                            {
                                'allow': 'private',
                                'operations': [
                                    'read',
                                    'create'
                                ]
                            },
                            {
                                'provider': 'userPools',
                                'ownerField': 'owner',
                                'allow': 'owner',
                                'identityClaim': 'cognito:username',
                                'operations': [
                                    'create',
                                    'update',
                                    'delete',
                                    'read'
                                ]
                            },
                            {
                                'groupClaim': 'cognito:groups',
                                'provider': 'userPools',
                                'allow': 'groups',
                                'groups': [
                                    'admins'
                                ],
                                'operations': [
                                    'read',
                                    'create',
                                    'update',
                                    'delete'
                                ]
                            },
                            {
                                'allow': 'public',
                                'operations': [
                                    'read'
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        'Preference': {
            'name': 'Preference',
            'fields': {
                'id': {
                    'name': 'id',
                    'isArray': false,
                    'type': 'ID',
                    'isRequired': true,
                    'attributes': []
                },
                'startTime': {
                    'name': 'startTime',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': []
                },
                'endTime': {
                    'name': 'endTime',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': []
                },
                'categories': {
                    'name': 'categories',
                    'isArray': true,
                    'type': {
                        'nonModel': 'Category'
                    },
                    'isRequired': false,
                    'attributes': [],
                    'isArrayNullable': true
                },
                'breakFrequency': {
                    'name': 'breakFrequency',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': []
                },
                'breakTime': {
                    'name': 'breakTime',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': []
                },
                'user': {
                    'name': 'user',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': true,
                    'attributes': []
                },
                'dinnerTimeWindow': {
                    'name': 'dinnerTimeWindow',
                    'isArray': false,
                    'type': {
                        'nonModel': 'TimeWindow'
                    },
                    'isRequired': false,
                    'attributes': []
                },
                'breakfastTimeWindow': {
                    'name': 'breakfastTimeWindow',
                    'isArray': false,
                    'type': {
                        'nonModel': 'TimeWindow'
                    },
                    'isRequired': false,
                    'attributes': []
                },
                'lunchTimeWindow': {
                    'name': 'lunchTimeWindow',
                    'isArray': false,
                    'type': {
                        'nonModel': 'TimeWindow'
                    },
                    'isRequired': false,
                    'attributes': []
                },
                'groceryTime': {
                    'name': 'groceryTime',
                    'isArray': false,
                    'type': 'Int',
                    'isRequired': false,
                    'attributes': []
                },
                'nutritionLimits': {
                    'name': 'nutritionLimits',
                    'isArray': false,
                    'type': {
                        'nonModel': 'NutritionLimits'
                    },
                    'isRequired': false,
                    'attributes': []
                },
                'archiveTime': {
                    'name': 'archiveTime',
                    'isArray': false,
                    'type': 'Int',
                    'isRequired': false,
                    'attributes': []
                },
                'sortOrder': {
                    'name': 'sortOrder',
                    'isArray': true,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': [],
                    'isArrayNullable': true
                },
                'completionEffect': {
                    'name': 'completionEffect',
                    'isArray': false,
                    'type': 'Boolean',
                    'isRequired': false,
                    'attributes': []
                },
                'sortEntryOrder': {
                    'name': 'sortEntryOrder',
                    'isArray': true,
                    'type': {
                        'nonModel': 'SortEntry'
                    },
                    'isRequired': false,
                    'attributes': [],
                    'isArrayNullable': true
                },
                'startOfWeek': {
                    'name': 'startOfWeek',
                    'isArray': false,
                    'type': {
                        'enum': 'DayOfWeek'
                    },
                    'isRequired': false,
                    'attributes': []
                },
                'coinsPerTask': {
                    'name': 'coinsPerTask',
                    'isArray': false,
                    'type': {
                        'nonModel': 'AmountPerTimeframe'
                    },
                    'isRequired': false,
                    'attributes': []
                },
                'createdAt': {
                    'name': 'createdAt',
                    'isArray': false,
                    'type': 'AWSDateTime',
                    'isRequired': false,
                    'attributes': [],
                    'isReadOnly': true
                },
                'updatedAt': {
                    'name': 'updatedAt',
                    'isArray': false,
                    'type': 'AWSDateTime',
                    'isRequired': false,
                    'attributes': [],
                    'isReadOnly': true
                }
            },
            'syncable': true,
            'pluralName': 'Preferences',
            'attributes': [
                {
                    'type': 'model',
                    'properties': {}
                },
                {
                    'type': 'auth',
                    'properties': {
                        'rules': [
                            {
                                'provider': 'userPools',
                                'ownerField': 'user',
                                'allow': 'owner',
                                'operations': [
                                    'create',
                                    'read',
                                    'update'
                                ],
                                'identityClaim': 'cognito:username'
                            },
                            {
                                'allow': 'private',
                                'operations': [
                                    'read',
                                    'create'
                                ],
                                'provider': 'iam'
                            }
                        ]
                    }
                }
            ]
        },
        'Task': {
            'name': 'Task',
            'fields': {
                'id': {
                    'name': 'id',
                    'isArray': false,
                    'type': 'ID',
                    'isRequired': true,
                    'attributes': []
                },
                'taskName': {
                    'name': 'taskName',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': []
                },
                'daily': {
                    'name': 'daily',
                    'isArray': false,
                    'type': {
                        'nonModel': 'Daily'
                    },
                    'isRequired': false,
                    'attributes': []
                },
                'duration': {
                    'name': 'duration',
                    'isArray': false,
                    'type': 'Int',
                    'isRequired': false,
                    'attributes': []
                },
                'type': {
                    'name': 'type',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': []
                },
                'scheduledDate': {
                    'name': 'scheduledDate',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': []
                },
                'scheduledTime': {
                    'name': 'scheduledTime',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': []
                },
                'priority': {
                    'name': 'priority',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': []
                },
                'completed': {
                    'name': 'completed',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': []
                },
                'category': {
                    'name': 'category',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': []
                },
                'dueDate': {
                    'name': 'dueDate',
                    'isArray': false,
                    'type': 'Boolean',
                    'isRequired': false,
                    'attributes': []
                },
                'dueDateDate': {
                    'name': 'dueDateDate',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': []
                },
                'user': {
                    'name': 'user',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': true,
                    'attributes': []
                },
                'ingredients': {
                    'name': 'ingredients',
                    'isArray': true,
                    'type': {
                        'nonModel': 'Ingredient'
                    },
                    'isRequired': false,
                    'attributes': [],
                    'isArrayNullable': true
                },
                'archived': {
                    'name': 'archived',
                    'isArray': false,
                    'type': 'Boolean',
                    'isRequired': false,
                    'attributes': []
                },
                'blockedOn': {
                    'name': 'blockedOn',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': []
                },
                'datesAssigned': {
                    'name': 'datesAssigned',
                    'isArray': true,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': [],
                    'isArrayNullable': true
                },
                'subTasks': {
                    'name': 'subTasks',
                    'isArray': true,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': [],
                    'isArrayNullable': true
                },
                'frequencyInDays': {
                    'name': 'frequencyInDays',
                    'isArray': false,
                    'type': 'Int',
                    'isRequired': false,
                    'attributes': []
                },
                'rank': {
                    'name': 'rank',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': []
                },
                'createdAt': {
                    'name': 'createdAt',
                    'isArray': false,
                    'type': 'AWSDateTime',
                    'isRequired': false,
                    'attributes': [],
                    'isReadOnly': true
                },
                'updatedAt': {
                    'name': 'updatedAt',
                    'isArray': false,
                    'type': 'AWSDateTime',
                    'isRequired': false,
                    'attributes': [],
                    'isReadOnly': true
                }
            },
            'syncable': true,
            'pluralName': 'Tasks',
            'attributes': [
                {
                    'type': 'model',
                    'properties': {}
                },
                {
                    'type': 'auth',
                    'properties': {
                        'rules': [
                            {
                                'provider': 'userPools',
                                'ownerField': 'user',
                                'allow': 'owner',
                                'identityClaim': 'cognito:username',
                                'operations': [
                                    'create',
                                    'update',
                                    'delete',
                                    'read'
                                ]
                            },
                            {
                                'allow': 'private',
                                'operations': [
                                    'read',
                                    'create'
                                ],
                                'provider': 'iam'
                            }
                        ]
                    }
                }
            ]
        }
    },
    'enums': {
        'TimeframeType': {
            'name': 'TimeframeType',
            'values': [
                'MINUTE',
                'HOUR'
            ]
        },
        'DayOfWeek': {
            'name': 'DayOfWeek',
            'values': [
                'MONDAY',
                'TUESDAY',
                'WEDNESDAY',
                'THURSDAY',
                'FRIDAY',
                'SATURDAY',
                'SUNDAY'
            ]
        },
        'SortType': {
            'name': 'SortType',
            'values': [
                'PRIORITY',
                'DURATION',
                'DUEDATE',
                'GOALS',
                'AMOUNTSCHEDULED'
            ]
        },
        'GoalType': {
            'name': 'GoalType',
            'values': [
                'DAY',
                'WEEK',
                'MONTH',
                'YEAR'
            ]
        },
        'MealType': {
            'name': 'MealType',
            'values': [
                'BREAKFAST',
                'LUNCH',
                'DINNER'
            ]
        }
    },
    'nonModels': {
        'AmountPerTimeframe': {
            'name': 'AmountPerTimeframe',
            'fields': {
                'amount': {
                    'name': 'amount',
                    'isArray': false,
                    'type': 'Float',
                    'isRequired': true,
                    'attributes': []
                },
                'timeFrameValue': {
                    'name': 'timeFrameValue',
                    'isArray': false,
                    'type': 'Float',
                    'isRequired': true,
                    'attributes': []
                },
                'timeFrameType': {
                    'name': 'timeFrameType',
                    'isArray': false,
                    'type': {
                        'enum': 'TimeframeType'
                    },
                    'isRequired': true,
                    'attributes': []
                }
            }
        },
        'SortEntry': {
            'name': 'SortEntry',
            'fields': {
                'id': {
                    'name': 'id',
                    'isArray': false,
                    'type': 'ID',
                    'isRequired': false,
                    'attributes': []
                },
                'type': {
                    'name': 'type',
                    'isArray': false,
                    'type': {
                        'enum': 'SortType'
                    },
                    'isRequired': true,
                    'attributes': []
                },
                'ascending': {
                    'name': 'ascending',
                    'isArray': false,
                    'type': 'Boolean',
                    'isRequired': false,
                    'attributes': []
                },
                'sortEntryId': {
                    'name': 'sortEntryId',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': []
                }
            }
        },
        'ScheduledMeal': {
            'name': 'ScheduledMeal',
            'fields': {
                'recipeId': {
                    'name': 'recipeId',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': []
                },
                'recipeType': {
                    'name': 'recipeType',
                    'isArray': false,
                    'type': {
                        'enum': 'MealType'
                    },
                    'isRequired': false,
                    'attributes': []
                },
                'associatedTaskId': {
                    'name': 'associatedTaskId',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': []
                }
            }
        },
        'DateTimeWindow': {
            'name': 'DateTimeWindow',
            'fields': {
                'date': {
                    'name': 'date',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': []
                },
                'startTime': {
                    'name': 'startTime',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': []
                },
                'endTime': {
                    'name': 'endTime',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': []
                }
            }
        },
        'NutritionLimits': {
            'name': 'NutritionLimits',
            'fields': {
                'calories': {
                    'name': 'calories',
                    'isArray': false,
                    'type': 'Float',
                    'isRequired': false,
                    'attributes': []
                },
                'fat': {
                    'name': 'fat',
                    'isArray': false,
                    'type': 'Float',
                    'isRequired': false,
                    'attributes': []
                },
                'protein': {
                    'name': 'protein',
                    'isArray': false,
                    'type': 'Float',
                    'isRequired': false,
                    'attributes': []
                },
                'sugar': {
                    'name': 'sugar',
                    'isArray': false,
                    'type': 'Float',
                    'isRequired': false,
                    'attributes': []
                },
                'water': {
                    'name': 'water',
                    'isArray': false,
                    'type': 'Float',
                    'isRequired': false,
                    'attributes': []
                }
            }
        },
        'TimeWindow': {
            'name': 'TimeWindow',
            'fields': {
                'startTime': {
                    'name': 'startTime',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': []
                },
                'endTime': {
                    'name': 'endTime',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': []
                }
            }
        },
        'Ingredient': {
            'name': 'Ingredient',
            'fields': {
                'name': {
                    'name': 'name',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': []
                },
                'amount': {
                    'name': 'amount',
                    'isArray': false,
                    'type': 'Float',
                    'isRequired': false,
                    'attributes': []
                },
                'unit': {
                    'name': 'unit',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': []
                },
                'id': {
                    'name': 'id',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': []
                }
            }
        },
        'Comment': {
            'name': 'Comment',
            'fields': {
                'comment': {
                    'name': 'comment',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': []
                },
                'username': {
                    'name': 'username',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': []
                }
            }
        },
        'Category': {
            'name': 'Category',
            'fields': {
                'categoryId': {
                    'name': 'categoryId',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': []
                },
                'name': {
                    'name': 'name',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': []
                },
                'color': {
                    'name': 'color',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': []
                },
                'startTime': {
                    'name': 'startTime',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': []
                },
                'endTime': {
                    'name': 'endTime',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': []
                },
                'day': {
                    'name': 'day',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': []
                },
                'restrict': {
                    'name': 'restrict',
                    'isArray': false,
                    'type': 'Boolean',
                    'isRequired': false,
                    'attributes': []
                },
                'prioritize': {
                    'name': 'prioritize',
                    'isArray': false,
                    'type': 'Boolean',
                    'isRequired': false,
                    'attributes': []
                },
                'scheduledTimeWindows': {
                    'name': 'scheduledTimeWindows',
                    'isArray': true,
                    'type': {
                        'nonModel': 'DateTimeWindow'
                    },
                    'isRequired': false,
                    'attributes': [],
                    'isArrayNullable': true
                },
                'static': {
                    'name': 'static',
                    'isArray': false,
                    'type': 'Boolean',
                    'isRequired': false,
                    'attributes': []
                }
            }
        },
        'Daily': {
            'name': 'Daily',
            'fields': {
                'enabled': {
                    'name': 'enabled',
                    'isArray': false,
                    'type': 'Boolean',
                    'isRequired': false,
                    'attributes': []
                },
                'dateTimes': {
                    'name': 'dateTimes',
                    'isArray': false,
                    'type': 'String',
                    'isRequired': false,
                    'attributes': []
                }
            }
        }
    },
    'codegenVersion': '3.4.4',
    'version': '784df5f635c94e800970d1226ee529c7'
};