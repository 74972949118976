import { Sort } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';

type Props = {
    ascending: boolean,
    onClick: (ascending: boolean) => void
}

export function OrderButton({ascending, onClick}: Props) {
    const [ localAscending, setLocalAscending ] = useState(ascending);

    useEffect(() => {setLocalAscending(ascending);}, [ascending]);

    return <Tooltip title={ascending ? 'Ascending' : 'Descending'}>
        <IconButton
            onClick={() => {
                onClick(!localAscending);
                setLocalAscending(!localAscending);
            }}
            size="large"
        >
            { localAscending ? <Sort style={{ transform: 'rotate(180deg) scaleX(-1)' }}/> : <Sort/> }
        </IconButton>
    </Tooltip>;
}