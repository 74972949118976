import React, { useState } from 'react';
import { Divider, Box, TextField, Tooltip } from '@mui/material';
import { testIds } from 'constants/TestConstants';

type Props = {
    setStartTime: (startTime: string) => void,
    setEndTime: (endTime: string) => void,
    startTime: string|null|undefined,
    endTime: string|null|undefined,
    title: string,
    startTimeError?: boolean,
    endTimeError?: boolean,
    disabled?: boolean
    disabledText?: string
}

function createTextField(
    value: string|null|undefined,
    label: string,
    testLabel: string,
    onChange: (time: string) => void,
    disabled?: boolean,
    disabledText?: string,
    error?: boolean
) {
    if (disabled) {
        return <Tooltip title={disabledText} placement='top'>
            <TextField
                variant="standard"
                type="time"
                defaultValue={value}
                InputLabelProps={{
                    shrink: true,
                }}
                label={label}
                error={error}
                disabled={disabled}
                data-testid={testLabel}
                sx={{
                    '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: 'grey',
                    },
                }}
                onChange={(event) => onChange(event.target.value)} />
        </Tooltip>;
    } else {
        return <TextField
            variant="standard"
            type="time"
            defaultValue={value}
            InputLabelProps={{
                shrink: true,
            }}
            label={label}
            error={error}
            disabled={disabled}
            data-testid={testLabel}
            onChange={(event) => onChange(event.target.value)} />;
    }
}

export default function TimeWindowInput({
    setStartTime,
    setEndTime,
    startTime,
    endTime,
    title,
    startTimeError = false,
    endTimeError = false,
    disabled = false,
    disabledText
}: Props) {
    const [localStartTime, setLocalStartTime] = useState(startTime);
    const [localEndTime, setLocalEndTime] = useState(endTime);

    return <Box>
        <h3>{title}</h3>
        {createTextField(
            localStartTime,
            'Start',
            testIds.timeWindowStartInput,
            (startTime) => {
                setLocalStartTime(startTime);
                setStartTime(startTime);
            },
            disabled,
            disabledText,
            startTimeError
        )}
        {createTextField(
            localEndTime,
            'End',
            testIds.timeWindowEndInput,
            (endTime) => {
                setLocalEndTime(endTime);
                setEndTime(endTime);
            },
            disabled,
            disabledText,
            endTimeError
        )}
        <Divider />
    </Box>;
}