import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import React, { useState } from 'react';

type Props = {
    password: string,
    setPassword: (password: string) => void,
    label: string,
    error?: boolean,
    autoComplete?: string
};

export function PasswordInput({ password, setPassword, label, error, autoComplete }: Props) {
    const [ showPassword, setShowPassword ] = useState(false);

    return <TextField
        variant="standard"
        label={label}
        type={showPassword ? 'text' : 'password'}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        autoComplete={autoComplete}
        error={error}
        data-testid="password-input"
        // Add show password button https://stackoverflow.com/questions/60391113/how-to-view-password-from-material-ui-textfield
        InputProps={{
            endAdornment: (
                <InputAdornment position="end">
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={() => setShowPassword(!showPassword)}
                    >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                </InputAdornment>
            )
        }}
    />;
}