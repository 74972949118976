import { Card, Grid } from '@mui/material';
import { CheckCircle, RadioButtonUnchecked } from '@mui/icons-material';
import { getCurrentDate, getLatestDate } from 'helpers/TimeHelper';
import React from 'react';
import StreakIcon from './StreakIcon';
import { testIds } from 'constants/TestConstants';
import { Task } from 'models';
import { convertToDateTimes, getDaysArray } from './DailysCommon';

type Props = {
    tasks: Task[],
    isBetaUser: boolean
};

export default function Dailys({ tasks, isBetaUser }: Props) {
    const dailys = tasks.filter(task => task.daily && task.daily.enabled);

    return <>
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={2}
        >
            {dailys.map((task) => {
                const dateTimes = convertToDateTimes(task.daily!.dateTimes);
                if (dateTimes && Object.keys(dateTimes).length > 0) {

                    // First scheduled date
                    const earliestScheduledDate = Object.keys(dateTimes).reduce(function (pre, cur) {
                        return Date.parse(pre) > Date.parse(cur) ? cur : pre;
                    });

                    const latestDate = getLatestDate(Object.keys(dateTimes));

                    // Gets whatever date is later between the first scheduled date and 30 days ago
                    const startingDate = new Date(latestDate);
                    startingDate.setDate(startingDate.getDate() - 30);
                    const earliestDate = getLatestDate([ startingDate.toISOString(), earliestScheduledDate ]);

                    const dates = getDaysArray(earliestDate, latestDate);
                    const today = getCurrentDate();
                    const completed = dateTimes && dateTimes[today] && dateTimes[today].completed ? true : false;
                    return <Card style={{maxWidth:300}} key={task.id}>
                        <StreakIcon
                            dateTimes={dateTimes}
                            today={today}
                            completed={completed}
                        />
                        {isBetaUser && <StreakIcon
                            dateTimes={dateTimes}
                            today={today}
                            completed={completed}
                            isHighScore={true}
                        />}
                        <br/>
                        {task.taskName}
                        <br/>
                        {
                            dates.map((date) => {
                                const dateString = date.toISOString().slice(0,10);
                                if (dateTimes[dateString] && dateTimes[dateString].completed) {
                                    return <CheckCircle key={task.taskName + dateString} data-testid={testIds.dailyChecked}/>;
                                }
                                return <RadioButtonUnchecked key={task.taskName + dateString} data-testid={testIds.dailyUnchecked}/>;
                            })
                        }
                    </Card>;
                }
            })}
        </Grid>
    </>;
}