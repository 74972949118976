import { Button } from '@mui/material';
import React from 'react';

type Props = {
    isComplete: boolean,
    isMod: boolean,
    setComplete: (complete: boolean) => void
}

export default function CompleteButtons({ isComplete, isMod, setComplete }: Props) {
    if (!isComplete && isMod) {
        return <Button 
            style={{width: '100px', display: 'inline-block', float: 'right', textAlign:'right', backgroundColor: 'green'}}
            onClick={() => setComplete(true)}
        >
            Complete
        </Button>;
    } else if(isComplete && isMod) {
        return <Button 
            style={{width: '100px', display: 'inline-block', float: 'right', textAlign:'right', backgroundColor: 'orange'}}
            onClick={() => setComplete(false)}
        >
            Reopen
        </Button>;
    } else {
        return <></>;
    }
}