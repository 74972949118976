import { DataStore } from 'aws-amplify/datastore';
import { taskPriorities, taskTypes } from 'constants/TaskConstants';
import { updateRecipe, updateTask } from 'helpers/DataStoreHelper';
import { addToListDictionary } from 'helpers/ListHelpers';
import { isTaskComplete } from 'helpers/TaskHelpers';
import { calculateTimeFromMinutes } from 'helpers/TimeHelper';
import { Daily, Ingredient, MealType, Recipe, ScheduledMeal, Task } from 'models';
import { isTaskGroomed } from 'tasks/taskCommon/ConfigCommon';

export function createRecipeTask(
    recipe: Recipe,
    today: string,
    scheduledTimeMinutes: number,
    mealType: MealType,
    username: string,
    pushError: (error: string) => void
) {
    console.log('Creating task for ' + mealType);

    const daily = new Daily({
        enabled: false,
        dateTimes: '{}',
    });
    const newTask = {
        taskName: 'Cook ' + recipe.name + ' for ' + mealType,
        type: taskTypes.SCHEDULED,
        daily,
        duration: recipe.duration,
        priority: taskPriorities.HIGH,
        user: username,
        scheduledDate: today,
        scheduledTime: calculateTimeFromMinutes(scheduledTimeMinutes),
        _deleted: false,
    };
    const newTaskObj = new Task(newTask);
    console.log(newTaskObj);
    DataStore.save(newTaskObj)
        .catch((error) => pushError(error));

    const newScheduledDays = recipe.scheduledDays ? [ ...recipe.scheduledDays ] : [];
    newScheduledDays.push(today);
    updateRecipe(recipe.id, { scheduledDays: newScheduledDays }, pushError);

    // This breaks typescript, I dont think its needed though?
    // If it is will need to create a new type
    /*return { ...newTaskObj, daily: {
        enabled: false,
        dateTimes: {}
    }};*/

    return newTaskObj;
}

export function removeScheduledTask(taskId: string, localTasks: Task[], pushError: (error: string) => void) {
    console.log(`Deleting scheduled task with id ${taskId}`);
    
    DataStore.delete(Task, task => task.id.eq(taskId))
        .then(() => console.log(`Successfully delete scheduled task with id ${taskId}`))
        .catch((error) => pushError(error));

    return [...localTasks].filter((task) => task.id !== taskId);
}

type IngredientNameToAmount = {
    [ingName: string]: number
}
export function createGroceryTask(
    unownedIngredients: Ingredient[],
    groceryTask: Task,
    groceryTime: number,
    username: string,
    today:string,
    scheduledTimeMinutes: number,
    pushError: (error: string) => void
) {
    // Check is there already a grocery task
    console.log('Creating grocery task');
    if (groceryTask) {
        // if so add recipe ingredients to that task
        const newIngredients = groceryTask.ingredients ? [ ...groceryTask.ingredients ] : [];
        const ingredientNameToAmount = newIngredients
            .reduce<IngredientNameToAmount>((acc: IngredientNameToAmount, currentIng: Ingredient | null) => {
                if (currentIng) {
                    const ingName = currentIng.name ? currentIng.name : '';
                    const ingAmount = currentIng.amount ? currentIng.amount : 0;
                    return { ...acc, [ingName]: ingAmount};
                }

                return { ...acc };
            }, {} as IngredientNameToAmount);

        unownedIngredients.forEach((ingredient) => {
            const ingName = ingredient.name ? ingredient.name : '';
            if (ingredientNameToAmount[ingName.toLowerCase()]) {
                // TODO
                // javascript find idk man
            } else {
                newIngredients.push(ingredient);
            }
        });

        // Check if the task has been scheduled or not, if not schedule
        const scheduledDate = groceryTask.scheduledDate ? groceryTask.scheduledDate : today;
        const scheduledTime = groceryTask.scheduledTime ? groceryTask.scheduledTime : calculateTimeFromMinutes(scheduledTimeMinutes);
        updateTask(groceryTask.id, { ingredients: newIngredients, scheduledDate, scheduledTime }, pushError);
        return { ...groceryTask, ingredients: newIngredients };
    } else {
        const daily = new Daily({
            enabled: false,
            dateTimes: '{}',
        });
        const newTask = {
            taskName: 'Get Groceries',
            type: taskTypes.GROCERY,
            daily,
            duration: groceryTime,
            priority: taskPriorities.HIGH,
            user: username,
            scheduledDate: today,
            scheduledTime: calculateTimeFromMinutes(scheduledTimeMinutes),
            ingredients: unownedIngredients,
            _deleted: false,
        };
        const newTaskObj = new Task(newTask);
        DataStore.save(newTaskObj)
            .catch((error) => pushError(error));
        return newTaskObj;
    }
}

export function getTaskFromScheduledMeal(
    scheduledMeal: ScheduledMeal | null | undefined,
    tasks: Task[],
) {
    // if there is a scheduledmeal and a selected meal, check if they are the same recipe. if not update task
    // if there is a scheduledmeal but no selected meal, find task and remove it
    if(scheduledMeal && scheduledMeal.associatedTaskId) {
        return tasks.find((task) => task.id === scheduledMeal.associatedTaskId);
    }

    return null;
}

type FridgeNameToAmount = {
    [name: string]: number
}
export function getNeededIngredients(recipe: Recipe, fridgeNameToAmount: FridgeNameToAmount) {
    // Get all required ingredients
    const unownedIngredients: Ingredient[] = [];
    const recipeIngredients = recipe.ingredients ? recipe.ingredients : [];
    recipeIngredients.forEach((ingredient) => {
        if (ingredient) {
            const name = ingredient.name ? ingredient.name.toLowerCase() : '';
            const amount = ingredient.amount ? ingredient.amount : 0;
            if (!fridgeNameToAmount[name]) {
                unownedIngredients.push(ingredient);
            } else if (fridgeNameToAmount[name] < amount) {
                unownedIngredients.push({
                    name: name,
                    amount: amount - fridgeNameToAmount[name],
                    unit: ingredient.unit
                });
            }
        }
    });

    return unownedIngredients;
}

export function isTaskNotComplete(task: Task, today: string) {
    return !isTaskComplete(task, today);
}

export function isBlockedTaskCompleted(taskId: string, tasks: Task[], today: string) {
    const targetTask = tasks.filter((task) => task.id === taskId)[0];
    return targetTask && (targetTask.archived || !isTaskNotComplete(targetTask, today));
}

export function getPlannableTasks(tasks: Task[], today: string) {
    return tasks
        .filter(task => task.type !== taskTypes.SCHEDULED)
        .filter(task => task.duration)
        // Filter ungroomed tasks
        .filter(task => isTaskGroomed(task))
        // Filter completed tasks
        .filter(task => isTaskNotComplete(task, today))
        // Filter blocked tasks out
        // TODO might be a way to make this more efficient by using blockingTasks from above
        .filter(task => !task.blockedOn || task.blockedOn === '' || isBlockedTaskCompleted(task.blockedOn, tasks, today))
        // Filter parent tasks out
        .filter(task => !task.subTasks || task.subTasks.length < 1);
}

export type BlockTaskObject = {
    [index: string]: Task[]
}
export function getBlockingTasks(tasks: Task[], today: string) {
    return tasks.reduce((workingBlockingTasks, currentTask) => {
        if (currentTask.blockedOn && currentTask.blockedOn !== '') {
            const blockingTask = tasks.filter((task) => task.id === currentTask.blockedOn)[0];
            // Tasks that are blocked on completed tasks are already included in uncompleted tasks below
            if (blockingTask && !(blockingTask.archived || !isTaskNotComplete(blockingTask, today))) {
                workingBlockingTasks = addToListDictionary(currentTask, blockingTask.id, workingBlockingTasks);
            }
        }

        return workingBlockingTasks;
    }, {} as BlockTaskObject);
}