import React, { useState } from 'react';
import { Avatar, Button, FormControlLabel, Switch } from '@mui/material';

type Props = {
    username: string,
    openPasswordModal: () => void,
    completionEffectsEnabled: boolean,
    setCompletionEffectsEnabled: (enabled: boolean) => void
}

export default function GeneralSettings({
    username,
    openPasswordModal,
    completionEffectsEnabled,
    setCompletionEffectsEnabled
}: Props) {
    const [ completionEffects, setCompletionEffects ] = useState(completionEffectsEnabled);

    return <div>
        <h1>Account</h1>
        <div style={{ display: 'flex', marginBottom: '10px', textAlign:'center' }}>
            <Avatar style={{ width: 64, height: 64 }}>{username.charAt(0)}</Avatar>
            <p style={{paddingLeft: '10px'}}>{username}</p>
        </div>
        <Button variant="contained" onClick={openPasswordModal}>Change Password</Button>
        <h1>Toggles</h1>
        <FormControlLabel
            control={<Switch
                checked={completionEffects !== false}
                onChange={(event) => {
                    setCompletionEffects(event.target.checked);
                    setCompletionEffectsEnabled(event.target.checked);
                }}
            />}
            label="Completion Effects"
        />
    </div>;
}
