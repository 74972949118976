import { taskTypes } from 'constants/TaskConstants';
import { dateDiffInDays, isDateBefore, calculateMinutesFromTime, getFormattedDate } from './TimeHelper';
import { LocalTask } from 'models/CommonTypes';
import { Preference, Task } from 'models';
import { TaskUpdates } from 'models/DataStoreUpdateTypes';
import { convertToDateTimes } from 'dailys/DailysCommon';

export function getScheduledTime(task: Task, today: string) {
    // TODO I would think theoretically that if daily is enabled we should never return scheduled time
    // If task is intended to be scheduled but daily is enabled we should prefer scheduledTime
    if (!task.scheduledTime && task.daily && task.daily.enabled && task.daily.dateTimes) {
        const dateTimes = convertToDateTimes(task.daily!.dateTimes);
        if (dateTimes[today] && dateTimes[today].time) {
            return dateTimes[today].time;
        } else {
            return '00:00';
        }
    }

    return task.scheduledTime;
}

export function isTaskComplete(task: Task, today?: string|null) {
    // TODO when converting to typescript should change this to require nonnull
    if (!task) {
        return false;
    }

    if (today && task.daily && (task.daily.enabled || task.frequencyInDays) && task.daily.dateTimes) {
        const dateTimes = convertToDateTimes(task.daily!.dateTimes);
        if (dateTimes[today]) {
            return dateTimes[today].completed;
        }
    } else if (today && task.type === taskTypes.SCHEDULED && task.frequencyInDays) {
        const lastDate = lastRepeatedScheduledDate(task.scheduledDate,
            task.frequencyInDays, today);
        if (task.daily && task.daily.dateTimes) {
            const dateTimes = convertToDateTimes(task.daily!.dateTimes);
            return dateTimes[lastDate] && dateTimes[lastDate].completed;
        } else {
            return false;
        }
    } else {
        return task.completed;
    }
}

export function isDueDateClose(task: Task, today: string) {
    return task.dueDate && task.dueDateDate && dateDiffInDays(new Date(today), new Date(task.dueDateDate)) === 1;
}

export function getPastTasks(tasks: Task[], today: string, timeOfDayMinutes: number) {
    return tasks.filter((task) => {
        if (!task.completed && (!task.daily || !task.daily.enabled) && task.type !== taskTypes.SCHEDULED) {
            if ((task.scheduledDate && isDateBefore(task.scheduledDate, today)) 
                    || (task.scheduledDate && task.scheduledDate === today &&
                        task.scheduledTime && calculateMinutesFromTime(task.scheduledTime) < timeOfDayMinutes)) {
                return true;
            }
        } else if (!task.completed && task.daily && task.daily && task.daily.dateTimes && task.type !== taskTypes.SCHEDULED) {
            const dateTimes = convertToDateTimes(task.daily!.dateTimes);
            if (dateTimes[today] && !dateTimes[today].completed && dateTimes[today].time && calculateMinutesFromTime(dateTimes[today].time) < timeOfDayMinutes) {
                return true;
            }
        }

        return false;
    });
}

export function archiveTasks(tasks: LocalTask[], preferences: Preference, changeTask: (id: string, taskUpdates: TaskUpdates) => void) {
    const dateMinusArchiveTime = new Date();
    const numOfDays = preferences.archiveTime || 3;
    dateMinusArchiveTime.setDate(dateMinusArchiveTime.getDate() - numOfDays);
    tasks.forEach((task) => {
        if (task.completed && !(task.daily && task.daily.enabled) &&
            (new Date(task.completed + ' 00:00') < dateMinusArchiveTime
                || (task.scheduledDate && new Date(task.scheduledDate + ' 00:00') < dateMinusArchiveTime))) {
            console.log('Archiving task: ' + task.id);
            changeTask(task.id, { archived: true });
        }
    });
}

export function sanitizeASAPTasks(tasks: Task[], today: string, changeTask: (id: string, taskUpdates: TaskUpdates) => void) {
    return tasks.map((task) => {
        if (task.daily && task.daily.enabled && task.daily.dateTimes) {
            const dateTimes = convertToDateTimes(task.daily.dateTimes);
            if (dateTimes[today] && !dateTimes[today].completed) {
                delete dateTimes[today];
                task = {
                    ...task,
                    daily: {
                        ...task.daily,
                        dateTimes: JSON.stringify(dateTimes)
                    }
                };
                changeTask(task.id, { ...task });
            }
        } else if ((task.type === taskTypes.ASAP || task.type === taskTypes.GROCERY) && !task.completed) {
            changeTask(task.id, {
                scheduledTime: null,
                scheduledDate: null,
            });
            return {
                ...task,
                scheduledTime: null,
                scheduledDate: null,
            };
        }
        return task;
    });
}

export function lastRepeatedScheduledDate(scheduledDate: string|null|undefined, frequencyInDays: number, today: string) {
    // TODO: Idk if this null handling is correct
    const originalScheduledDate = scheduledDate ? new Date(scheduledDate) : new Date(today);
    const daysFromToday = dateDiffInDays(originalScheduledDate, new Date(today));
    const daysFromScheduledDate = daysFromToday/frequencyInDays;
    // TBH no idea why the plus 1 is needed, the diff seems correct so must be something with
    // the way adding dates works
    originalScheduledDate.setDate(originalScheduledDate.getDate() + (daysFromScheduledDate + 1));
    return getFormattedDate(originalScheduledDate);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function deepEqual(x: any, y: any): boolean {
    return (x && y && typeof x === 'object' && typeof y === 'object') ?
        (Object.keys(x).length === Object.keys(y).length) &&
        Object.keys(x).reduce(function(isEqual, key) {
            return isEqual && deepEqual(x[key], y[key]);
        }, true) : (x === y);
}