import { Checkbox, Container, TextField } from '@mui/material';
import { InputTextField } from 'common/InputTextField';
import { testIds } from 'constants/TestConstants';
import React, { useState } from 'react';

export default function ScheduledInputs({ scheduledDate, scheduledTime, frequencyInDays, handleConfigChange }) {
    const [ repeatingEnabled, setRepeatingEnabled] = useState(frequencyInDays);

    return <Container data-testid={testIds.scheduledInputs}>
        <div style={{ display: 'flex' }}>
            <TextField
                variant="standard"
                label="Task Date"
                type="date"
                defaultValue={scheduledDate}
                InputLabelProps={{
                    shrink: true,
                }}
                data-testid={testIds.dateInput}
                onChange={(event) => handleConfigChange('scheduledDate', event.target.value)}
            />
            <TextField
                variant="standard"
                type="time"
                defaultValue={scheduledTime}
                InputLabelProps={{
                    shrink: true,
                }}
                label="Task Time"
                error={!scheduledTime}
                data-testid={testIds.timeInput}
                onChange={(event) => handleConfigChange('scheduledTime', event.target.value)}
            />
        </div>
        <div style={{ fontSize: '18px' }}>
            <Checkbox
                onChange={(event) => setRepeatingEnabled(event.target.checked)}
                value={repeatingEnabled}
                checked={repeatingEnabled ? true : false}
            />
                Repeat
            {repeatingEnabled &&
                    <>{' every'}
                        <InputTextField
                            style={{ maxWidth: '30px' }}
                            name="frequencyInDays"
                            error={repeatingEnabled && (!frequencyInDays || frequencyInDays < 1)}
                            value={frequencyInDays}
                            onChangeEvent={(value) => handleConfigChange('frequencyInDays', parseInt(value))}
                            isNumber={true}
                        />
                    days</>
            }
        </div>
    </Container>;
}