import React, { ReactElement } from 'react';
import { Category, Task } from 'models';
import { getScheduledTime } from 'helpers/TaskHelpers';
import CalendarTask from './CalendarTask';
import { calculateMinutesFromDate, calculateMinutesFromTime } from 'helpers/TimeHelper';

type Props = {
    tasks: Task[],
    today: string,
    timeOfDayMinutes: number,
    startTime: Date,
    categories: Category[]
}

export default function CalendarTasks({ tasks, today, timeOfDayMinutes, startTime, categories }: Props) {
    let currentMinutes = Math.max(calculateMinutesFromDate(startTime), timeOfDayMinutes);
    const calendarTasks = [] as ReactElement[];

    tasks.forEach((task) => {
        const scheduledTime = getScheduledTime(task, today);
        const expectedTime = scheduledTime ? calculateMinutesFromTime(scheduledTime) : currentMinutes;
        calendarTasks.push(<CalendarTask 
            key={task.id}
            task={task}
            taskTime={expectedTime}
            today={today}
            timeOfDayMinutes={timeOfDayMinutes}
            categories={categories}
        />);
        currentMinutes = expectedTime + (task.duration ? task.duration : 0);
    });

    return <div>
        {calendarTasks}
    </div>;
}