import { taskPriorities, taskTypes } from 'constants/TaskConstants';
import { isTaskComplete } from './TaskHelpers';
import { Ingredient } from 'models';
import { FridgeNameToAmount, LocalTask } from 'models/CommonTypes';

const redColor = '#FAB3AB';
const brightRedColor = 'red';
const orangeColor = '#FAD0A9';
const yellowColor = '#FAEDA7';
const blueColor = '#63cdda';
const groceryColor = '#BD7EB5';
const greenColor = '#8FF081';
const greyColor = '#A8A8A8';

export function getTaskColor(task: LocalTask, today?: string|null) {
    if (isTaskComplete(task, today)) {
        return greenColor;
    }

    // TODO figure out if blockedOn task is complete
    if (task.blockedOn && task.blockedOn !== '' && task.blockedOnTask && !task.blockedOnTask.completed) {
        return greyColor;
    }

    if (task.type === taskTypes.SCHEDULED) {
        return blueColor;
    } else if (task.type === taskTypes.ASAP) {
        if (task.priority === taskPriorities.HIGH) {
            return redColor;
        } else if (task.priority === taskPriorities.MEDIUM) {
            return orangeColor;
        } else if (task.priority === taskPriorities.LOW) {
            return yellowColor;
        }
        
        return 'white';
    } else if (task.type === taskTypes.GROCERY) {
        return groceryColor;
    }
}

export function getIngredientColor(fridgeNameToAmount: FridgeNameToAmount, ingredient: Ingredient) {
    if (ingredient.name && fridgeNameToAmount[ingredient.name.toLowerCase()]) {
        if (ingredient.amount && ingredient.amount <= fridgeNameToAmount[ingredient.name.toLowerCase()]) {
            return greenColor;
        } else {
            return yellowColor;
        }
    } else {
        return 'background.paper';
    }
}

export function getSelectedColor(selected: boolean) {
    return selected ? greenColor : 'white';
}

export function getCompletedColor(completed: boolean) {
    return completed ? greenColor : 'background.paper';
}

export function getBlockedIconColor(blocked: boolean) {
    return blocked ? brightRedColor : '';
}

export function getPalette(mode: string) {
    return mode === 'light'
        ? {
            text: {
                primary: '#2f3640',
                secondary: '#2f3640',
                disabled: '#2f3640',
            },
        }
        : {

        };
}