import { isJsonString } from 'helpers/TextHelper';
import { calculateMinutesFromTime, formatMinutes, getWeekDay } from 'helpers/TimeHelper';
import { notEmpty } from 'helpers/TypesHelper';
import { Category, DateTimeWindow } from 'models';
import { CategoryBlob, CategoryInstance } from 'models/CommonTypes';

export function isCategoryScheduledToday(category: Category, today: string) {
    const weekDay = getWeekDay(new Date(today));
    if (category.static === false) {
        if(category.scheduledTimeWindows) {
            const timeWindowsToday = category.scheduledTimeWindows
                .filter(notEmpty)
                .filter(timeWindow => timeWindow.date && timeWindow.date === today);
            return timeWindowsToday.length > 0;
        } else {
            return false;
        }
    } else {
        if (!category.day) return false;

        const day = typeof category.day == 'string' ? JSON.parse(category.day) : category.day;
        return day[weekDay];
    }
}

export function isTimeWithinCategoryWindow(category: Category, time: number, date: string) {
    if (category.static === false) {
        if(category.scheduledTimeWindows) {
            const timeWindowsToday = category.scheduledTimeWindows.filter(notEmpty).filter(cat => cat.date === date);
            return timeWindowsToday.some(timeWindow =>
                calculateMinutesFromTime(timeWindow.startTime) <= time 
                && calculateMinutesFromTime(timeWindow.endTime) > time);
        } else {
            return false;
        }
    } else {
        return calculateMinutesFromTime(category.startTime) <= time 
            && calculateMinutesFromTime(category.endTime) > time;
    }
}

export function toCategoryInstances(category: Category, today: string): CategoryInstance[] {
    const weekDay = getWeekDay(new Date(today));
    if (category.static === false) {
        if(category.scheduledTimeWindows) {
            const timeWindowsToday = category.scheduledTimeWindows
                .filter(notEmpty)
                .filter(timeWindow => timeWindow.date && timeWindow.date === today);
            return timeWindowsToday.map((timeWindow, index) => {
                return {
                    categoryId: category.categoryId || 'Unknown',
                    name: category.name,
                    color: category.color,
                    startTime: timeWindow.startTime,
                    endTime: timeWindow.endTime,
                    static: category.static,
                    instanceNumber: index
                };
            });
        }
    } else if (category.day && isJsonString(category.day) && JSON.parse(category.day)[weekDay]){
        return [{
            categoryId: category.categoryId || 'Unknown',
            name: category.name,
            color: category.color,
            startTime: category.startTime,
            endTime: category.endTime,
            static: category.static,
            instanceNumber: 0
        }];
    }

    return [];
}

export function CategoryDaysToObject(category: Category): CategoryBlob {
    let day;

    if (!category.day) {
        day = null;
    } else if (typeof category.day == 'string') {
        day = JSON.parse(category.day);
    } else {
        day = category.day;
    }

    return {
        ...category,
        day
    };
}

export function CategoryDaysToString(category: CategoryBlob): Category {
    let day;

    if (!category.day) {
        day = null;
    } else if (typeof category.day == 'object') {
        day = JSON.stringify(category.day);
    } else {
        day = category.day;
    }

    return {
        ...category,
        day
    };
}

export function getNonNullTimeWindows(category: Category) {
    return category.scheduledTimeWindows
        ? category.scheduledTimeWindows.filter(notEmpty)
        : [];
}

export function isTimeWindowEqual(timeWindow:DateTimeWindow|null, today:string, start:number, end:number) {
    return timeWindow && timeWindow.date === today
    && timeWindow.startTime === formatMinutes(start)
    && timeWindow.endTime === formatMinutes(end);
}