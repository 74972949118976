import { Box, Button, Card, List, ListItem, TextField } from '@mui/material';
import Alert from '@mui/material/Alert';
import { DataStore } from 'aws-amplify/datastore';
import { updateMealDiaryEntry } from 'helpers/DataStoreHelper';
import { MealDiaryEntry } from 'models';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { calculateUnitizedAmount } from './Common/IngredientCommon';
import IngredientListItem from './Common/IngredientListItem';
import { testIds } from 'constants/TestConstants';

export default function MealDiary({ diaryResponse, nutritionFacts, preferences, username, today, setToday, recipeResponse, pushError }) {
    const [ diaryUpdates, setDiaryUpdates ] = useState({});
    const [ diaryEntrys, setDiaryEntrys ] = useState(diaryResponse.filter((entry) => entry.date == today));
    const [ unsaved, setUnsaved ] = useState(false);
    const history = useHistory();

    useEffect(() => {
        setDiaryEntrys(diaryResponse.filter((entry) => entry.date == today));
    }, [today]);

    const updateEntry = useCallback((index, key, value) => {
        const newDiaryEntry = diaryEntrys[index];
        setDiaryUpdates({
            ...diaryUpdates,
            [newDiaryEntry.id]: {
                ...diaryUpdates[newDiaryEntry.id],
                [key]: value
            }
        });

        // This may cause performance issues,
        //  but its also needed to keep nutrition info updates on change
        //  so far performance does seem too bad, but if it is may need to rethink
        const tempDiaryEntries = [ ...diaryEntrys ];
        tempDiaryEntries[index] = {
            ...tempDiaryEntries[index],
            [key]: value
        };
        setDiaryEntrys(tempDiaryEntries);

        setUnsaved(true);
    }, [ diaryUpdates, diaryEntrys ]);

    const nameToNutritionFact = useMemo(() => {
        return nutritionFacts.reduce((prevNutrition, currentNutrition) => {
            if (currentNutrition.name) {
                return { ...prevNutrition, [currentNutrition.name.toLowerCase()]: currentNutrition };
            } else {
                return prevNutrition;
            }
        }, {});
    }, [nutritionFacts]);

    const nameToRecipe = useMemo(() => {
        return recipeResponse.reduce((prevRecipes, currentRecipe) => {
            if (currentRecipe.name) {
                return { ...prevRecipes, [currentRecipe.name.toLowerCase()]: currentRecipe };
            } else {
                return prevRecipes;
            }
        }, {});
    }, [recipeResponse]);

    const hasErrors = useMemo(() => {
        return diaryEntrys.some((entry) => !entry.name || entry.name.length === 0 || !entry.amount || entry.amount.length === 0);
    }, [diaryEntrys]);

    const nutrititionInfo = useMemo(() => {
        var totalCal = 0.0;
        var totalFat = 0.0;
        var totalProtein = 0.0;
        var totalSugar = 0.0;
        diaryEntrys.forEach((ingredient) => {
            if (ingredient.name) {
                const ingredientName = ingredient.name.toLowerCase();
                if (nameToRecipe[ingredientName]) {
                    nameToRecipe[ingredientName].ingredients.forEach((recipeIng) => {
                        if (nameToNutritionFact[recipeIng.name.toLowerCase()]) {
                            const ingredientNutrition = nameToNutritionFact[recipeIng.name.toLowerCase()];
                            totalCal += calculateUnitizedAmount(recipeIng, ingredientNutrition, 'calories');
                            totalFat += calculateUnitizedAmount(recipeIng, ingredientNutrition, 'totalFat');
                            totalProtein += calculateUnitizedAmount(recipeIng, ingredientNutrition, 'protein');
                            totalSugar += calculateUnitizedAmount(recipeIng, ingredientNutrition, 'sugar');
                        }
                    });
                } else if (nameToNutritionFact[ingredientName]) {
                    const ingredientNutrition = nameToNutritionFact[ingredientName];
                    totalCal += calculateUnitizedAmount(ingredient, ingredientNutrition, 'calories');
                    totalFat += calculateUnitizedAmount(ingredient, ingredientNutrition, 'totalFat');
                    totalProtein += calculateUnitizedAmount(ingredient, ingredientNutrition, 'protein');
                    totalSugar += calculateUnitizedAmount(ingredient, ingredientNutrition, 'sugar');
                }
            }
        });

        return {
            totalCal,
            totalFat,
            totalProtein,
            totalSugar
        };
    }, [ diaryEntrys, nameToNutritionFact, nameToRecipe ]);

    const deleteDiaryEntry = useCallback((index) => {
        const newDiaryEntrys = [...diaryEntrys];
        const deleted = newDiaryEntrys.splice(index, 1);
        DataStore.delete(MealDiaryEntry, post => post.id.eq(deleted[0].id))
            .then((response) => console.log(response))
            .catch((error) => pushError(error));
        setDiaryEntrys(newDiaryEntrys);
    }, [diaryEntrys]);

    const saveCurrentUpdates = useCallback(() => {
        Object.keys(diaryUpdates).forEach((key) => {
            updateMealDiaryEntry(key, diaryUpdates[key], pushError);
        });
        // TODO handle errors
        return [];
    }, [diaryUpdates]);

    return <div data-testid={testIds.mealDiary}>
        <Alert severity="warning">This feature is currently being built and is not expected to work properly.</Alert>
        <Card>
            <TextField
                variant="standard"
                type="date"
                defaultValue={today}
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={(event) => {
                    history.push(`?date=${event.target.value}`);
                    setToday(event.target.value);
                }} />
        </Card>
        <p style={{fontSize: '20px', padding:'0px', margin: '0px'}}>Nutrition Info</p>
        <p style={{fontSize: '15px', padding:'0px', margin: '0px'}}>Calories: {nutrititionInfo.totalCal}{preferences.nutritionLimits ? '/' + preferences.nutritionLimits.calories : ''}</p>
        <p style={{fontSize: '15px', padding:'0px', margin: '0px'}}>Total Fat: {nutrititionInfo.totalFat}g{preferences.nutritionLimits && preferences.nutritionLimits.fat ? '/' + preferences.nutritionLimits.fat + 'g' : ''}</p>
        <p style={{fontSize: '15px', padding:'0px', margin: '0px'}}>Protein: {nutrititionInfo.totalProtein}g{preferences.nutritionLimits && preferences.nutritionLimits.protein ? '/' + preferences.nutritionLimits.protein + 'g' : ''}</p>
        <p style={{fontSize: '15px', padding:'0px', margin: '0px'}}>Sugar: {nutrititionInfo.totalSugar}g{preferences.nutritionLimits && preferences.nutritionLimits.sugar ? '/' + preferences.nutritionLimits.sugar + 'g' : ''}</p>
        <h3>Eaten today</h3>
        <Box sx={{ width: '100%', maxWidth: '1000px', bgcolor: 'background.paper', margin: '15px' }}>
            <List>
                {unsaved && <><Button
                    style={{backgroundColor: 'green'}}
                    disabled={hasErrors}
                    onClick={() => {
                        saveCurrentUpdates();
                        setUnsaved(false);
                    }}
                >
                    save
                </Button><br/></>}
                { diaryEntrys.map((ingredient, index) =>
                    <IngredientListItem
                        key={'diary' + ingredient.name + index}
                        ingredient={ingredient}
                        updateIngredient={(key, value) => updateEntry(index, key, value)}
                        deleteFunc={() => deleteDiaryEntry(index)}
                        autoCompleteOptions={[
                            ...Object.keys(nameToNutritionFact),
                            ...Object.keys(nameToRecipe),
                        ]}
                    />)
                }
                <ListItem>
                    <Button style={{width:'100%'}} onClick={() => {
                        const newDiaryEntrys = [ ...diaryEntrys ];
                        const newDiaryEntry = new MealDiaryEntry({
                            owner: username,
                            date: today
                        });
                        DataStore.save(newDiaryEntry).catch((error) => pushError(error));
                        newDiaryEntrys.push(newDiaryEntry);
                        setDiaryEntrys(newDiaryEntrys);
                    }}>+</Button>
                </ListItem>
            </List>
        </Box>
    </div>;
}