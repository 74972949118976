import { Card, CircularProgress, Grid } from '@mui/material';
import { getFirstDayOfThisMonth, getFirstDayOfThisYear, getThisDayOfWeek } from 'helpers/TimeHelper';
import { Goal, GoalType, Preference, Task } from 'models';
import React, { useCallback, useMemo } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import GoalComponent from './GoalComponent';
import { testIds } from 'constants/TestConstants';
import { DateCard } from 'common/DateCard';
import { handleConfigChange } from 'tasks/taskCommon/ConfigCommon';
import { isTaskComplete } from 'helpers/TaskHelpers';
import { TasksById } from 'models/CommonTypes';
import { saveTaskUpdates } from 'helpers/DataStoreHelper';
import { TaskUpdates } from 'models/DataStoreUpdateTypes';

type Props = {
    goals: Goal[],
    tasks: Task[],
    today: string,
    username: string,
    preferences: Preference,
    pushError: (error: string) => void,
    setToday: (today: string) => void
}

export default function GoalPage({ goals, tasks, today, username, preferences, pushError, setToday }: Props) {
    const [ tasksById, setTasksById ] = useState<TasksById>({});
    const [ loadingTasks, setLoadingTasks ] = useState(true);

    useEffect(() => {
        const tasksMapping = tasks.reduce((obj, task) => ({ ...obj, [task.id]: task }), {});
        setTasksById(tasksMapping);
        setLoadingTasks(false);
    }, [ tasks ]);

    const completeTask = useCallback((taskId: string) => {
        // complete task
        const task = tasksById[taskId];
        if (task) {
            const saveTaskUpdate = (newTask:TaskUpdates) => {
                saveTaskUpdates({
                    [taskId]: newTask
                }, pushError);
            };

            const newState = handleConfigChange('completed', isTaskComplete(task) ? null : 'true', saveTaskUpdate, task, today) as Task;

            // update tasksById
            const newTasksById = { ...tasksById };
            newTasksById[taskId] = { ...task, ...newState };
            setTasksById(newTasksById);
        } else {
            pushError('Trying to complete task that does not exist.');
        }
    }, [tasksById, setTasksById]);

    const dayGoals = useMemo(() => {
        return goals.filter((goal) => goal.type === GoalType.DAY)
            .filter((goal) => goal.date === today);
    }, [ goals, today ]);

    const weekGoals = useMemo(() => {
        return goals.filter((goal) => goal.type === GoalType.WEEK)
            .filter((goal) => goal.date === getThisDayOfWeek(today, preferences.startOfWeek));
    }, [ goals, today ]);

    const monthGoals = useMemo(() => {
        return goals.filter((goal) => goal.type === GoalType.MONTH)
            .filter((goal) => goal.date === getFirstDayOfThisMonth(today));
    }, [ goals, today ]);

    const yearGoals = useMemo(() => {
        return goals.filter((goal) => goal.type === GoalType.YEAR)
            .filter((goal) => goal.date === getFirstDayOfThisYear(today));
    }, [ goals, today ]);

    const goalComponentRender = useCallback((goals: Goal[], type: GoalType) => <GoalComponent
        goals={goals}
        username={username}
        pushError={pushError}
        type={type}
        tasks={tasks}
        tasksById={tasksById}
        today={today}
        preferences={preferences}
        completeTask={completeTask}
    />, [tasksById, username, tasks, today, preferences, completeTask, pushError]);

    if (loadingTasks) {
        return <CircularProgress/>;
    }

    return <div data-testid={testIds.goalPage} style={{maxWidth:'100%'}}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <DateCard today={today} setToday={(date) => setToday(date)}/>
        </div>
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
        >
            <Card style={{ margin: '5px', minWidth: '300px', maxWidth: '300px' }}>
                <h4>Day Goals</h4>
                {goalComponentRender(dayGoals, GoalType.DAY)}
            </Card>
            <br/>
            <Card style={{ margin: '5px', minWidth: '300px', maxWidth: '300px' }}>
                <h4>Week Goals</h4>
                {goalComponentRender(weekGoals, GoalType.WEEK)}
            </Card>
            <br/>
            <Card style={{ margin: '5px', minWidth: '300px', maxWidth: '300px' }}>
                <h4>Month Goals</h4>
                {goalComponentRender(monthGoals, GoalType.MONTH)}
            </Card>
            <br/>
            <Card style={{ margin: '5px', minWidth: '300px', maxWidth: '300px' }}>
                <h4>Year Goals</h4>
                {goalComponentRender(yearGoals, GoalType.YEAR)}
            </Card>
        </Grid>
    </div>;
}