import React from 'react';
import reactStringReplace from 'react-string-replace';

export function separateNewLines(text: string, keyPrefix: string, fontSize = '20px') {
    return text && text.split('\n').map((text, index) =>
        <p style={{ margin:5, fontSize: fontSize }} key={keyPrefix + index}>
            {urlify(text)}
        </p>
    );
}

//https://stackoverflow.com/questions/1500260/detect-urls-in-text-with-javascript
export function urlify(text: string) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return reactStringReplace(text, urlRegex, function(url) {
        return <a href={url}>{url}</a>;
    });
}

export function verifySentence(sentence: string, badWords: string[]) {
    let isBannedWords = false;
    const formattedSentence = sentence.replace(/[.,\\/#!$%\\^&\\*;:{}=\-_`~()]/g, '')
        .trim()
        .toLowerCase();
    const noSpaces = formattedSentence.replace(/\s+/g, '');
    isBannedWords = badWords.some((badWord) => noSpaces.includes(badWord));
    // Below checks every word in a sentence, problem is someone could just put spaces
    /*formattedSentence
        .split(' ')
        .forEach((word) => {
            console.log(word);
            if (badWords.includes(word)) {
                console.log('word is in banned words');
                isBannedWords = true;
            }
        });*/
    return !isBannedWords;
}

export function isString(a: string) {
    return (typeof a === 'string');
}

export function isJsonString(str: string) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}