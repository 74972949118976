import { Button, Card } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Alert from '@mui/material/Alert';
import { DataStore } from 'aws-amplify/datastore';
import { updateRecipe } from 'helpers/DataStoreHelper';
import { Recipe } from 'models';
import React, { useCallback, useState } from 'react';
import { testIds } from 'constants/TestConstants';
import RecipePage from './Recipe/RecipePage';

export default function MealPrep({ recipeResponse, fridgeResponse, foodInfoResponse, username, pushError }) {
    const [ recipes, setRecipes ] = useState(recipeResponse);
    const [ currentRecipe, setCurrentRecipe ] = useState(0);

    const deleteRecipe = useCallback((index) => {
        const newRecipes = [...recipes];
        const deletedRecipe = newRecipes.splice(index, 1);
        DataStore.delete(Recipe, thisrecipe => thisrecipe.id.eq(deletedRecipe[0].id))
            .then((response) => console.log(response))
            .catch((error) => pushError(error));
        setRecipes(newRecipes);
    }, [ recipes ]);

    return <>
        <Alert severity="warning">This feature is currently being built and is not expected to work properly.</Alert>
        <Button onClick={() => {
            console.log('creating new recipe');
            const newRecipes = recipeResponse ? [ ...recipeResponse ] : [];
            const newRecipe = new Recipe({
                owner: username
            });
            DataStore.save(newRecipe).catch((error) => pushError(error));
            newRecipes.unshift(newRecipe);
            setRecipes(newRecipes);
        }}><AddIcon style={{ position: 'relative', top: '50%' }} fontSize="large" /> Add Recipe</Button>
        <Card style={{width: '100%', backgroundColor:'grey', overflow:'visible'}} data-testid={testIds.recipes}>
            <Button
                onClick={() => setCurrentRecipe(currentRecipe - 1)}
                disabled={currentRecipe == 0}
                style={{ verticalAlign: 'center', height: '500px' }}
            >prev</Button>
            {recipes && recipes.length > 0 && <RecipePage
                key={recipes[currentRecipe].id || 'None'}
                recipe={recipes[currentRecipe]}
                deleteRecipe={() => deleteRecipe(currentRecipe)}
                updateRecipe={updateRecipe}
                nutritionFacts={foodInfoResponse}
                fridgeIngredients={fridgeResponse.ingredients}
                pushError={pushError}
            />}
            <Button
                onClick={() => setCurrentRecipe(currentRecipe + 1)}
                disabled={currentRecipe >= recipes.length - 1}
                style={{ verticalAlign: 'center', minHeight: '500px' }}
            >next</Button>
        </Card>
    </>;
}