import { Dehaze } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { testIds } from 'constants/TestConstants';
import React from 'react';

type Props = {
    onClick: () => void;
}

export function DrawerButton({onClick}: Props) {
    return <IconButton
        style={{width: '50px', display: 'inline-block',  textAlign:'left', color:'white'}}
        onClick={onClick}
        size="large"
        data-testid={testIds.drawerButton}
    >
        <Dehaze/>
    </IconButton>;
}