import { IngredientUnit } from 'constants/MealConstants';
import { ValueUnits } from './ValueUnits';

export function calculateUnitizedAmount(ingredient, nutritionInformation, type) {
    const servingSize = nutritionInformation.servingSize ? nutritionInformation.servingSize : 1;
    // Convert to correct units
    // If we are missing units somewhere, assume that it is "x"
    var unitizedAmount = ingredient.amount;
    if (ingredient.unit && nutritionInformation.servingSizeUnit) {
        // Ingredients have the key for some reason stupid
        const ingredientUnit = IngredientUnit[ingredient.unit] ? IngredientUnit[ingredient.unit] : ingredient.unit;
        const valUnits = new ValueUnits(ingredient.amount, ingredientUnit);
        unitizedAmount = valUnits.toUnits(nutritionInformation.servingSizeUnit).value;
    }
    // Get the amount of servings
    const amount = unitizedAmount / servingSize;
    const value = amount * nutritionInformation[type];
    return roundToTwo(value);
}

function roundToTwo(num) {
    return +(Math.round(num + 'e+2')  + 'e-2');
}

export function getFridgeNameToAmount(fridgeIngredients) {
    if(fridgeIngredients) {
        return fridgeIngredients.reduce((previousIng, currentIng) => {
            if (currentIng && currentIng.name && currentIng.amount) {
                return { ...previousIng, [currentIng.name.toLowerCase()]: currentIng.amount};
            }

            return { ...previousIng };
        }, {});
    } else {
        return {};
    }
}