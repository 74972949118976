import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormGroup, FormControlLabel, Switch, Button } from '@mui/material';
import TaskTextComponent from './TaskTextComponent';
import { DataStore } from 'aws-amplify/datastore';
import { Task } from '../models';
import Alert from '@mui/material/Alert';
import { createNewTask, findTaskInMapDFS, generateTaskMap, getTaskMapLayerFromId, removeTaskFromParent } from './taskCommon/ConfigCommon';
import { UnsavedChangesPopup } from 'common/UnsavedChangesPopup';
import { taskRankComparator } from './taskCommon/TaskComparator';
import { logTimeToExecute } from 'helpers/DebuggingHelper';
import Nestable from 'react-nestable';
import { ArrowDropDown, ArrowRight } from '@mui/icons-material';
import { saveTaskUpdates } from 'helpers/DataStoreHelper';
import { taskTypes } from 'constants/TaskConstants';
import { onNestableChange } from './taskCommon/ExpandableListFunctions';
import { addTaskToParent } from './taskCommon/TaskCommonFunctions';

export default function TasksTextView({ ...props }) {
    const { tasks, username, isBetaUser, toggleTextView, pushError, preferences, today } = props;

    // Could maybe partition this
    const [ localTasks, setLocalTasks ] = useState(tasks.sort(taskRankComparator));
    const [ focusedTaskId, setFocusedTaskId ] = useState(null);
    const [ unsavedChanges, setUnsavedChanges ] = useState(false);
    const taskUpdates = useRef([]);

    // v2
    const [ taskById, setTaskById ] = useState({});
    const [ taskMap, setTaskMap ] = useState({});

    // If unsaved changes, show alert before leaving
    useEffect(() => {
        window.onbeforeunload = (event) => {
            // Show prompt based on state
            if (unsavedChanges) {
                const e = event || window.event;
                e.preventDefault();
                if (e) {
                    e.returnValue = '';
                }
                return '';
            }
        };
    }, [ unsavedChanges ]);

    useEffect(() => {
        logTimeToExecute(() => {
            //setLoadingTaskView(true);

            const response = generateTaskMap(localTasks, saveTaskUpdates, pushError);
            // TODO split up the taskMap based on top level tasks into ungroomed, incomplete, and completed

            setTaskById(response.tasksById);
            setTaskMap(response.taskMap);
            //setCategoryMap(response.tasksById);
            //setLoadingTaskView(false);
        }, 'generate task map');
    // TODO for some reason if pushError is included in this effect it triggers on every task update
    }, [ localTasks ]);

    const changeTask = useCallback((id, newSettings) => {
        console.log('task update');
        const newUpdates = { ...taskUpdates.current };
        newUpdates[id] = {
            ...newUpdates[id],
            ...newSettings
        };
        taskUpdates.current = newUpdates;
        setUnsavedChanges(true);
    }, [ ]);

    const makeChild = useCallback((taskId) => {
        // 1. Find task in task map (Or just pass in the task map on render)
        const layerTaskMap = getTaskMapLayerFromId(taskId, taskMap);
        // 2. Find task in localTasks
        const taskIndex = localTasks.findIndex((task) => task.id === taskId);
        // 3. Go up 1 task in localTasks until you find a task in the same layer
        //      (Layer being the the keys adjascent to task in map)
        var targetParent;
        var targetParentIndex;
        for (var i = taskIndex - 1; i >= 0; i--) {
            if (layerTaskMap[localTasks[i].id]) {
                targetParent = localTasks[i];
                targetParentIndex = i;
                break;
            }
        }
        // 4. If none push Eror
        if (!targetParent) {
            pushError(`Cannot make child task for task ${localTasks[taskIndex].taskName}`);
            return;
        } else if (targetParent.type === taskTypes.GROCERY) {
            pushError('Cannot add subtasks to grocery tasks.');
            return;
        }

        const newLocalTasks = [ ...localTasks ];

        // If old task had a parent remove it from the parent
        const taskHeirarchy = findTaskInMapDFS(taskId, taskMap);
        if (taskHeirarchy.length > 1) {
            const parentId = taskHeirarchy[1];
            const parentTaskIndex = newLocalTasks.findIndex((task) => task.id === parentId);
            removeTaskFromParent(taskId, parentId, parentTaskIndex, taskById, changeTask, newLocalTasks);
        }

        addTaskToParent(targetParent, targetParentIndex, taskById[taskId], changeTask, newLocalTasks, taskById);
        setLocalTasks(newLocalTasks);
    }, [ localTasks, taskById ]);

    const deleteTask = useCallback((id) => {
        const newTasks = [...localTasks].filter((task) => task.id !== id);
        DataStore.delete(Task, post => post.id.eq(id))
            .then((response) => console.log(response))
            .catch((error) => pushError(error));
        setLocalTasks(newTasks);
    }, [ localTasks ]);

    const addTask = useCallback((taskId) => {
        const newLocalTasks = [ ...localTasks ];
        // Find task in localTasks
        const index = newLocalTasks.findIndex((task) => task.id === taskId);
        const taskAbove = newLocalTasks[index];
        const taskBelow = newLocalTasks[index + 1] ? newLocalTasks[index + 1] : null;

        createNewTask(username, pushError, taskAbove, taskBelow).then((newTaskJson) => {
            newLocalTasks.splice(index + 1, 0, newTaskJson); //add at index
            console.log(taskAbove);

            // TODO feel like it would be better to blackbox this logic
            //is taskAbove a subtask, If so add this task as a subtask to the same task
            const taskHeirarchy = findTaskInMapDFS(taskId, taskMap);
            if (taskHeirarchy.length > 1) {
                const parentTaskId = taskHeirarchy[1];
                const parentTask = taskById[parentTaskId];
                const parentIndex = newLocalTasks.findIndex((task) => task.id === parentTaskId);
                // Just in case tasks are not ordered correctly in the subtask array
                //const orderedSubtasks = parentTask.subTasks.map((taskId) => taskById[taskId]).sort(taskRankComparator);
                const subTaskIndex = parentTask.subTasks.findIndex((subTaskId) => subTaskId === taskAbove.id);
                const tempTaskById = { ...taskById, [newTaskJson.id]: newTaskJson };
                addTaskToParent(parentTask, parentIndex, newTaskJson, changeTask, newLocalTasks, tempTaskById, subTaskIndex + 1);
            } else if (taskAbove.subTasks && taskAbove.subTasks.length > 0) { // If added at parent task, make it a subtask
                // Just in case tasks are not ordered correctly in the subtask array
                //const orderedSubtasks = parentTask.subTasks.map((taskId) => taskById[taskId]).sort(taskRankComparator);
                const subTaskIndex = taskAbove.subTasks.findIndex((subTaskId) => subTaskId === taskAbove.id);
                const tempTaskById = { ...taskById, [newTaskJson.id]: newTaskJson };
                addTaskToParent(taskAbove, index, newTaskJson, changeTask, newLocalTasks, tempTaskById, subTaskIndex + 1);
            }

            setFocusedTaskId(newTaskJson.id);
            setLocalTasks(newLocalTasks);
        });
    }, [ username, localTasks, taskMap, taskById, setFocusedTaskId ]);

    const handleNestableChange = useCallback((result) =>
        setLocalTasks(onNestableChange(localTasks, taskMap, taskById, result, changeTask, taskUpdates, pushError)),
    [ localTasks, taskMap, taskById, setLocalTasks, changeTask, pushError ]);

    const convertTaskMapToItems = useCallback((taskMap) => {
        const tasksToRender = Object.keys(taskMap)
            .map((taskId) => taskById[taskId])
            .sort(taskRankComparator);

        return tasksToRender.map((task) => ({
            id: task.id,
            children: convertTaskMapToItems(taskMap[task.id])
        }));
    }, [ taskById, localTasks ]);

    const renderCollapseIcon = useCallback(({ isCollapsed }) => {
        if (isCollapsed) {
            return <ArrowRight/>;
        }

        return <ArrowDropDown/>;
    }, []);

    return <>
        <UnsavedChangesPopup unsavedChanges={unsavedChanges} onSave={() => {
            saveTaskUpdates(taskUpdates.current, pushError);
            setUnsavedChanges(false);
            taskUpdates.current = [];
        }} />
        <FormGroup>
            {isBetaUser &&
                    <FormControlLabel
                        control={<Switch defaultChecked onChange={toggleTextView} />}
                        label="Text View"
                    />
            }
        </FormGroup>
        <Alert severity="warning">This feature is currently being built and is not expected to work properly.</Alert>
        <h2>Incomplete</h2>
        <div style={{alignItems: 'center', display: 'flex'}}>
            <Nestable
                items={convertTaskMapToItems(taskMap)}
                handler={<Button style={{ color:'white', minWidth: '5px'}}>{'•'}</Button>}
                renderCollapseIcon={(isCollapsed) => renderCollapseIcon(isCollapsed)}
                onChange={handleNestableChange}
                renderItem={({item, depth, handler, collapseIcon}) => {
                // TODO handle collapse/expand by creating button with collapseIcon
                // Onclick it should call the nestable
                    return <TaskTextComponent
                        indent={depth ? depth : 0}
                        key={item.id}
                        task={taskById[item.id]}
                        focused={focusedTaskId && focusedTaskId === item.id}
                        changeTask={(newSettings) => {
                            changeTask(item.id, newSettings);
                            setUnsavedChanges(true);
                        }}
                        addTask={() => addTask(item.id)}
                        deleteTask={() => deleteTask(item.id)}
                        makeChild={() => makeChild(item.id)}
                        handler={handler}
                        collapseIcon={collapseIcon}
                        categories={preferences.categories}
                        today={today}
                    />;
                }}
            />
        </div>
    </>;
}