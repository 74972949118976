import React from 'react';
import 'App.css';
import { Box, Button, Card, TextField } from '@mui/material';
import { useState } from 'react';
import { useCallback } from 'react';
import { Preference } from 'models';
import { verifySentence } from 'helpers/TextHelper';
import { PasswordInput } from 'common/PasswordInput';
import { swearWords } from 'common/swearWords';
import { testIds } from 'constants/TestConstants';
import { processCreateAccountError } from 'accountManagement/common/AccountManagementCommonFunctions';
import { signUp } from 'aws-amplify/auth';
import { DataStore } from 'aws-amplify/datastore';
import ValidateAccountModal from './ValidateAccountModal';

type Props = {
    updateHasAccount: () => void,
    resetPassword: () => void,
    pushError: (error: string) => void
}

export default function CreateAccount({ updateHasAccount, resetPassword, pushError }: Props) {
    const [ username, setUsername ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ confirmPassword, setConfirmPassword ] = useState('');
    const [ bannedWords ] = useState(swearWords.split('\r\n'));
    const [ validateOpen, setValidateOpen ] = useState(false);

    const updateValidateAccountModal = useCallback(() => {
        setValidateOpen(false);
        //TODO try to login, if fails send to login screen
        updateHasAccount();
    }, [ validateOpen, setValidateOpen ]);

    const createAccountCallback = useCallback(() => {
        if (verifySentence(username, bannedWords)) {
            signUp({
                username: username,
                password: password,
                options: {
                    userAttributes: {
                        email: email
                    }
                }
            }).then(() => {
                const newPreference = {
                    user: username
                };
                DataStore.save(new Preference(newPreference))
                    .catch((error) => pushError(error));
                setValidateOpen(true);
            }).catch((error) => {
                const errorString = error.message ? error.message : error;
                pushError(processCreateAccountError(errorString));
            });
        } else {
            pushError('Username contains a banned word, please use a different username.');
        }
    }, [username, password, bannedWords]);

    return <>
        { validateOpen && <ValidateAccountModal validateAccount={() => updateValidateAccountModal()} /> }
        <Card
            style={{width:300, minHeight:150, padding:5, margin:5, display: 'inline-block', overflow:'visible', verticalAlign: 'top'}}
            data-testid={testIds.createAccount}
        >
            Create Account
            <Box
                component="form"
            >
                <TextField
                    variant="standard"
                    name={'ProductivityUsername'}
                    label={'Username'}
                    value={username}
                    error={username.length < 1}
                    onChange={(e) => setUsername(e.target.value)} />
                <TextField
                    variant="standard"
                    name={'ProductivityEmail'}
                    label={'Email'}
                    value={email}
                    error={!email.includes('@')}
                    onChange={(e) => setEmail(e.target.value)} />
                <p style={{ fontSize: '10px' }}>Email is only used for account recovery.</p>
                <PasswordInput
                    password={password}
                    setPassword={setPassword}
                    label='Password'
                    error={password.length < 1}
                    autoComplete="productivity-password"
                />
                <p style={{ fontSize: '10px' }}>Password must contain 1 uppercase and lowercase character, 1 special character and 1 number.</p>
                <PasswordInput
                    password={confirmPassword}
                    setPassword={setConfirmPassword}
                    label='Confirm Password'
                    error={confirmPassword !== password || confirmPassword.length < 1}
                />
                <br/>
                <Button
                    onClick={() => createAccountCallback()}
                    disabled={confirmPassword !== password || confirmPassword.length < 1 || password.length < 1 || !email.includes('@') || username.length < 1}
                >
                    Create Account
                </Button>
            </Box>
            <div style={{ fontSize: '15px' }}>Already have an account? <a href="" onClick={(event) => {event.preventDefault(); updateHasAccount();}}>Log in!</a></div>
            <div style={{ fontSize: '15px' }}><a href="" onClick={(event) => {event.preventDefault(); resetPassword();}}>Forgot Password?</a></div>
        </Card>
    </>;
}