import React, { useCallback } from 'react';
import { Avatar, Box, IconButton, Menu, MenuItem, Switch, Tooltip } from '@mui/material';
import { signOut } from 'aws-amplify/auth';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import BetaModal from 'accountManagement/BetaModal';
import { ErrorPopUps } from './ErrorPopUps';
import { Settings } from '@mui/icons-material';
import { useErrorHook } from './ErrorHook';

type Props = {
    username: string,
    triggerAuth: () => void,
    isBetaUser: boolean,
    submitAnIssue?: boolean
}

export function AppBarProfile({ username, triggerAuth, isBetaUser, submitAnIssue = false }: Props) {
    const [ anchorEl, setAnchorEl ] = useState<HTMLElement|null>(null);
    const [ betaModalOpen, setBetaModalOpen ] = useState(false);
    const { errors, pushError, cancelError } = useErrorHook([]);
    const history = useHistory();
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const signOutCallback = useCallback(() => signOut()
        .then(() => {
            console.log('Successfully signed out');
            triggerAuth();
            window.location.reload();
        })
        .catch((error) => pushError('Error signing out: ' + error))
    , [ triggerAuth ]);
    
    return <Box style={{position:'absolute', right:10}}>
        {betaModalOpen &&
            <BetaModal handleClose={() => setBetaModalOpen(false)} isBetaUser={isBetaUser} username={username} pushError={pushError} />
        }
        { errors && <ErrorPopUps errors={errors} cancelError={cancelError} /> }
        { username && <Tooltip title="Preferences">
            <IconButton
                style={{width: '50px', color:'white'}}
                onClick={() => history.push('/preferences')}
                size="large">
                <Settings/>
            </IconButton>
        </Tooltip>}

        {
            // TODO once help page has been completed uncomment
            /*<IconButton
                    style={{width: '50px', color:'white'}}
                    onClick={() => history.push('/help')}
                >
                    <Help/>
                </IconButton>*/
        }

        {username &&
            <>
                <IconButton
                    onClick={handleClick}
                    size="small"
                >
                    <Avatar style={{ width: 32, height: 32 }}>{username.charAt(0)}</Avatar>
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    { 
                        // Commenting this out until theres actually something to show on profile
                        isBetaUser && <MenuItem onClick={() => history.push(`/profile?username=${username}`)}>Profile</MenuItem>
                    }
                    { submitAnIssue &&
                        <MenuItem onClick={() => history.push('/issues')}>Submit an Issue</MenuItem>
                    }
                    <MenuItem onClick={() => signOutCallback()}>Logout</MenuItem>
                    <MenuItem><Switch checked={isBetaUser} onChange={() => setBetaModalOpen(true)}/>Beta</MenuItem>
                </Menu>
            </>}
    </Box>;
}