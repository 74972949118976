import { Button, Card, Container, List, ListItem, MenuItem, Modal, Select } from '@mui/material';
import { FileCopy } from '@mui/icons-material';
import IngredientListItem from 'mealprep/Common/IngredientListItem';
import { v4 as uuidv4 } from 'uuid';
import React, { useCallback, useState } from 'react';
import { modalStyle } from 'constants/StylingConstants';
import { Ingredient } from 'models';

function getIngredientText(ingredients: Ingredient[]) {
    let ingredientText = '';
    ingredients.forEach((ingredients) => {
        const unitText = ingredients.unit ? ingredients.unit : 'x';
        ingredientText += ingredients.amount + unitText + ' ' + ingredients.name + '\n';
    });
    return ingredientText;
}

type Props = {
    taskIngredients: (Ingredient|null|undefined)[]|null|undefined,
    priority: string|null|undefined,
    handleConfigChange: (name: string, value: Ingredient[]|string) => void
}

export default function GroceryInputs({ taskIngredients, priority, handleConfigChange }: Props) {
    const [ groceryModalOn, setGroceryModalOn ] = useState(false);
    const [ ingredients, setIngredients ] = useState(
        taskIngredients ? taskIngredients.map(i => { return { ...i, id: uuidv4()}; }) : []
    );

    const handleClose = useCallback(() => {
        setGroceryModalOn(false);
    }, []);

    const updateIngredient = useCallback((index: number, key: string, value: string|number|null) => {
        const newIngredients = [ ...ingredients ];
        newIngredients[index] = {
            ...newIngredients[index],
            [key]: value
        };
        handleConfigChange('ingredients', newIngredients);
        setIngredients(newIngredients);
    }, [ ingredients ]);

    const deleteIngredient = useCallback((index: number) => {
        const newIngredients = [...ingredients];
        newIngredients.splice(index, 1);
        setIngredients(newIngredients);
        handleConfigChange('ingredients', newIngredients);
    }, [ ingredients ]);

    return <>
        {groceryModalOn &&
            <Modal
                open={true}
                onClose={handleClose}
            >
                <Card style={modalStyle}>
                    <div>
                        <Button
                            onClick={() => navigator.clipboard.writeText(getIngredientText(ingredients))}
                        >
                            <FileCopy/>
                            Copy Groceries to Clipboard
                        </Button>
                        <h2>
                            Groceries
                        </h2>
                    </div>
                    <List>
                        { ingredients.map((ingredient, index) => 
                            <IngredientListItem
                                key={ingredient.id /*Todd, dont use name here you dumb idiot*/}
                                ingredient={ingredient}
                                updateIngredient={(key, value) => updateIngredient(index, key, value)}
                                deleteFunc={() => deleteIngredient(index)}
                            />)
                        }
                        <ListItem>
                            <Button style={{width:'100%'}} onClick={() => {
                                const newIngredients = [ ...ingredients ];
                                const newIngredient = { id: uuidv4() };
                                newIngredients.push(newIngredient);
                                setIngredients(newIngredients);
                            }}>+</Button>
                        </ListItem>
                    </List>
                </Card>
            </Modal>
        }
        <Select
            variant="standard"
            value={priority || 'Medium'}
            onChange={(event) => handleConfigChange('priority', event.target.value)}>
            <MenuItem value="High">High</MenuItem>
            <MenuItem value="Medium">Medium</MenuItem>
            <MenuItem value="Low">Low</MenuItem>
        </Select>
        <Container>
            <Button
                onClick={() => setGroceryModalOn(true)}
            >
                Grocery List
            </Button>
        </Container>
    </>;
}