import { Card, CardActionArea, Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import TimeWindowInput from './TimeWindowInput';
import { InputTextField } from '../common/InputTextField';
import ChangePasswordModal from 'accountManagement/ChangePasswordModal';
import { UnsavedChangesPopup } from 'common/UnsavedChangesPopup';
import { isTimeBefore } from 'helpers/TimeHelper';
import { testIds } from 'constants/TestConstants';
import CategoryComponent from './Category/CategoryComponent';
import GeneralSettings from './SettingTabs/GeneralSettings';
import { SettingsCard } from './Common/SettingsCard';
import SchedulingPreferences from './SettingTabs/SchedulingPreferences';

const validatePreferences = (preferenceUpdates) => {
    const validationErrors = [];

    if (preferenceUpdates.categories) {
        preferenceUpdates.categories.forEach((category) => {
            if (category.static !== false) {
                if (!category.startTime) {
                    validationErrors.push('Category is missing a start time.');
                } else if (!category.endTime) {
                    validationErrors.push('Category is missing an end time.');
                } else if (!isTimeBefore(category.startTime, category.endTime)) {
                    validationErrors.push('Category start time is after end time.');
                }
            }
        });
    }

    return validationErrors;
};

export default function Preferences({ ...props }) {
    const { tab, username, preferences, savePreferences, isBetaUser, pushError, setUpperUnsaved } = props;
    // eslint-disable-next-line no-unused-vars
    const [ categories, setCategories ] = useState(preferences.categories || []);
    const [ changePasswordModalOpen, setChangePasswordModalOpen ] = useState(false);
    const preferenceUpdates = useRef({});
    const [ unsaved, setUnsaved ] = useState(false);

    // If unsaved changes, show alert before leaving 
    useEffect(() => {
        window.onbeforeunload = (event) => {
            // Show prompt based on state
            if (unsaved) {
                const e = event || window.event;
                e.preventDefault();
                if (e) {
                    e.returnValue = '';
                }
                return '';
            }
        };
    }, [ unsaved ]);

    const updatePreferences = useCallback((variableName, variableValue) => {
        setUnsaved(true);
        setUpperUnsaved(true);
        preferenceUpdates.current = {
            ...preferenceUpdates.current,
            [variableName]: variableValue,
        };
        /*onPreferencesChanged({
            ...preferences,
            [variableName]: variableValue,
        });*/
    }, [ setUnsaved, preferences, preferenceUpdates ]);

    const updateCategory = useCallback((newCategory, index) => {
        const newCategories = [ ...categories ];
        newCategories[index] = newCategory;
        setCategories(newCategories);
        updatePreferences('categories', newCategories);
    }, [ setCategories, categories, updatePreferences ]);

    const deleteCategory = useCallback((index) => {
        const newCategories = [ ...categories ];
        newCategories.splice(index, 1);
        setCategories(newCategories);
        updatePreferences('categories', newCategories);
    }, [ categories ]);

    return <div style={{display: 'inline-block', justifyContent: 'top'}} data-testid={testIds.preferencesPage}>
        {changePasswordModalOpen && <ChangePasswordModal handleClose={() => setChangePasswordModalOpen(false)} pushError={pushError}/>}
        <UnsavedChangesPopup unsavedChanges={unsaved} onSave={() => {
            const validationErrors = validatePreferences(preferenceUpdates.current);
            if (validationErrors && validationErrors.length > 0) {
                validationErrors.forEach((error) => pushError(error));
            } else {
                savePreferences(preferenceUpdates.current);
                setUnsaved(false);
                setUpperUnsaved(false);
                preferenceUpdates.current = {};
            }
        }}/>
        {tab == 0 && <GeneralSettings
            username={username}
            openPasswordModal={() => setChangePasswordModalOpen(true)}
            completionEffectsEnabled={preferences.completionEffect}
            setCompletionEffectsEnabled={(enabled) => updatePreferences('completionEffect', enabled)}
        />}
        {tab == 1 &&
            <div>
                <h1>Categories</h1>
                <Card style={{width: '100%', backgroundColor:'grey', overflow:'visible'}}>
                    {categories.map((category, index) => 
                        <CategoryComponent
                            key={category.categoryId + index}
                            category={category}
                            updateCategory={(newCategory) => updateCategory(newCategory, index)}
                            preferences={preferences}
                            deleteCategory={() => deleteCategory(index)}
                            isBetaUser={isBetaUser}
                        />
                    )}
                    <Card style={{ minHeight: 150, padding: 5, margin: 5, textAlign: 'center', display: 'inline-block'}}>
                        <CardActionArea
                            onClick={() => {
                                const newCategories = [ ...categories ];
                                newCategories.push({
                                    categoryId: uuidv4(),
                                });
                                setCategories(newCategories);
                                updatePreferences('categories', newCategories);
                            }
                            }
                            style={{ minWidth: 250, minHeight: 150 }}
                        >
                            <AddIcon style={{ position: 'relative', top: '50%' }} fontSize="large" />
                        </CardActionArea>
                    </Card>
                </Card>
            </div>}
        {tab == 2 &&
            <SchedulingPreferences
                preferences={preferences}
                updatePreferences={updatePreferences}
                isBetaUser={isBetaUser}
            />}
        {tab == 3 &&
            <div style={{width: '75%'}}>
                {isBetaUser && <div style={{display: 'inline-block'}}>
                    <h1>Meal Scheduling</h1>
                    <h3>Meal Time Windows</h3>
                    Set the time window for when the scheduling algorithm should schedule each type of meal
                    <div style={{ display: 'flex' }}>
                        <SettingsCard>
                            <TimeWindowInput
                                setStartTime={(startTime) => {
                                    updatePreferences('breakfastTimeWindow', {
                                        ...preferences.breakfastTimeWindow,
                                        startTime
                                    });
                                }}
                                setEndTime={(endTime) => {
                                    updatePreferences('breakfastTimeWindow', {
                                        ...preferences.breakfastTimeWindow,
                                        endTime
                                    });
                                }}
                                startTime={preferences.breakfastTimeWindow && preferences.breakfastTimeWindow.startTime || ''} 
                                endTime={preferences.breakfastTimeWindow && preferences.breakfastTimeWindow.endTime || ''}
                                title="Breakfast Time Window"
                            />
                        </SettingsCard>
                        <SettingsCard>
                            <TimeWindowInput
                                setStartTime={(startTime) => {
                                    updatePreferences('lunchTimeWindow', {
                                        ...preferences.lunchTimeWindow,
                                        startTime
                                    });
                                }}
                                setEndTime={(endTime) => {
                                    updatePreferences('lunchTimeWindow', {
                                        ...preferences.lunchTimeWindow,
                                        endTime
                                    });
                                }}
                                startTime={preferences.lunchTimeWindow && preferences.lunchTimeWindow.startTime || ''} 
                                endTime={preferences.lunchTimeWindow && preferences.lunchTimeWindow.endTime || ''}
                                title="Lunch Time Window"
                            />
                        </SettingsCard>
                        <SettingsCard>
                            <TimeWindowInput
                                setStartTime={(startTime) => {
                                    updatePreferences('dinnerTimeWindow', {
                                        ...preferences.dinnerTimeWindow,
                                        startTime
                                    });
                                }}
                                setEndTime={(endTime) => {
                                    updatePreferences('dinnerTimeWindow', {
                                        ...preferences.dinnerTimeWindow,
                                        endTime
                                    });
                                }}
                                startTime={preferences.dinnerTimeWindow && preferences.dinnerTimeWindow.startTime || ''} 
                                endTime={preferences.dinnerTimeWindow && preferences.dinnerTimeWindow.endTime || ''}
                                title="Dinner Time Window"
                            />
                        </SettingsCard>
                    </div>
                    <h3>Groceries</h3>
                    Set the time a normal grocery trip takes for you, this will be used to automatically schedule a grocery trip if you need ingredients
                    <div style={{ display: 'flex' }}>
                        <SettingsCard>
                            <InputTextField
                                name="groceryTime"
                                label="Grocery trip time in minutes" 
                                value={preferences.groceryTime}
                                onChangeEvent={(value) => {
                                    updatePreferences('groceryTime', parseInt(value));
                                }}
                                isNumber={true}
                            />
                        </SettingsCard>
                    </div>
                </div>}
                {isBetaUser && <Grid item xs="auto">
                    <h1>Nutrition</h1>
                    <h3>Nutrition Targets</h3>
                    Set the targets you want to hit for various nutritional values. This can be seen in the meal diary if you are hitting these targets.
                    <div style={{ display: 'flex' }}>
                        <SettingsCard>
                            <InputTextField
                                name="caloriesLimit"
                                label="Target Calories per day" 
                                value={preferences.nutritionLimits ? preferences.nutritionLimits.calories : 0}
                                onChangeEvent={(value) => {
                                    updatePreferences('nutritionLimits', {
                                        ...preferences.nutritionLimits,
                                        calories: parseInt(value)
                                    });
                                }}
                                isNumber={true}
                            />
                        </SettingsCard>
                        <SettingsCard>
                            <InputTextField
                                name="fatLimit"
                                label="Target Fat per day (g)" 
                                value={preferences.nutritionLimits ? preferences.nutritionLimits.fat : 0}
                                onChangeEvent={(value) => {
                                    updatePreferences('nutritionLimits', {
                                        ...preferences.nutritionLimits,
                                        fat: parseInt(value)
                                    });
                                }}
                                isNumber={true}
                            />
                        </SettingsCard>
                        <SettingsCard>
                            <InputTextField
                                name="proteinLimit"
                                label="Target Protein per day (g)" 
                                value={preferences.nutritionLimits ? preferences.nutritionLimits.protein : 0}
                                onChangeEvent={(value) => {
                                    updatePreferences('nutritionLimits', {
                                        ...preferences.nutritionLimits,
                                        protein: parseInt(value)
                                    });
                                }}
                                isNumber={true}
                            />
                        </SettingsCard>
                        <SettingsCard>
                            <InputTextField
                                name="sugarLimit"
                                label="Target Sugar per day (g)" 
                                value={preferences.nutritionLimits ? preferences.nutritionLimits.sugar : 0}
                                onChangeEvent={(value) => {
                                    updatePreferences('nutritionLimits', {
                                        ...preferences.nutritionLimits,
                                        sugar: parseInt(value)
                                    });
                                }}
                                isNumber={true}
                            />
                        </SettingsCard>
                        <SettingsCard>
                            <InputTextField
                                name="waterLimit"
                                label="Target water per day (cup)" 
                                value={preferences.nutritionLimits ? preferences.nutritionLimits.water : 0}
                                onChangeEvent={(value) => {
                                    updatePreferences('nutritionLimits', {
                                        ...preferences.nutritionLimits,
                                        water: parseInt(value)
                                    });
                                }}
                                isNumber={true}
                            />
                        </SettingsCard>
                    </div>
                </Grid>}
            </div>}
    </div>;
}