import React, { useCallback, useEffect, useRef, useState } from 'react';
import { optimizeTasks } from '../scheduling/CalendarAlgorithm';
import { Modal, Button, CircularProgress } from '@mui/material';
import PastTasks from './PastTasks';
import { DataStore } from 'aws-amplify/datastore';
import ScheduledInformationComponent from './ScheduledInformationComponent';
import { ScheduledInformation, ScheduledMeal } from 'models';
import { archiveTasks, getPastTasks, sanitizeASAPTasks } from 'helpers/TaskHelpers';
import { getRecipe } from 'helpers/MealHelpers';
import { MealType } from 'models';
import { updateScheduledInformation } from 'helpers/DataStoreHelper';
import { getTaskFromScheduledMeal, removeScheduledTask } from 'calendar/scheduling/SchedulingCommonFunctions';
import { modalStyle } from 'constants/StylingConstants';
import { handleDailyCompleted } from 'tasks/taskCommon/ConfigCommon';
import { replaceById } from 'helpers/ListHelpers';

export default function RescheduleModal({...props}) {
    const {
        updateOptimizedTask,
        tasks,
        today,
        startTime,
        endTime,
        timeOfDayMinutes,
        close,
        preferences,
        scheduledInformation,
        recipesResponse,
        fridgeResponse,
        goals,
        username,
        isBetaUser,
        screenWidth,
        pushError,
        refetchLoading,
        categories
    } = props;

    const [ localTasks, setLocalTasks ] = useState(tasks);
    const [ pastTasks, setPastTasks ] = useState(tasks);
    // Selected id by user
    const [ selectedBreakfastRecipeId, setSelectedBreakfastRecipeId ] = useState(null);
    // Meal in scheduled information from previous schedules
    const [ scheduledBreakfast, setScheduledBreakfast ] = useState(null);
    const [ selectedLunchRecipeId, setSelectedLunchRecipeId ] = useState(null);
    const [ scheduledLunch, setScheduledLunch ] = useState(null);
    const [ selectedDinnerRecipeId, setSelectedDinnerRecipeId ] = useState(null);
    const [ scheduledDinner, setScheduledDinner ] = useState(null);
    const [ scheduleInProgress, setScheduleInProgress ] = useState(false);
    const [ currentTask, setCurrentTask ] = useState('Setting everything up...');
    const taskUpdates = useRef([]);
    
    useEffect(() => {
        const newPastTasks = getPastTasks(tasks, today, timeOfDayMinutes);
        setPastTasks(newPastTasks);
    }, [ tasks, today, timeOfDayMinutes ]);

    useEffect(() => {
        if (scheduledInformation && scheduledInformation.scheduledMeals) {
            scheduledInformation.scheduledMeals.forEach((scheduledMeal) => {
                switch(scheduledMeal.recipeType) {
                case MealType.BREAKFAST:
                    setSelectedBreakfastRecipeId(scheduledMeal.recipeId);
                    setScheduledBreakfast(scheduledMeal);
                    break;
                case MealType.LUNCH:
                    setSelectedLunchRecipeId(scheduledMeal.recipeId);
                    setScheduledLunch(scheduledMeal);
                    break;
                case MealType.DINNER:
                    setSelectedDinnerRecipeId(scheduledMeal.recipeId);
                    setScheduledDinner(scheduledMeal);
                    break;
                }
            });
        }
    }, [ scheduledInformation ]);

    const completeLocalTask = useCallback((id, complete) => {
        // Get existing task
        const taskToComplete = localTasks.filter(t => t.id === id)[0];
        // Push and update to task updates
        if (taskToComplete.daily.enabled) {
            changeTask(id, { daily: handleDailyCompleted(taskToComplete, today, complete) });
        } else {
            changeTask(id, { completed: complete ? taskToComplete.scheduledDate : null });
        }

        var completedTask;
        if (taskToComplete.daily.enabled) {
            const newTask = { ...taskToComplete, daily: handleDailyCompleted(taskToComplete, today, complete) };
            completedTask = newTask;
        } else {
            completedTask = { ...taskToComplete, completed: complete ? taskToComplete.scheduledDate : null};
        }

        // We need to update past tasks so that the modal updates when you complete
        // TODO Can probably make this better by managing the completed state in PastTasks
        setPastTasks(replaceById(id, pastTasks, completedTask));

        // Local tasks is what gets passed to scheduler, it needs to know if a task what completed
        setLocalTasks(replaceById(id, localTasks, completedTask));
    }, [ localTasks, pastTasks, setLocalTasks, setPastTasks ]);

    const setRecipeId = useCallback((id, type) => {
        switch(type) {
        case MealType.BREAKFAST:
            setSelectedBreakfastRecipeId(id);
            break;
        case MealType.LUNCH:
            setSelectedLunchRecipeId(id);
            break;
        case MealType.DINNER:
            setSelectedDinnerRecipeId(id);
            break;
        }
    });

    const changeTask = useCallback((id, newSettings) => {
        const newUpdates = { ...taskUpdates.current };
        newUpdates[id] = {
            ...newUpdates[id],
            ...newSettings
        };
        taskUpdates.current = newUpdates;
    }, [ taskUpdates ]);

    const runScheduler = useCallback(async () => {
        setScheduleInProgress(true);

        // Archive past tasks
        setCurrentTask('Archiving completed tasks...');
        console.log('Archiving completed tasks...');
        archiveTasks(localTasks, preferences, changeTask);

        // Remove scheduled time for tasks that should be rescheduled
        setCurrentTask('Sanitizing already scheduled ASAP tasks...');
        console.log('Sanitizing already scheduled ASAP tasks...');
        let newTasks = sanitizeASAPTasks(localTasks, today, changeTask);
        
        // TODO make recipes generic
        // Get recipe objects for selected recipe ids
        setCurrentTask('Fetching selected recipes...');
        console.log('Fetching selected recipes...');
        const breakfastRecipe = getRecipe(selectedBreakfastRecipeId, recipesResponse);
        const lunchRecipe = getRecipe(selectedLunchRecipeId, recipesResponse);
        const dinnerRecipe = getRecipe(selectedDinnerRecipeId, recipesResponse);

        // Fetch/Create recipe tasks
        setCurrentTask('Fetching current scheduled meal tasks...');
        console.log('Fetching selected recipes...');
        const currentBreakfastTask = getTaskFromScheduledMeal(scheduledBreakfast, newTasks);
        const currentLunchTask = getTaskFromScheduledMeal(scheduledLunch, newTasks);
        const currentDinnerTask = getTaskFromScheduledMeal(scheduledDinner, newTasks);

        // For now, just delete all scheduled tasks related to meals, they will be recreated
        // Eventually it might be nice to keep the current scheduled task so it does change the time
        if (currentBreakfastTask) {
            newTasks = removeScheduledTask(currentBreakfastTask.id, newTasks, pushError);
        }
        if (currentLunchTask) {
            newTasks = removeScheduledTask(currentLunchTask.id, newTasks, pushError);
        }
        if (currentDinnerTask) {
            newTasks = removeScheduledTask(currentDinnerTask.id, newTasks, pushError);
        }

        // constructing meals array
        const meals = {
            [MealType.BREAKFAST]: {
                currentTask: currentBreakfastTask,
                recipe: breakfastRecipe
            },
            [MealType.LUNCH]: {
                currentTask: currentLunchTask,
                recipe: lunchRecipe
            },
            [MealType.DINNER]: {
                currentTask: currentDinnerTask,
                recipe: dinnerRecipe
            }
        };

        // Call the algorithm
        setCurrentTask('Optimizing tasks...');
        console.log('Optimizing tasks...');
        const optimizeResponse = optimizeTasks(newTasks,
            today,
            startTime,
            endTime,
            timeOfDayMinutes,
            username,
            meals,
            preferences,
            fridgeResponse,
            goals,
            pushError,
            categories);
        const newOptimizedTasks = optimizeResponse.todaysTasks;

        // Push task updates for all new optimized tasks
        setCurrentTask('Saving scheduled tasks...');
        console.log('Saving scheduled tasks...');
        newOptimizedTasks.forEach((task) => {
            changeTask(task.id, { ...task });
        });

        const scheduledMeals = [
            new ScheduledMeal({
                recipeId: selectedBreakfastRecipeId,
                recipeType: MealType.BREAKFAST,
                associatedTaskId: optimizeResponse.breakfastAssociatedTaskId
            }),
            new ScheduledMeal({
                recipeId: selectedLunchRecipeId,
                recipeType: MealType.LUNCH,
                associatedTaskId: optimizeResponse.lunchAssociatedTaskId
            }),
            new ScheduledMeal({
                recipeId: selectedDinnerRecipeId,
                recipeType: MealType.DINNER,
                associatedTaskId: optimizeResponse.dinnerAssociatedTaskId
            })
        ];

        // Generate scheduled information on first schedule
        if (!scheduledInformation || scheduledInformation.length === 0) {
            const newScheduledInformation = {
                breakfastRecipe: selectedBreakfastRecipeId,
                lunchRecipe: selectedLunchRecipeId,
                dinnerRecipe: selectedDinnerRecipeId,
                owner: username,
                day: today,
                scheduledMeals
            };
            DataStore.save(new ScheduledInformation(newScheduledInformation))
                .catch((error) => pushError(error));
        } else {
            updateScheduledInformation(today, { scheduledMeals }, username, today, pushError);
        }

        updateOptimizedTask({ ...taskUpdates.current }, [ ...newOptimizedTasks ]);
        close();
    }, [ localTasks, today, startTime, endTime, timeOfDayMinutes, username, preferences, taskUpdates, scheduledInformation, fridgeResponse, selectedBreakfastRecipeId, selectedLunchRecipeId, selectedDinnerRecipeId, recipesResponse, changeTask, pushError, scheduledBreakfast, scheduledLunch, scheduledDinner, categories ]);

    var body;
    if (!scheduleInProgress && !refetchLoading) {
        body = (
            <div style={{
                ...modalStyle,
                position: 'absolute',
                width: '50%',
                minWidth: '400px',
                backgroundColor: '#fff',
                border: '2px solid #000',
                padding: '16px 32px 24px'
            }}>
                <h2 id="simple-modal-title">Schedule Day</h2>
                <div>
                    <p id="simple-modal-description">
                    Are any of these tasks completed?<br/>
                        <b>Note:</b> Any non completed tasks will be rescheduled for later.
                    </p>
                    {pastTasks.length > 0 &&
                        <PastTasks
                            pastTasks={pastTasks}
                            completeLocalTask={completeLocalTask}
                            today={today}
                            screenWidth={screenWidth}
                        />
                    }
                    { recipesResponse && recipesResponse.length > 0 && isBetaUser &&
                        <ScheduledInformationComponent
                            recipesResponse={recipesResponse}
                            fridgeResponse={fridgeResponse}
                            setRecipeId={setRecipeId}
                            preferences={preferences}
                            selectedBreakfastRecipeId={selectedBreakfastRecipeId}
                            selectedLunchRecipeId={selectedLunchRecipeId}
                            selectedDinnerRecipeId={selectedDinnerRecipeId}
                        />
                    }
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={close}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            runScheduler();
                        }}
                    >
                        Schedule Day
                    </Button>
                </div>
            </div>
        );
    } else {
        body = <div style={{
            ...modalStyle,
            position: 'absolute',
            width: '50%',
            minWidth: '400px',
            backgroundColor: '#fff',
            border: '2px solid #000',
            padding: '16px 32px 24px',
            overflow: 'hidden'}}
        >
            <h2 id="simple-modal-title">Scheduling</h2>
            <CircularProgress/>
            <p id="simple-modal-description">{currentTask}</p>
        </div>;
    }

    return (
        <Modal
            open={true}
            onClose={close}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            style={{maxHeight: '90%', overflow: 'auto'}} //TODO this looks odd
        >
            {body}
        </Modal>
    );
}