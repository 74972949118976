import { DataStore } from 'aws-amplify/datastore';
import { Issue } from 'models';

export function upvoteIssueWithIndex(newIssues: Issue[], index: number, username: string): Issue[] {
    newIssues[index] = upvoteIssue(newIssues[index], username);
    return newIssues;
}

export function upvoteIssue(issue: Issue, username: string) : Issue {
    if (issue.upvotes && issue.upvotes.includes(username)) {
        const newUpvotes = issue.upvotes.filter((upvoter) => username !== upvoter);
        return {
            ...issue,
            upvotes: newUpvotes
        };
    } else {
        return {
            ...issue,
            upvotes: [ ...issue.upvotes, username ]
        };
    }
}

export async function deleteIssue(id: string, pushError = (error: string) => console.error(error)) {
    DataStore.delete(Issue, post => post.id.eq(id))
        .then(() => {
            // TODO this is to ensure that datastore syncs before refresh, a query may be better or just updating local value
            setTimeout(function(){
                window.location.reload();
            }, 1000);
        })
        .catch((error: string) => pushError(error));
}

export function sortByDateCompleted(a: Issue, b: Issue): number  {
    const dateA = a.dateCompleted ? new Date(a.dateCompleted) : new Date('0001-01-01T00:00:00Z');
    const dateB = b.dateCompleted ? new Date(b.dateCompleted) : new Date('0001-01-01T00:00:00Z');
    return dateB.getTime() - dateA.getTime();
}

export function sortByUpvotes(a: Issue, b: Issue): number {
    return b.upvotes.length - a.upvotes.length;
}