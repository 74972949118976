import React from 'react';
import 'App.css';
import { Card, Modal, Typography } from '@mui/material';
import { modalStyle } from 'constants/StylingConstants';

type Props = {
    validateAccount: () => void
}

export default function ValidateAccountModal({ validateAccount }: Props) {
    return <Modal
        open={true}
        onClose={validateAccount}
    >
        <Card style={modalStyle}>
            <h2>Account Successfully Created!</h2>
            <Typography id="modal-modal-description" >
                    Please verify your account through your email before you log in.
            </Typography>
        </Card>
    </Modal>;
}