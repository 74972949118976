// @ts-check
import { initSchema } from 'aws-amplify/datastore';
import { schema } from './schema';

const TimeframeType = {
    'MINUTE': 'MINUTE',
    'HOUR': 'HOUR'
};

const DayOfWeek = {
    'MONDAY': 'MONDAY',
    'TUESDAY': 'TUESDAY',
    'WEDNESDAY': 'WEDNESDAY',
    'THURSDAY': 'THURSDAY',
    'FRIDAY': 'FRIDAY',
    'SATURDAY': 'SATURDAY',
    'SUNDAY': 'SUNDAY'
};

const SortType = {
    'PRIORITY': 'PRIORITY',
    'DURATION': 'DURATION',
    'DUEDATE': 'DUEDATE',
    'GOALS': 'GOALS',
    'AMOUNTSCHEDULED': 'AMOUNTSCHEDULED'
};

const GoalType = {
    'DAY': 'DAY',
    'WEEK': 'WEEK',
    'MONTH': 'MONTH',
    'YEAR': 'YEAR'
};

const MealType = {
    'BREAKFAST': 'BREAKFAST',
    'LUNCH': 'LUNCH',
    'DINNER': 'DINNER'
};

const { Note, ShopItem, Goal, MealDiaryEntry, ReleaseNotes, ScheduledInformation, FoodInformation, User, Fridge, Recipe, Issue, Preference, Task, AmountPerTimeframe, SortEntry, ScheduledMeal, DateTimeWindow, NutritionLimits, TimeWindow, Ingredient, Comment, Category, Daily } = initSchema(schema);

export {
    Note,
    ShopItem,
    Goal,
    MealDiaryEntry,
    ReleaseNotes,
    ScheduledInformation,
    FoodInformation,
    User,
    Fridge,
    Recipe,
    Issue,
    Preference,
    Task,
    TimeframeType,
    DayOfWeek,
    SortType,
    GoalType,
    MealType,
    AmountPerTimeframe,
    SortEntry,
    ScheduledMeal,
    DateTimeWindow,
    NutritionLimits,
    TimeWindow,
    Ingredient,
    Comment,
    Category,
    Daily
};