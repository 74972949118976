import React from 'react';
import { Drawer, List, Toolbar, ListItemButton } from '@mui/material';
import { isSmallDevice } from 'helpers/StyleHelper';

// https://v4.mui.com/components/drawers/#PersistentDrawerLeft.js
const drawerWidth = isSmallDevice(document.documentElement.clientWidth) ? 115 : 175;

type Props = {
    tabNames: string[],
    tab: number,
    setTab: (tabNum: number) => void
};

export default function SettingsDrawer({ tabNames, tab, setTab }: Props) {

    return <>
        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                overflow: 'hidden',
                zIndex: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    backgroundColor: '#282c34',
                    color: '#E6E6E6',
                    overflow: 'hidden',
                    maxHeight: '100%',
                    fontSize: isSmallDevice(document.documentElement.clientWidth) ? '15px' : '25px'
                },
            }}
            anchor={'left'}
            open={true}
            variant={'persistent'}
        >
            <div style={{ overflow: 'auto' }}>
                <Toolbar/>
                <List>
                    {tabNames.map((text, index) => (
                        <ListItemButton key={text + 'drawer'} selected={tab === index} onClick={() => setTab(index)}>
                            {text}
                        </ListItemButton>
                    ))}
                </List>
            </div>
            <div style={{ position: 'fixed', left: 0, bottom: 0, color: 'white', fontSize: '15px' }}>
                V0.14
            </div>
        </Drawer>
    </>;
}