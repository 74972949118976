import { Box } from '@mui/material';
import MouseOverPopover from 'common/MouseOverPopover';
import { testIds } from 'constants/TestConstants';
import { calculateMinutesFromTime } from 'helpers/TimeHelper';
import { Category } from 'models';
import { CategoryEvent } from 'models/CommonTypes';
import React from 'react';

type Props = {
    categoryInstance: Category,
    left: number,
    scheduledTime: number, // in minutes
    onCreate: (event: CategoryEvent) => void
} 

export default function CategoryCalendarBox({ categoryInstance, left, scheduledTime, onCreate }: Props) {
    const scheduledEndTime = calculateMinutesFromTime(categoryInstance.endTime || '00:00');
    const duration = scheduledEndTime - scheduledTime;
    const writingMode = duration < 45 ? 'horizontal-tb' : 'vertical-rl';

    return <Box
        key={categoryInstance.name}
        bgcolor={categoryInstance.color ? categoryInstance.color : 'white'}
        borderRadius={1}
        border={1}
        borderColor="text.primary"
        position="absolute"
        width="50px"
        height={duration}
        left={left}
        top={scheduledTime}
        data-testid={testIds.calendarBox}
        onClick={() => onCreate({
            start: scheduledTime,
            end: scheduledEndTime,
            selectedCategoryId: categoryInstance.categoryId,
            dynamic: categoryInstance.static === false
        })}
    >
        <MouseOverPopover
            text={<div style={{ writingMode: writingMode }}>{categoryInstance.name}</div>}
            popoverText={categoryInstance.name ? categoryInstance.name : 'Unknown'}
            height={duration}
        />
    </Box>;
}