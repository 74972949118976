import { Autocomplete, IconButton, ListItem, MenuItem, Select, TextField } from '@mui/material';
import { InputTextField } from 'common/InputTextField';
import React, { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { IngredientUnit } from 'constants/MealConstants';
import { Ingredient } from 'models';
import { testIds } from 'constants/TestConstants';

type Props = {
    ingredient: Ingredient,
    updateIngredient: (name: string, value: string|number|null) => void,
    deleteFunc: () => void,
    autoCompleteOptions?: string[]
}

export default function IngredientListItem({ ingredient, updateIngredient, deleteFunc, autoCompleteOptions = [] }: Props) {
    const [ ingredientName, setIngredientName ] = useState(ingredient.name);
    const [ ingredientAmount, setIngredientAmount ] = useState(ingredient.amount);
    // Grocery task saves as the key, but other stuff saves as the value cus im smart
    const [ ingredientUnit, setIngredientUnit ] = useState(
        ingredient.unit ? IngredientUnit[ingredient.unit] || ingredient.unit : IngredientUnit.UNIT
    );

    return (
        <ListItem style={{margin:'0px'}}>
            {autoCompleteOptions.length > 0 ?
                <Autocomplete
                    disablePortal
                    options={autoCompleteOptions}
                    sx={{ width: 300, paddingRight: 2 }}
                    onChange={(event, newInput) => {
                        console.log(event);
                        setIngredientName(newInput);
                        updateIngredient('name', newInput);
                    }}
                    value={ingredientName}
                    data-testid={testIds.autocomplete}
                    renderInput={(params) => <TextField
                        {...params}
                        label="Name" />
                    }
                />
                :<InputTextField
                    name="ingredientName"
                    label="Ingredient Name"
                    style={{width:'65%'}}
                    value={ingredientName}
                    error={!ingredientName}
                    onChangeEvent={(value) => {
                        setIngredientName(value);
                        updateIngredient('name', value);
                    }}
                />}
            <InputTextField
                name="ingredientAmount"
                label="Amount"
                style={{width:'25%'}}
                value={ingredientAmount}
                error={!ingredientAmount}
                onChangeEvent={(value) => {
                    setIngredientAmount(parseFloat(value));
                    updateIngredient('amount', parseFloat(value));
                }}
                isNumber={true}
            />
            <Select
                variant="standard"
                style={{width:'15%'}}
                value={ingredientUnit}
                label="Unit"
                onChange={(event) => {
                    setIngredientUnit(event.target.value);
                    updateIngredient('unit', event.target.value);
                }}>
                {Object.values(IngredientUnit).map((type) => <MenuItem key={type} value={type}>{type}</MenuItem>)}
            </Select>
            <IconButton
                style={{width: '50px', display: 'inline-block', float: 'right', textAlign:'right', color:'red'}}
                onClick={deleteFunc}
                size="large">
                <DeleteIcon/>
            </IconButton>
        </ListItem>
    );
}