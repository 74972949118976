import { DataStore } from 'aws-amplify/datastore';
import { FoodInformation } from 'models';

export async function validateNutritionFact(id, nutritionFact) {
    return DataStore.query(FoodInformation)
        .then((response) => {
            const validationErrors = [];

            console.log(response);
            console.log(id);
            if (response.find((responseFact) => responseFact.name != null
                    && responseFact.id !== id
                    && responseFact.name.toLowerCase() === nutritionFact.name.toLowerCase())) {
                validationErrors.push({
                    fieldName: 'name',
                    message: `You already have a nutrition fact configured with the name "${nutritionFact.name}"`
                });
            }

            return validationErrors;
        })
        .catch((response) => {
            throw response;
        });
}