import { Button, Card, MenuItem, Modal, Select } from '@mui/material';
import { modalStyle } from 'constants/StylingConstants';
import { testIds } from 'constants/TestConstants';
import { updatePreference } from 'helpers/DataStoreHelper';
import { groupByField } from 'helpers/ListHelpers';
import { calculateMinutesFromTime, formatMinutes } from 'helpers/TimeHelper';
import { Category, Preference } from 'models';
import { CategoryEvent } from 'models/CommonTypes';
import { isTimeWindowEqual } from 'preferences/Category/CategoryCommon';
import TimeWindowInput from 'preferences/TimeWindowInput';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

type Props = {
    handleClose: () => void,
    event: CategoryEvent,
    today: string,
    preferences: Preference,
    localCategories: Category[],
    updateLocalCategories: (newCategories: (Category|null)[]) => void,
    pushError: (error: string) => void
}

type CategoryById = {
    [index: string]: Category
}

export default function ScheduleCategoryModal({
    handleClose,
    event,
    today,
    preferences,
    localCategories,
    updateLocalCategories,
    pushError
}: Props) {
    const [ selectedCategory, setSelectedCategory ] = useState<string|null|undefined>(event.selectedCategoryId);
    // Event contains the increment of 15 minutes in the day
    // TODO this will break if we change the increment
    const [ startTime, setStartTime ] = useState(formatMinutes(event.start));
    const [ endTime, setEndTime ] = useState(formatMinutes(event.end));

    const [ timeError, setTimeError ] = useState(event.start > event.end);

    // TODO do we need both of these maps?
    const dynamicCategoriesById: CategoryById = useMemo(() => {
        const dynamicCategories = localCategories
            ? localCategories.filter(cat => cat !== null && cat.static === false)
            : [];
        return groupByField(dynamicCategories, 'categoryId');
    }, [ localCategories ]);

    const categoriesById: CategoryById = useMemo(() => {
        return groupByField(localCategories, 'categoryId');
    }, [ localCategories ]);

    useEffect(() => {
        setTimeError(calculateMinutesFromTime(startTime) > calculateMinutesFromTime(endTime));
    }, [startTime, endTime]);

    const deleteTimeWindow = useCallback(() => {
        if (selectedCategory) {
            const newCategories = localCategories ? [ ...localCategories ] : [];
            const categoryIndex = newCategories.findIndex(cat => cat && cat.categoryId === selectedCategory);

            // If there are no scheduled time windows you shouldnt be able to delete
            if (!dynamicCategoriesById[selectedCategory].scheduledTimeWindows) {
                pushError('Category has no existing time windows');
                return;
            }
            const newTimeWindows = [ ...dynamicCategoriesById[selectedCategory].scheduledTimeWindows! ];

            // Filter first time window that matches original times
            const timeWindowToDeleteIndex = newTimeWindows.findIndex((timeWindow) =>
                isTimeWindowEqual(timeWindow, today, event.start, event.end));
            if (timeWindowToDeleteIndex === -1) pushError('Unabled to find category instance');

            // Delete time window and within the category
            newTimeWindows.splice(timeWindowToDeleteIndex, 1);
            newCategories.splice(categoryIndex, 1, {
                ...dynamicCategoriesById[selectedCategory],
                scheduledTimeWindows: newTimeWindows
            });

            updatePreference(preferences.id,
                { categories: newCategories },
                (error) => pushError(error));
            updateLocalCategories(newCategories);
            handleClose();
        } else {
            pushError('No category selected');
        }
    }, [ localCategories, selectedCategory, updatePreference, updateLocalCategories, handleClose ]);

    const addNewTimeWindow = useCallback(() => {
        if (selectedCategory) {
            const newCategories = localCategories ? [ ...localCategories ] : [];
            const categoryIndex = newCategories.findIndex(cat => cat && cat.categoryId === selectedCategory);
            const existingTimeWindows = dynamicCategoriesById[selectedCategory].scheduledTimeWindows
                ? dynamicCategoriesById[selectedCategory].scheduledTimeWindows!
                : [];
            const newTimeWindows = [
                ...existingTimeWindows,
                {
                    startTime,
                    endTime,
                    date: today
                }
            ];
            newCategories.splice(categoryIndex, 1, {
                ...dynamicCategoriesById[selectedCategory],
                scheduledTimeWindows: newTimeWindows
            });
            updatePreference(preferences.id,
                { categories: newCategories },
                (error) => pushError(error));
            updateLocalCategories(newCategories);
            handleClose();
        } else {
            pushError('No category selected');
        }
    }, [ localCategories, selectedCategory, updatePreference, updateLocalCategories, handleClose ]);

    const editTimeWindow = useCallback(() => {
        if (selectedCategory) {
            const newCategories = localCategories ? [ ...localCategories ] : [];
            const categoryIndex = newCategories.findIndex(cat => cat && cat.categoryId === selectedCategory);
            const existingTimeWindows = dynamicCategoriesById[selectedCategory].scheduledTimeWindows
                ? [ ...dynamicCategoriesById[selectedCategory].scheduledTimeWindows! ]
                : [];

            // Replace start and end time
            const timeWindowIndex = existingTimeWindows.findIndex((window) => isTimeWindowEqual(window, today, event.start, event.end));
            if (timeWindowIndex >= 0) {
                existingTimeWindows[timeWindowIndex] = {
                    ...existingTimeWindows[timeWindowIndex],
                    startTime,
                    endTime
                };
            } else {
                console.log('Trying to edit category that doesnt exist.');
            }

            newCategories.splice(categoryIndex, 1, {
                ...dynamicCategoriesById[selectedCategory],
                scheduledTimeWindows: existingTimeWindows
            });
            updatePreference(preferences.id,
                { categories: newCategories },
                (error) => pushError(error));
            updateLocalCategories(newCategories);
            handleClose();
        } else {
            pushError('No category selected');
        }
    }, [ localCategories, selectedCategory, startTime, endTime, updatePreference, updateLocalCategories, handleClose ]);

    const modalContent = useMemo(() => {
        return <div>
            <Button
                color="error"
                disabled={Boolean(!event.selectedCategoryId) || !event.dynamic}
                onClick={deleteTimeWindow}
            >
                Delete Instance
            </Button>
            <h3>
                {event.selectedCategoryId ? 'Edit' : 'Create'}
                {event.dynamic ? ' Dynamic ' : ' Static '}
                Category Instance
            </h3>
            {event.selectedCategoryId
                ? <h2>{categoriesById[event.selectedCategoryId].name}</h2>
                : <Select
                    variant="standard"
                    value={selectedCategory}
                    data-testid={testIds.selector}
                    onChange={(event) => setSelectedCategory(event.target.value)}
                >
                    {Object.keys(dynamicCategoriesById).map(catId =>
                        <MenuItem key={catId} value={catId}>{dynamicCategoriesById[catId].name}</MenuItem>)}
                </Select>
            }
            <TimeWindowInput
                setStartTime={(startTime) => setStartTime(startTime)}
                setEndTime={(endTime) => setEndTime(endTime)}
                startTime={startTime}
                endTime={endTime}
                disabled={!event.dynamic}
                disabledText='Static Category'
                title="Time Window"
                startTimeError={timeError}
                endTimeError={timeError}
            />
            <Button
                variant="contained"
                color="secondary"
                onClick={handleClose}
            >
                Cancel
            </Button>
            {event.selectedCategoryId ?
                <Button
                    variant="contained"
                    color="primary"
                    onClick={editTimeWindow}
                    disabled={timeError}
                >
                    Save
                </Button>
                : <Button
                    variant="contained"
                    color="primary"
                    onClick={addNewTimeWindow}
                    disabled={timeError}
                >
                    Create
                </Button>
            }
        </div>;
    }, [ selectedCategory, startTime, endTime ]);

    return <Modal
        open={true}
        onClose={handleClose}
        data-testid={testIds.modal}
    >
        <Card style={modalStyle}>
            {modalContent}
        </Card>
    </Modal>;
}