import { convertToDateTimes } from 'dailys/DailysCommon';
import { getHours, getMinutes } from 'date-fns';
import { setMinutes } from 'date-fns/esm';
import { DayOfWeek, Task } from 'models';

const _MS_PER_DAY = 1000 * 60 * 60 * 24;

export function calculateMinutesFromDate(startTime: Date) {
    return (getHours(startTime)*60) + getMinutes(startTime);
}

export function calculateMinutesFromTime(startTime?: string|null) {
    if (!startTime) {
        return 0;
    }
    const split = startTime.split(':');
    return (parseInt(split[0])*60) + parseInt(split[1]);
}

export function calculateTimeFromMinutes(minutes: number) {
    const hourMinutes = (minutes%60) < 10 ? '0' + (minutes%60) : (minutes%60);
    return Math.floor(minutes/60) + ':' + hourMinutes;
}

export function getDateFromString(string: string){
    if (typeof string === 'string') {
        const split = string.split(':');
        // 0 || 8 will give you 8 btw
        return setMinutes(new Date().setHours(parseInt(split[0]) || 0), split[1] ? parseInt(split[1]) : 0);
    } else {
        console.error('Trying to convert nonstring to date.');
        console.error(string);
        return new Date();
    }
}

export function getTimeStringFromDate(date: Date){
    const minutes = date.getMinutes();
    const hourMinutes = minutes < 10 ? '0' + minutes : minutes;
    const hours = date.getHours();
    const hourHours = hours < 10 ? '0' + hours : hours;
    return hourHours + ':' + hourMinutes;
}

export function formatMinutes(minutes: number) {
    const hours = Math.floor(minutes/60).toString();
    const paddedhours = hours.length<2 ? '0'+hours : hours;
    const leftoverminutes = (minutes%60).toString();
    const paddedminutes = leftoverminutes.length<2 ? '0'+leftoverminutes : leftoverminutes;
    return paddedhours+':'+paddedminutes;
}

export function getFormattedDate(date: Date) {
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = date.getFullYear();
    return yyyy + '-' + mm + '-' + dd;
}

export function getFormattedTime(date: Date) {
    return date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds();
}

export function getCurrentDate() {
    //https://stackoverflow.com/questions/1531093/how-do-i-get-the-current-date-in-javascript
    const currentDate = new Date();
    return getFormattedDate(currentDate);
}

export function isDateBefore(time1: string, time2: string) {
    return new Date(time1) < new Date(time2); // true if time1 is later
}

export function isTimeBefore(time1: string, time2: string) {
    return getDateFromString(time1) < getDateFromString(time2); // true if time2 is later
}

export function getUserDate() {
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    return params.get('date') || getCurrentDate();
}

export function getWeekDay(date: Date) {
    const weekday = new Array(7);
    weekday[0] = 'Mon';
    weekday[1] = 'Tue';
    weekday[2] = 'Wed';
    weekday[3] = 'Thu';
    weekday[4] = 'Fri';
    weekday[5] = 'Sat';
    weekday[6] = 'Sun';
    return weekday[date.getDay()];
}

// a and b are javascript Date objects
export function dateDiffInDays(a: Date, b: Date) {
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
  
    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

// Get a Date object for every hour in the day
export function getAllHours() {
    const hours = [];
    for (let i=0; i<24; i++) {
        hours.push(setMinutes(new Date().setHours(i), 0));
    }
    return hours;
}

export function isScheduledTaskToday(task: Task, today: string) {
    let isDailyScheduledToday = false;
    if (task.daily && task.daily.enabled && task.daily.dateTimes) {
        const dateTimes = convertToDateTimes(task.daily.dateTimes);
        isDailyScheduledToday = Boolean(dateTimes[today]) && Boolean(dateTimes[today].time);
    }

    return (task.scheduledDate && task.scheduledTime && task.scheduledDate === today) ||
        // Or daily is scheduled today
        isDailyScheduledToday ||
        // Or frequency in days is enabled and last scheduled was x days ago
        (task.frequencyInDays && task.scheduledDate
            && dateDiffInDays(new Date(task.scheduledDate), new Date(today)) >= 0
            && dateDiffInDays(new Date(task.scheduledDate), new Date(today)) % task.frequencyInDays == 0);
}

export function getThisDayOfWeek(today: string, dayOfWeek?: DayOfWeek|string|null) {
    const d = new Date(today);

    // Default to monday
    let dayOfWeekNum = 6;
    switch(dayOfWeek) {
    case DayOfWeek.SUNDAY: dayOfWeekNum = 7; break;
    case DayOfWeek.MONDAY: dayOfWeekNum = 6; break;
    case DayOfWeek.TUESDAY: dayOfWeekNum = 5; break;
    case DayOfWeek.WEDNESDAY: dayOfWeekNum = 4; break;
    case DayOfWeek.THURSDAY: dayOfWeekNum = 3; break;
    case DayOfWeek.FRIDAY: dayOfWeekNum = 2; break;
    case DayOfWeek.SATURDAY: dayOfWeekNum = 1; break;
    }
    
    d.setDate(d.getDate() - (d.getDay() + dayOfWeekNum) % 7);
    return getFormattedDate(d);
}

export function getFirstDayOfThisMonth(today: string) {
    const date = new Date(today);
    const monthDate = new Date(date.getFullYear(), date.getMonth(), 1);
    return getFormattedDate(monthDate);
}

export function getFirstDayOfThisYear(today: string) {
    const date = new Date(today);
    const yearDate = new Date(date.getFullYear(), 1, 1);
    return getFormattedDate(yearDate);
}

export function getLatestDate(dateArray: string[]) {
    return Date.parse(dateArray.reduce(function (pre, cur) {
        return Date.parse(pre) < Date.parse(cur) ? cur : pre;
    }));
}

export function convert24HourTo12Hour(militaryTime: string) {
    const splitString = militaryTime.split(':');
    let hours = parseInt(splitString[0]);
    const minutes = splitString[1];
    let ampm;

    if (hours > 12) {
        hours -= 12;
        ampm = 'PM';
    } else {
        ampm = 'AM';
    }
 
    return `${hours}:${minutes} ${ampm}`;
}