export enum issueTypes {
    BUG = 'bug',
    FEATURE = 'feature',
}

export const issueTags = {
    BETA: 'beta',
    ALPHA: 'alpha',
    BREAKING: 'breaking',

    // Versions
    V0POINT1: 'v0.1',
    V0POINT2: 'v0.2',
    V0POINT3: 'v0.3',
    V0POINT4: 'v0.4',
    V0POINT5: 'v0.5',
    V0POINT6: 'v0.6',
    V0POINT7: 'v0.7',
    V0POINT8: 'v0.8',
    V0POINT9: 'v0.9',
    V0POINT10: 'v0.10',
    V0POINT11: 'v0.11',
    V0POINT12: 'v0.12',
    V0POINT13: 'v0.13',
    V0POINT14: 'v0.14',
    V0POINT15: 'v0.15',
    V0POINT16: 'v0.16',
};