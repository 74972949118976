export function processCreateAccountError(error: string): string {
    if (error.toLowerCase().includes('user already exists')) {
        return 'Username already exists.';
    } else if (error.toLowerCase().includes('invalid email address format')) {
        return 'Invalid email address.';
    } else if (error.toLowerCase().includes('password did not conform with policy')) {
        if (error.includes(':')) {
            return error.split(':')[1];
        } else {
            return 'Invalid password.';
        }
    } else {
        // TODO some way to track unknown errors
        return 'An unknown error ocurred while creating account: ' + error;
    }
}

export function processLoginError(error: string): string {
    if (error.toLowerCase().includes('incorrect username or password')) {
        return 'Incorrect username or password.';
    } else {
        return 'An unknown error ocurred while logging in. Please try again in a few minutes. ' + error;
    }
}