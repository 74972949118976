import React from 'react';
import { Amplify } from 'aws-amplify';
import { DataStore, AuthModeStrategyType, syncExpression, Predicates } from 'aws-amplify/datastore';
import awsconfig from './amplifyconfiguration.json';
import App from './App';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import InfoPage from 'InfoPage';
import Issues from 'issues/Issues';
import IndividualIssue from 'issues/IndividualIssue';
import ModerateIssues from 'issues/ModerateIssues';
import { useEffect } from 'react';
import { useState } from 'react';
import { CircularProgress, ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material';
import { Hub } from 'aws-amplify/utils';
import CompletedIssues from 'issues/CompletedIssues';
import { Fridge, Goal, Note, Recipe, ShopItem, Task } from 'models';
import { Preference } from 'models';
import Help from 'information/Help';
import { FoodInformation } from 'models';
import { ScheduledInformation } from 'models';
import FourOhFour from 'information/FourOhFour';
import Profile from 'accountManagement/profile/Profile';
import { Issue } from 'models';
import { MealDiaryEntry } from 'models';
import { User } from 'models';
import { useCallback } from 'react';
import Settings from 'preferences/Settings';
import { testIds } from 'constants/TestConstants';
import { getPalette } from 'helpers/ColorHelper';
import { getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';

Amplify.configure({
    ...awsconfig,
    DataStore: {
        authModeStrategyType: AuthModeStrategyType.MULTI_AUTH
    }
});

// TODO make this able to be switched
const mode = 'light';

// This + ThemeProvider added as part of MUI V5 migration
// https://mui.com/material-ui/migration/migration-v4/
const theme = createTheme({
    palette: {
        mode,
        ...getPalette(mode)
    },
    typography: {
        fontFamily: 'Nunito',
        h1: {
            // https://www.youtube.com/watch?v=88XxC0_zs74
            letterSpacing: -10
        },
    },
    shape: {
        borderRadius: 30,
    },
});

function Routing() {
    const [ authEvent, setAuthEvent ] = useState();
    const [ datastoreLoading, setDatastoreLoading ] = useState(false);
    const [ username, setUsername ] = useState();
    const [ isMod, setIsMod ] = useState();
    const [ isBetaUser, setIsBetaUser ] = useState(false);
    const [ isBetaUserLoading, setIsBetaUserLoading ] = useState(false);
    const [ usernameLoading, setUsernameLoading ] = useState(true);
    const [ user, setUser ] = useState();

    // Use to update effect below when auth event is triggered
    Hub.listen('auth', (data) => {
        if (data.payload.data && data.payload.event !== 'forgotPassword') {
            setAuthEvent(data.payload.data.username);
            if(data.payload.event === 'signOut') {
                DataStore.clear();
            }
            console.log('A new auth event has happened: ', data.payload.data.username + ' has ' + data.payload.event);
        }
    });

    Hub.listen('datastore', async hubData => {
        const  { event, data } = hubData.payload;
        if (event === 'networkStatus') {
            console.log(`User has a network connection: ${data.active}`);
        }

        if (event === 'ready') {
            console.log('DataStore has finished loading.');
            setDatastoreLoading(false);
        }
    });

    const startService = useCallback((response, authSession) => {
        setUsername(response.username);
        console.log('Fetching user groups');

        if (response.username && authSession) {
            // TODO fix below
            const groups = authSession.tokens.accessToken.payload['cognito:groups'];
            setIsMod(groups && groups.includes('admins'));
        }

        setDatastoreLoading(true);
        if (response.username) {
            DataStore.configure({
                syncExpressions: [
                    syncExpression(Task, () => {
                        return task => task.and(task => [task.user.eq(response.username),
                            task.archived.ne(true)]);
                    }),
                    syncExpression(Preference, () => {
                        return pref => pref.user.eq(response.username);
                    }),
                    syncExpression(Recipe, () => {
                        return recipe => recipe.owner.eq(response.username);
                    }),
                    syncExpression(Fridge, () => {
                        return fridge => fridge.owner.eq(response.username);
                    }),
                    syncExpression(FoodInformation, () => {
                        return foodInfo => foodInfo.owner.eq(response.username);
                    }),
                    syncExpression(ScheduledInformation, () => {
                        return scheduledInfo => scheduledInfo.owner.eq(response.username);
                    }),
                    syncExpression(Issue, () => {
                        if (isMod) {
                            return Predicates.ALL;
                        }

                        return issue => issue.or(issue => [issue.user.eq(response.username),
                            issue.approved.eq(true)]);
                    }),
                    syncExpression(MealDiaryEntry, () => {
                        return diaryEntry => diaryEntry.owner.eq(response.username);
                    }),
                    syncExpression(User, () => {
                        return user => user.username.eq(response.username);
                    }),
                    syncExpression(Goal, () => {
                        return goal => goal.owner.eq(response.username);
                    }),
                    syncExpression(ShopItem, () => {
                        return shopItem => shopItem.owner.eq(response.username);
                    }),
                    syncExpression(Note, () => {
                        return note => note.owner.eq(response.username);
                    })
                ]
            });
        } else {
            DataStore.configure({
                authModeStrategyType: AuthModeStrategyType.MULTI_AUTH,
                syncExpressions: [
                    syncExpression(Issue, () => {
                        return issue => issue.approved.eq(true);
                    }),
                ]
            });
        }
        console.log('About to call datastore start');
        DataStore.start();
        setUsernameLoading(false);
    }, [ setUsername, setIsMod, setDatastoreLoading, setUsernameLoading ]);

    useEffect(() => {
        setIsBetaUserLoading(true);
        getCurrentUser()
            .then(response => {
                console.log('Fetching username');
                DataStore.clear().then(() => {
                    fetchAuthSession().then((authSession) => startService(response, authSession));
                }).catch((error) => {
                    console.log(error);
                    console.error('Failed to clear DataStore, attempting to start database regardless.');
                    fetchAuthSession().then((authSession) => startService(response, authSession));
                });
            })
            .catch((error) => {
                console.error(error);
                setUsernameLoading(false);
                setDatastoreLoading(false);
                setIsBetaUserLoading(false);
                startService({});
            });
    }, [ authEvent ]);

    useEffect(() => {
        console.log('Fetching User Details');
        DataStore.query(User)
            .then((response) => {
                if (response.length < 1 || !response[0].beta) {
                    setIsBetaUser(false);
                } else {
                    setIsBetaUser(response[0].beta);
                    console.log('Beta should be true');
                    console.log(response[0]);
                }
                setUser(response[0]);
                setIsBetaUserLoading(false);
            })
            .catch((response) => {
                console.log(response);
                setIsBetaUser(false);
                setIsBetaUserLoading(false);
            });
    }, [username, datastoreLoading]);

    if (usernameLoading || datastoreLoading || isBetaUserLoading) {
        return <CircularProgress data-testid={testIds.circularProgress}/>;
    }

    return <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
            <Router>
                <Switch>
                    <Route exact path="/" component={() => (<App username={username} isBetaUser={isBetaUser} triggerAuth={(username) => setUsernameLoading(username)} user={user}/>)} />
                    <Route exact path="/preferences" component={() => (<Settings username={username} isBetaUser={isBetaUser} triggerAuth={(username) => setUsernameLoading(username)} />)} />
                    <Route exact path="/info" component={() => (<InfoPage username={username} triggerAuth={(username) => setUsernameLoading(username)} />)} />
                    <Route exact path="/help" component={() => (<Help />)} />
                    <Route exact path="/404" component={() => (<FourOhFour />)} />
                    <Route exact path="/issues" component={() => (<Issues username={username} isMod={isMod} isBetaUser={isBetaUser} triggerAuth={(username) => setUsernameLoading(username)}/>)} />
                    <Route exact path="/issues/completed" component={() => (<CompletedIssues username={username} isMod={isMod} isBetaUser={isBetaUser} triggerAuth={(username) => setUsernameLoading(username)} />)} />
                    <Route exact path="/issues/moderate" component={() => (<ModerateIssues username={username} isMod={isMod} isBetaUser={isBetaUser} triggerAuth={(username) => setUsernameLoading(username)}  />)} />
                    <Route exact path="/individualIssue" component={() => (<IndividualIssue username={username} isMod={isMod} isBetaUser={isBetaUser} triggerAuth={(username) => setUsernameLoading(username)} />)} />
                    <Route exact path="/profile" component={() => (<Profile username={username} isBetaUser={isBetaUser} triggerAuth={(username) => setUsernameLoading(username)} />)} />
                    <Redirect to="/404" />
                </Switch>
            </Router>
        </ThemeProvider>
    </StyledEngineProvider>;
}

export default Routing;