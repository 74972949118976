import { ListItem, ListItemText } from '@mui/material';
import { format, isAfter, isBefore, setMinutes } from 'date-fns';
import React from 'react';

type Props = {
    time: Date,
    startTime: Date,
    endTime: Date,
    timeOfDay: Date
}

export default function CalendarItem({ time, startTime, endTime, timeOfDay }: Props) {
    const disabled = isBefore(time, startTime) || isBefore(time, setMinutes(timeOfDay, 0)) || isAfter(time, endTime);
    
    return <ListItem disabled={disabled}>
        <ListItemText>{format(time, 'HH:mm')}</ListItemText>
    </ListItem>;
}