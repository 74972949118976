import { isSmallDevice } from 'helpers/StyleHelper';
import { Chip } from '@mui/material';
import React from 'react';
import { testIds } from 'constants/TestConstants';

type Props = {
    tag: string
}

export default function IssueTagChip({ tag }: Props) {
    return <Chip
        label={tag}
        style={{fontSize: isSmallDevice(document.documentElement.clientWidth) ? '11px' : '14px'}}
        data-testid={testIds.issueTagChip}
    />;
}