import React from 'react';
import DraggableListItem from './DraggableListItem';
import {
    DragDropContext,
    DropResult,
    Droppable,
    ResponderProvided
} from 'react-beautiful-dnd';
import AddIcon from '@mui/icons-material/Add';
import { ListItemButton } from '@mui/material';

type DraggableItem = {
    item: string,
    children: string | JSX.Element | JSX.Element[]
}

type Props = {
    items: DraggableItem[],
    onDragEnd: (result: DropResult, provided: ResponderProvided) => void,
    canAdd?: boolean,
    addFunction?: () => void
};

const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? 'lightblue' : 'lightgrey'
});

//https://codesandbox.io/s/draggable-material-ui-oj3wz?file=/src/components/DraggableList.tsx:0-856
// TODO do we really need two components for this
export default function DraggableList({
    items,
    onDragEnd,
    canAdd = false,
    addFunction = () => {},
}: Props) {
    return <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
            {(provided, snapshot) => (
                <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                >
                    {items.map((item, index) => (
                        <DraggableListItem
                            key={item.item + 'listItem'}
                            item={item.item}
                            index={index}
                        >
                            {item.children}
                        </DraggableListItem>
                    ))}
                    {provided.placeholder}
                    {canAdd && <ListItemButton onClick={addFunction}>
                        <AddIcon style={{ position: 'relative', top: '50%' }} fontSize="large" />
                    </ListItemButton>}
                </div>
            )}
        </Droppable>
    </DragDropContext>;
}
