import { AppBar, Box, Button, Card, List, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { issueTypes } from 'constants/IssueConstants';
import { useHistory } from 'react-router-dom';
import { IssueListItem } from './common/IssueListItem';
import { AppBarProfile } from 'common/AppBarProfile';
import { deleteIssue, sortByDateCompleted } from './common/IssueCommonFunctions';
import { DataStore } from 'aws-amplify/datastore';
import { Issue } from 'models';
import { Pagination } from '@mui/material';
import ReleaseNoteComponent from './common/ReleaseNoteComponent';
import { ReleaseNotes } from 'models';
import { useErrorHook } from 'common/ErrorHook';
import { ErrorPopUps } from 'common/ErrorPopUps';
import { paginate } from 'helpers/ListHelpers';

type CompletedIssuesProps = {
    username: string,
    isMod: boolean,
    triggerAuth: () => void,
    isBetaUser: boolean
}

type IssuesByDate = {
    [date: string]: Issue[]
}

type ReleaseNotesByDate = {
    [date: string]: ReleaseNotes
}

export default function CompletedIssues({ username, isMod, triggerAuth, isBetaUser }: CompletedIssuesProps) {
    const [ issuesByDate, setIssuesByDate ] = useState<IssuesByDate[]>([]);
    const [ releaseNotesByDate, setReleaseNotesByDate ] = useState<ReleaseNotesByDate>({});
    const [ currentPage, setCurrentPage ] = useState(0);
    const { errors, pushError, cancelError } = useErrorHook([]);
    const history = useHistory();

    useEffect(() => {
        DataStore.query(Issue)
            .then((response) => {
                const bugs = [ ...response ]
                    .filter((issue) => issue.approved && issue.completed)
                    .sort(sortByDateCompleted);
                const paginatedBugs = paginate(bugs, 25);
                const bugsByMonth = paginatedBugs.map((issuePage) => {
                    return issuePage.reduce((prev, current) => {
                        // get current month
                        const currentDateDate = new Date(current.dateCompleted);
                        const currentDate = currentDateDate.getFullYear() + ' ' 
                                                + currentDateDate.toLocaleString('default', { month: 'long' });
                        if (prev && prev[currentDate]) {
                            prev[currentDate] = [ ...prev[currentDate], current ];
                        } else {
                            prev[currentDate] = [ current ];
                        }
                        return prev;
                    }, {} as IssuesByDate);
                });
                setIssuesByDate(bugsByMonth);
            }).catch((error) => pushError(error));

        DataStore.query(ReleaseNotes)
            .then((response) => {
                const byDate = {} as ReleaseNotesByDate;
                response.forEach(release => {
                    if (release.date) {
                        byDate[release.date] = release;
                    }
                });
                setReleaseNotesByDate(byDate);
            }).catch((error) => pushError(error));
    }, []);

    return <div className="App">
        <Typography>
            <header className="App-header">
                <AppBar position="fixed" style={{ top: 0 }}>
                    <Box style={{position:'absolute', left:10}}>
                        <Button onClick={() => history.push('/')} color="primary" variant="contained">
                        Home
                        </Button>
                    </Box>
                    <AppBarProfile username={username} triggerAuth={triggerAuth} isBetaUser={isBetaUser}/>
                </AppBar>
                <Button variant="contained" onClick={() => history.push('/issues')}>
                    Back To Issues
                </Button>
                <ErrorPopUps errors={errors} cancelError={cancelError} />
                <Card style={{ width: '80%', marginTop: 10, backgroundColor: '#E3FCBF' }}>
                    <h1>Recently Pushed</h1>
                    { issuesByDate[currentPage] && Object.keys(issuesByDate[currentPage]).map((date) => {
                        return <>
                            <h2>{date}</h2>
                            <ReleaseNoteComponent
                                key={date + 'release'}
                                date={date}
                                releaseNotes={releaseNotesByDate[date]}
                                canEdit={isMod}
                                pushError={pushError}
                            />
                            <List>
                                {issuesByDate[currentPage][date].map((issue, index) => {
                                    if (issue.approved && issue.completed) {
                                        return <>
                                            <p style={{color: 'grey', fontSize: '15px', margin: '0px'}}>Completed at {issue.dateCompleted || 'unknown'}</p>
                                            <IssueListItem
                                                key={index}
                                                issueType={issueTypes.BUG}
                                                issue={issue}
                                                username={username}
                                                deleteIssue={() => deleteIssue(issue.id, pushError)}
                                                isModerator={isMod}
                                                approve={() => {}}
                                                upvote={() => {}}
                                            />
                                        </>;
                                    }}
                                )}
                            </List>
                        </>;
                    })}
                    <div style={{ display: 'flex', flexFlow: 'wrap', justifyContent: 'center' }}>
                        <Pagination
                            count={issuesByDate.length}
                            onChange={(event, value)=> setCurrentPage((value-1))}
                            page={currentPage+1}
                        />
                    </div>
                </Card>
            </header>
        </Typography>
    </div>;
}