import Alert from '@mui/material/Alert';
import React from 'react';
import FridgeComponent from './Fridge/FridgeComponent';
import { updateFridge } from 'helpers/DataStoreHelper';

export default function FridgePage({ fridgeResponse, pushError }) {

    return <>
        <Alert severity="warning">This feature is currently being built and is not expected to work properly.</Alert>
        <h3>Fridge</h3>
        <FridgeComponent
            fridgeResponse={fridgeResponse}
            updateFridge={updateFridge}
            pushError={pushError}
        />
    </>;
}