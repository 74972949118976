import React, { useState } from 'react';
import 'App.css';
import { Box, Button, Card, Modal } from '@mui/material';
import { useCallback } from 'react';
import { updatePassword } from 'aws-amplify/auth';
import { PasswordInput } from 'common/PasswordInput';
import { modalStyle } from 'constants/StylingConstants';

type Props = {
    handleClose: () => void,
    pushError: (error: string, isSuccess?: boolean) => void
}

export default function ChangePasswordModal({ handleClose, pushError }: Props) {
    const [ oldPassword, setOldPassword ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ confirmPassword, setConfirmPassword ] = useState('');

    const setNewPassword = useCallback(() => {
        updatePassword({
            oldPassword,
            newPassword: password
        }).then(() => {
            pushError('Password changed.', true);
            handleClose();
        }).catch((err) => pushError(err));
    }, [ password ]);

    return <Modal
        open={true}
        onClose={handleClose}
    >
        <Card style={modalStyle}>
            <Box
                component="form"
                onSubmit={(e) => {
                    e.preventDefault();
                    setNewPassword();
                }}
            >
                <PasswordInput
                    password={oldPassword}
                    setPassword={setOldPassword}
                    label='Current Password'
                    error={oldPassword.length < 1}
                />
                <br/>
                <PasswordInput
                    password={password}
                    setPassword={setPassword}
                    label='New Password'
                    error={password.length < 1}
                />
                <p style={{ fontSize: '10px' }}>Password must contain 1 uppercase and lowercase character, 1 special character and 1 number.</p>
                <br/>
                <PasswordInput
                    password={confirmPassword}
                    setPassword={setConfirmPassword}
                    label='Confirm New Password'
                    error={confirmPassword !== password || confirmPassword.length < 1}
                />
                <br/>
                <Button
                    type="submit"
                    disabled={confirmPassword !== password || oldPassword.length < 1 || confirmPassword.length < 1 || password.length < 1}
                >
                        Set Password
                </Button>
            </Box>
        </Card>
    </Modal>;
}