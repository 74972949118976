import {
    AppBar,
    Collapse,
    CssBaseline,
    Drawer,
    List,
    ListItemButton,
    Toolbar
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import React, { useCallback } from 'react';
import { useState } from 'react';
import { AppBarProfile } from './AppBarProfile';
import { isSmallDevice } from 'helpers/StyleHelper';
import { BetaChip } from './BetaChip';
import { DrawerButton } from './DrawerButton';
import { testIds } from 'constants/TestConstants';
import { styled } from '@mui/material/styles';

// https://v4.mui.com/components/drawers/#PersistentDrawerLeft.js
const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
  }>(({ theme, open }) => ({
      flexGrow: 1,
      transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: `-${drawerWidth}px`,
      ...(open && {
          transition: theme.transitions.create('margin', {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.enteringScreen,
          }),
          marginLeft: 0,
      }),
  }));

type Props = {
    children: string | JSX.Element | JSX.Element[],
    screenWidth: number,
    isBetaUser: boolean,
    username: string,
    triggerAuth: () => void,
    handleTabChange: (tab: number) => void,
    tab: number
}

export function MainAppBar({ children, screenWidth, isBetaUser, username, triggerAuth, handleTabChange, tab }: Props) {
    const [ drawerOpen, setDrawerOpen ] = useState(!isSmallDevice(screenWidth) ? true : false);
    const [ mealOpen, setMealOpen ] = useState(false);

    const tabNames = isBetaUser ? [ 'My Day', 'Tasks', 'Goals', 'Shop', 'Notes' ] : [ 'My Day', 'Tasks', 'Goals' ] ;

    const onTabClick = useCallback((num: number) => {
        // Close drawer if mobile
        if(isSmallDevice(screenWidth)) {
            setDrawerOpen(false);
        }

        handleTabChange(num);
    }, [ setDrawerOpen, handleTabChange ]);

    const renderDrawer = () => {

        return <div>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    overflow: 'hidden',
                    fontSize: '30px',
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                        backgroundColor: '#303538',
                        color: '#E6E6E6'
                    },
                }}
                anchor={'left'}
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                variant={!isSmallDevice(screenWidth) ? 'persistent': 'temporary'}
            >
                <div style={{ overflow: 'auto' }} data-testid={testIds.mainAppDrawer}>
                    <Toolbar />
                    <List>
                        {tabNames.map((text, index) => (
                            <ListItemButton key={text + 'drawer'} selected={tab === index} onClick={() => onTabClick(index)}>
                                {text}
                            </ListItemButton>
                        ))}
                        {isBetaUser && <>
                            <ListItemButton onClick={(event) => {
                                event.preventDefault();
                                setMealOpen(!mealOpen);
                            }}>
                                Meals {mealOpen ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                            <Collapse in={mealOpen}>
                                <List>
                                    <ListItemButton style={{fontSize: '20px'}} selected={tab === 5} onClick={() => onTabClick(5)}>
                                        Recipes
                                    </ListItemButton>
                                    <ListItemButton style={{fontSize: '20px'}} selected={tab === 6} onClick={() => onTabClick(6)}>
                                        Ingredients
                                    </ListItemButton>
                                    <ListItemButton style={{fontSize: '20px'}} selected={tab === 7} onClick={() => onTabClick(7)}>
                                        Meal Diary
                                    </ListItemButton>
                                    <ListItemButton style={{fontSize: '20px'}} selected={tab === 8} onClick={() => onTabClick(8)}>
                                        Fridge
                                    </ListItemButton>
                                </List>
                            </Collapse>
                        </>}
                    </List>
                </div>
                <div style={{ position: 'fixed', left: 0, bottom: 0, color: 'white', fontSize: '15px' }}>
                    V0.14
                </div>
            </Drawer>
        </div>;
    };

    return <>
        <CssBaseline />
        <AppBar
            position="fixed"
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: '#303538' }}
        >
            <Toolbar>
                <DrawerButton onClick={() => setDrawerOpen(!drawerOpen)}/>
                { isBetaUser && <BetaChip/> }
                <AppBarProfile isBetaUser={isBetaUser} username={username} triggerAuth={triggerAuth} submitAnIssue={true}/>
            </Toolbar>
        </AppBar>
        { renderDrawer() }
        { !isSmallDevice(screenWidth) ?
            <Main open={drawerOpen} style={{width: '100%'}}>
                <Toolbar />
                {children}
            </Main>
            : <main style={{width: '100%'}}>
                <Toolbar />
                {children}
            </main>}
    </>;
}