import { getFormattedDate } from 'helpers/TimeHelper';
import { DailyDateTimeMap } from 'models/CommonTypes';

// TODO this probably takes a lot of time :P
export function getStreak(dateTimes: DailyDateTimeMap, today: string) {
    if (!dateTimes) {
        return 0;
    }

    let streak = 0;
    let loop = new Date(today + ' 00:00');
    if (!dateTimes[today] || !dateTimes[today].completed) {
        const tempDate = loop.setDate(loop.getDate() - 1);
        loop = new Date(tempDate);
    }
    
    while (dateTimes[getFormattedDate(loop)] && dateTimes[getFormattedDate(loop)].completed) {
        streak++;
        const newDate = loop.setDate(loop.getDate() - 1);
        loop = new Date(newDate);
    }

    return streak;
}

// TODO this probably takes a lot of time :P
export function getHighScore(dateTimes: DailyDateTimeMap) {
    if (!dateTimes) {
        return 0;
    }

    const highscore = 0;
    // TODO

    return highscore;
}

export function getDaysArray(start: number, end: number) {
    const arr=[];
    for(let dt=new Date(start); dt<=new Date(end); dt.setDate(dt.getDate()+1)){
        arr.push(new Date(dt));
    }
    return arr;
}

export function convertToDateTimes(dateTimeString: string|null|undefined): DailyDateTimeMap {
    if (!dateTimeString) {
        return {};
    }

    if (typeof dateTimeString !== 'string') {
        return dateTimeString;
    }

    return JSON.parse(dateTimeString);
}