import { Button, Grid, Input, TextField } from '@mui/material';
import { Note } from 'models';
import React, { useState } from 'react';
import { DataStore } from 'aws-amplify/datastore';
import { updateNote } from 'helpers/DataStoreHelper';

type Props = {
    notes: Note[],
    username: string,
    pushError: (error:string) => void
}

export default function Notes({notes, username, pushError}: Props) {
    const [localNotes, setLocalNotes] = useState(notes);
    const [currentNote, setCurrentNote] = useState<Note|undefined|null>();
    const [workingTitle, setWorkingTitle] = useState<string|null|undefined>();
    const [workingContents, setWorkingContents] = useState<string|null|undefined>();

    return <div style={{height: '93dvh', padding: '0px', margin: '0px', width: '100%'}}>
        <div style={{width: 200, float: 'left', padding: '0px !important', height:'100%', margin: '0px !important', backgroundColor: 'grey'}}>
            <Grid
                style={{height:'100%', padding: '0 !important', margin: '0 !important', width: '100%'}}
                container
                direction="column"
                spacing={0}
            >
                <Button
                    variant="contained"
                    onClick={() => {
                        const newNotes = localNotes ? [ ...localNotes ] : [];
                        const newNote = new Note({
                            owner: username
                        });
                        DataStore.save(newNote).catch((error) => pushError(error));
                        newNotes.unshift(newNote);
                        setLocalNotes(newNotes);
                    }}>
                        Add Note
                </Button>
                {localNotes && localNotes.map((note: Note) =>
                    <Grid item
                        style={{padding: 0, margin:0, width: '100%'}}
                        key={note.id}>
                        <Button
                            style={{ minHeight: '50px', margin: 0, width: '100%', color:'black' }}
                            onClick={() => {
                                setCurrentNote(note);
                                setWorkingTitle(note.title || 'Untitled Document');
                                setWorkingContents(note.content);
                            }}
                        >
                            <p style={{fontSize: '12px', width: '100%'}}>{note.title || 'Untitled Document'}</p>
                        </Button>
                    </Grid>
                )}
            </Grid>
        </div>
        <div>
            Under Construction<br/>
            {currentNote && <>
            Title:
                <Input
                    value={workingTitle}
                    onChange={(event) => setWorkingTitle(event.target.value)}
                    style={{backgroundColor: 'white'}}
                />
                <Button
                    onClick={() => {
                        updateNote(currentNote.id, {
                            title: workingTitle,
                            content: workingContents
                        }, pushError);
                    }}
                >Save</Button>
                <Button
                    onClick={() => {
                        DataStore.delete(Note, post => post.id.eq(currentNote.id))
                            .then((response) => {
                                console.log('Successfully deleted note');
                                console.log(response);
                                setCurrentNote(null);
                            })
                            .catch((error) => pushError(error));
                    }}
                >Delete</Button>
                <br/>
                <TextField
                    variant="standard"
                    style={{ width: '50%', marginLeft: '15px', backgroundColor: 'white' }}
                    value={workingContents}
                    onChange={(event) => setWorkingContents(event.target.value)}
                    multiline
                    rows={20}
                />
            </>
            }
        </div>
    </div>;
}