import { Card, CardActionArea, Chip, Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Alert from '@mui/material/Alert';
import { DataStore } from 'aws-amplify/datastore';
import { FoodInformation } from 'models';
import React, { useCallback, useEffect, useState } from 'react';
import NutritionFacts from './Nutrition/NutritionFacts';
import { testIds } from 'constants/TestConstants';

export default function NutritionFactPage({ foodInfoResponse, recipes, username, pushError }) {
    const [ foodInfo, setFoodInfo ] = useState(foodInfoResponse);
    const [ unknownIngredients, setUnknownIngredients ] = useState([]);

    const deleteFoodInfo = useCallback((index) => {
        const newFoodInformation = [...foodInfo];
        const deletedFood = newFoodInformation.splice(index, 1);
        DataStore.delete(FoodInformation, thisFood => thisFood.id.eq(deletedFood[0].id))
            .then((response) => console.log(response))
            .catch((error) => pushError(error));
        setFoodInfo(newFoodInformation);
    }, [ foodInfo ]);

    useEffect(() => {
        new Promise((resolve) => {
            const newUnknownIngredients = new Set();

            const knownIngredients = foodInfo.filter((ingredient) => ingredient.name)
                .map((ingredient) => ingredient.name.toLowerCase());

            recipes.forEach((recipe) => {
                recipe.ingredients && recipe.ingredients.forEach((ingredient) => {
                    if (ingredient.name && !knownIngredients.includes(ingredient.name.toLowerCase())) {
                        newUnknownIngredients.add(ingredient.name.toLowerCase());
                    }
                });
            });

            resolve([ ...newUnknownIngredients ]);
        }).then((result) => setUnknownIngredients(result));
    }, [ recipes, setUnknownIngredients ]);

    return <>
        <Alert severity="warning">This feature is currently being built and is not expected to work properly.</Alert>
        {unknownIngredients && unknownIngredients.length > 0 && <Alert severity="warning">
            It looks like you have references to a few ingredients without nutrition information saved.
            <br/>
            {unknownIngredients.map((ingredient) =>
                <Chip key={ingredient + 'unkown'} label={ingredient}/>
            )}
        </Alert>}
        <h3>Nutrition Facts</h3>
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            data-testid={testIds.nutritionFacts}
        >
            <Card style={{ width: 250, minHeight: 150, padding: 5, margin: 5, textAlign: 'center', display: 'inline-block'}}>
                <CardActionArea
                    onClick={() => {
                        const newFoodInfo = foodInfo ? [ ...foodInfo ] : [];
                        const newFood = new FoodInformation({
                            owner: username
                        });
                        DataStore.save(newFood).catch((error) => pushError(error));
                        newFoodInfo.unshift(newFood);
                        setFoodInfo(newFoodInfo);
                    }}
                    style={{ minWidth: 250, minHeight: 300 }}
                >
                    <AddIcon style={{ position: 'relative', top: '50%' }} fontSize="large" />
                </CardActionArea>
            </Card>
            {foodInfo.map((foodInfo, index) => 
                <NutritionFacts
                    key={foodInfo ? foodInfo.id + index : 'nutrition' + index}
                    nutritionFact={foodInfo}
                    deleteFoodInformation={() => deleteFoodInfo(index)}
                    pushError={pushError}
                />
            )}
        </Grid>
    </>;
}