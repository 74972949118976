import { Button, Card, CircularProgress, IconButton, MenuItem, Select } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { InputTextField } from 'common/InputTextField';
import { IngredientUnit } from 'constants/MealConstants';
import { updateFoodInformation } from 'helpers/DataStoreHelper';
import React, { useCallback, useState } from 'react';
import { validateNutritionFact } from './NutritionFactCommon';
import { testIds } from 'constants/TestConstants';

export default function NutritionFacts({
    nutritionFact,
    deleteFoodInformation,
    pushError
}) {
    const [ name, setName ] = useState(nutritionFact.name || '');
    const [ calories, setCalories ] = useState(nutritionFact.calories || 0.0);
    const [ totalFat, setTotalFat ] = useState(nutritionFact.totalFat || 0.0);
    const [ protein, setProtein ] = useState(nutritionFact.protein || 0.0);
    const [ sugar, setSugar ] = useState(nutritionFact.sugar || 0.0);
    const [ servingSize, setServingSize ] = useState(nutritionFact.servingSize || 1.0);
    const [ servingSizeUnit, setServingSizeUnit ] = useState(nutritionFact.servingSizeUnit || IngredientUnit.UNIT);
    const [ unsaved, setUnsaved ] = useState(false);
    const [ saving, setSaving ] = useState(false);
    const [ invalidFields, setInvalidFields ] = useState([]);

    const saveNutritionInfo = useCallback(() => {
        setSaving(true);
        const newNutritionFact = {
            name,
            calories,
            totalFat,
            protein,
            sugar,
            servingSize,
            servingSizeUnit
        };

        validateNutritionFact(nutritionFact.id, newNutritionFact).then((validationErrors) => {
            if (validationErrors.length === 0) {
                updateFoodInformation(nutritionFact.id, newNutritionFact, pushError);
                setUnsaved(false);
                setSaving(false);
                setInvalidFields([]);
            } else {
                const newInvalidFields = [];
                validationErrors.forEach(error => {
                    newInvalidFields.push(error.fieldName);
                    pushError(error.message);
                });
                setInvalidFields(newInvalidFields);
                setSaving(false);
            }
        }).catch((error) => {
            setSaving(false);
            pushError(error);
        });
        
    }, [name, calories, totalFat, protein, sugar, servingSize, servingSizeUnit, nutritionFact]);

    return <Card style={{minWidth:300, minHeight:300, padding:5, margin:5}} data-testid={testIds.nutritionFactComponent}>
        {
            // If in the process of saving show loader
            saving ? <><CircularProgress/><br/></> :
                unsaved && <><Button
                    style={{backgroundColor: 'green'}}
                    onClick={() => saveNutritionInfo()}
                >
                        save
                </Button><br/></>
        }
        <IconButton
            style={{width: '100px', display: 'inline-block', float: 'right', textAlign:'right', color:'red'}}
            onClick={deleteFoodInformation}
            size="large">
            <DeleteIcon/>
        </IconButton>
        <InputTextField
            name="name"
            label="Ingredient Name"
            value={name}
            error={invalidFields.includes('name')}
            onChangeEvent={(value) => {
                setName(value);
                setUnsaved(true);
            }}
        />
        <br/>
        <InputTextField
            name="servingSize"
            label="Serving Size"
            value={servingSize}
            onChangeEvent={(value) => {
                setServingSize(parseFloat(value));
                setUnsaved(true);
            }}
            isNumber={true}
        />
        <Select
            variant="standard"
            style={{width:'15%'}}
            value={servingSizeUnit}
            label="Unit"
            data-testid={testIds.selector}
            onChange={(event) => {
                setServingSizeUnit(event.target.value);
                setUnsaved(true);
            }}>
            {Object.values(IngredientUnit).map((type) => <MenuItem key={type} value={type}>{type}</MenuItem>)}
        </Select>
        <br/>
        <InputTextField
            name="calories"
            label="Calories"
            value={calories}
            onChangeEvent={(value) => {
                setCalories(parseFloat(value));
                setUnsaved(true);
            }}
            isNumber={true}
        />
        <br/>
        <InputTextField
            name="totalFat"
            label="Total Fat (g)"
            value={totalFat}
            onChangeEvent={(value) => {
                setTotalFat(parseFloat(value));
                setUnsaved(true);
            }}
            isNumber={true}
        />
        <br/>
        <InputTextField
            name="protein"
            label="Protein (g)"
            value={protein}
            onChangeEvent={(value) => {
                setProtein(parseFloat(value));
                setUnsaved(true);
            }}
            isNumber={true}
        />
        <br/>
        <InputTextField
            name="sugar"
            label="Sugar (g)"
            value={sugar}
            onChangeEvent={(value) => {
                setSugar(parseFloat(value));
                setUnsaved(true);
            }}
            isNumber={true}
        />
        <br/>
    </Card>;
}