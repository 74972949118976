import { defaultBreakfastEnd, defaultBreakfastStart, defaultDinnerEnd, defaultDinnerStart, defaultLunchEnd, defaultLunchStart } from 'constants/MealConstants';
import { Category, DateTimeWindow, Fridge, Goal, Ingredient, MealType, Preference, Recipe, SortEntry, SortType, Task } from 'models';
import { taskTypes } from '../../constants/TaskConstants';
import { getScheduledTime, isDueDateClose } from '../../helpers/TaskHelpers';
import { calculateMinutesFromTime, calculateMinutesFromDate, calculateTimeFromMinutes, isScheduledTaskToday } from '../../helpers/TimeHelper';
import { getSortedTasks } from './TaskSorter';
import { getNonNullTimeWindows, isCategoryScheduledToday, isTimeWithinCategoryWindow } from 'preferences/Category/CategoryCommon';
import { createGroceryTask, createRecipeTask, getBlockingTasks, getNeededIngredients, getPlannableTasks } from './SchedulingCommonFunctions';
import { getFridgeNameToAmount } from 'mealprep/Common/IngredientCommon';
import { Meals } from 'models/CommonTypes';
import { taskStartTimeComparator } from 'tasks/taskCommon/TaskComparator';
import { setScheduledTime } from 'tasks/taskCommon/TaskCommonFunctions';

const DEFAULT_GROCERY_TIME_MINUTES = 30;
const DEFAULT_RECIPE_TIME_MINUTES = 30;

// Commenting out for now so that it doesnt overwrite saved sort orders
const DEFAULT_SORT_ENTRIES = [
    new SortEntry({
        type: SortType.PRIORITY
    }),
    new SortEntry({
        type: SortType.DURATION
    }),
    new SortEntry({
        type: SortType.DUEDATE
    })
];

type TasksByCategory = {
    [index: string]: Task[]
}

export function optimizeTasks(
    tasks: Task[],
    today: string,
    startTime: Date,
    endTime: Date,
    currentMinutes: number,
    username: string,
    meals: Meals,
    preferences: Preference,
    fridgeResponse: Fridge,
    goals: Goal[],
    pushError: (error: string) => void,
    categories: Category[]
) {
    const { breakFrequency, breakTime, breakfastTimeWindow, lunchTimeWindow, dinnerTimeWindow } = preferences;

    // Set up meal related variables
    const breakfastStartTime = breakfastTimeWindow && breakfastTimeWindow.startTime || defaultBreakfastStart;
    const breakfastEndTime = breakfastTimeWindow && breakfastTimeWindow.endTime || defaultBreakfastEnd;
    const lunchStartTime = lunchTimeWindow && lunchTimeWindow.startTime || defaultLunchStart;
    const lunchEndTime = lunchTimeWindow && lunchTimeWindow.endTime || defaultLunchEnd;
    const dinnerStartTime = dinnerTimeWindow && dinnerTimeWindow.startTime || defaultDinnerStart;
    const dinnerEndTime = dinnerTimeWindow && dinnerTimeWindow.endTime || defaultDinnerEnd;
    const fridgeNameToAmount = getFridgeNameToAmount(fridgeResponse.ingredients);
    let groceryTask = tasks.filter(task => task.type === taskTypes.GROCERY && !task.completed)[0];
    let breakfastAssociatedTaskId;
    let lunchAssociatedTaskId;
    let dinnerAssociatedTaskId;

    //Scheduled tasks
    const startTimeInMinutes = calculateMinutesFromDate(startTime);
    const endTimeInMinutes = calculateMinutesFromDate(endTime);
    const todaysTasks = tasks.filter(task => isScheduledTaskToday(task, today));

    todaysTasks.sort((a, b) => taskStartTimeComparator(a, b, today));

    // Get any categories scheduled for today
    const categoriesScheduledToday = categories ? categories.filter((category) => isCategoryScheduledToday(category, today)) : [];

    // Gather tasks that are blocking, when they are scheduled we will add the tasks it is blocking
    // to available tasks
    const blockingTasks = getBlockingTasks(tasks, today);

    // ASAP tasks
    const unCompletedTasks = getPlannableTasks(tasks, today);
    
    const tasksByCategory = unCompletedTasks.reduce(function(acc, task) {
        const category = isDueDateClose(task, today) ? 'None' : task.category || 'None';
        acc[category] = [...(acc[category] || []), task];
        return acc;
    }, {} as TasksByCategory);

    const nonCategoryTasks = tasksByCategory.None || [];
    // Get categories that are not restricted or prioritized and add those tasks to the above list as well
    categories.filter(category => category.restrict === false || category.prioritize === false)
        .forEach(category => {
            if (category.categoryId && tasksByCategory[category.categoryId]) {
                nonCategoryTasks.push(...tasksByCategory[category.categoryId]);
            }
        });
    
    // Sort tasks based on sort order configured, or by the default
    const sortEntries = preferences.sortEntryOrder && preferences.sortEntryOrder.length > 0 ? preferences.sortEntryOrder : DEFAULT_SORT_ENTRIES;
    let prioritizedTasks = getSortedTasks(nonCategoryTasks, sortEntries, today, goals);

    const addTaskToWorkingTasks = (task: Task) => {
        console.log(`Adding task ${task.id}`);
        if (task.category) {
            tasksByCategory[task.category].push(task);
        } else {
            prioritizedTasks = getSortedTasks([ ...prioritizedTasks, task ], sortEntries, today, goals);
        }
    };

    // Adds task to list with the datesAssigned field populated
    const addToTodaysTasks = (index: number, task: Task) => {
        const datesAssigned = task.datesAssigned ? [ ...task.datesAssigned ] : [];
        if (datesAssigned && !datesAssigned.includes(today)) {
            datesAssigned.push(today);
            const newtask = {
                ...task,
                datesAssigned
            };
            todaysTasks.splice(index, 0, newtask);
        } else {
            todaysTasks.splice(index, 0, task);
        }

        if (blockingTasks[task.id]) {
            blockingTasks[task.id].forEach((task: Task) => addTaskToWorkingTasks(task));
        }
    };

    const handleMealScheduling = (
        unownedIngredients: Ingredient[],
        recipe: Recipe,
        thisStartTime: number,
        duration: number,
        mealStartTime: string,
        mealEndTime: string,
        index: number,
        incrementIndex: () => void,
        fillSlot: (taskId: string) => void,
        type: MealType
    ) => {
        const groceryTime = preferences.groceryTime ? preferences.groceryTime : DEFAULT_GROCERY_TIME_MINUTES;
        const recipeTime = recipe.duration ? recipe.duration : DEFAULT_RECIPE_TIME_MINUTES;
        // Check if there are unowned ingredients, if so we need to factor in getting groceries to the duration
        // TODO we may want some kind of check before scheduling or error after scheduling if groceries cant be scheduled for a certain meal
        if (unownedIngredients.length > 0
                && thisStartTime >= calculateMinutesFromTime(mealStartTime)
                && thisStartTime < calculateMinutesFromTime(mealEndTime)
                && recipeTime + groceryTime < duration) {
            groceryTask = createGroceryTask(unownedIngredients, groceryTask, groceryTime, username, today, thisStartTime, pushError);
            prioritizedTasks = prioritizedTasks.filter((task) => task.id !== groceryTask.id);
            addToTodaysTasks(index+1, groceryTask);
            thisStartTime += groceryTime;
            const task = createRecipeTask(recipe, today, thisStartTime, type, username, pushError);
            addToTodaysTasks(index+2, task);
            incrementIndex();
            fillSlot(task.id);
        } else if (unownedIngredients.length === 0
                && thisStartTime >= calculateMinutesFromTime(mealStartTime)
                && thisStartTime < calculateMinutesFromTime(mealEndTime)
                && recipeTime < duration) {
            const task = createRecipeTask(recipe, today, thisStartTime, type, username, pushError);
            addToTodaysTasks(index+1, task);
            fillSlot(task.id);
        }
    };

    let lastBreakTimeMinutes = startTimeInMinutes;

    let hasBreakfastBeenScheduled = meals[MealType.BREAKFAST] && meals[MealType.BREAKFAST].recipe ? false : true;
    const breakfastRecipe = meals[MealType.BREAKFAST] ? meals[MealType.BREAKFAST].recipe : null;
    const breakfastUnownedIngredients = breakfastRecipe ? getNeededIngredients(breakfastRecipe, fridgeNameToAmount) : [];

    let hasLunchBeenScheduled = meals[MealType.LUNCH] && meals[MealType.LUNCH].recipe ? false : true;
    const lunchRecipe = meals[MealType.LUNCH] ? meals[MealType.LUNCH].recipe : null;
    const lunchUnownedIngredients = lunchRecipe ? getNeededIngredients(lunchRecipe, fridgeNameToAmount) : [];

    let hasDinnerBeenScheduled = meals[MealType.DINNER] && meals[MealType.DINNER].recipe ? false : true;
    const dinnerRecipe = meals[MealType.DINNER] ? meals[MealType.DINNER].recipe : null;
    const dinnerUnownedIngredients = dinnerRecipe ? getNeededIngredients(dinnerRecipe, fridgeNameToAmount): [];

    for (let i = -1; i < todaysTasks.length; i++) {
        // If -1 then targetTask is undefined
        const targetTask = todaysTasks[i];

        // This should never happen, but to appease the typescript gods check that task has a duration
        if (i > -1 && !targetTask.duration) continue;

        // If beginning of list, start time is default, else its the last scheduled task + its duration
        let thisStartTime = i === -1 ? startTimeInMinutes : (calculateMinutesFromTime(getScheduledTime(targetTask, today)) + (targetTask.duration || 0));

        // Add break if its the correct time
        const targetBreakTime = !breakFrequency || breakFrequency === '0' ? -1 : lastBreakTimeMinutes + parseInt(breakFrequency);
        if (targetBreakTime !== -1 && breakTime && targetBreakTime < thisStartTime) {
            console.log(`Break time! At ${calculateTimeFromMinutes(thisStartTime)} minutes`);
            thisStartTime += parseInt(breakTime);
            lastBreakTimeMinutes = thisStartTime;
        }

        // If no tasks past this one end time is default end time
        const thisEndTime = todaysTasks[i + 1] ? calculateMinutesFromTime(getScheduledTime(todaysTasks[i + 1], today)) : endTimeInMinutes;

        // If end time is less than the previous tasks end time, lets skip this task
        
        if (i > 0 ) {
            const lastTaskStartTime = calculateMinutesFromTime(getScheduledTime(todaysTasks[i - 1], today));
            const lastTaskDuration = (todaysTasks[i - 1].duration || 0);
            if (lastTaskStartTime + lastTaskDuration > thisStartTime) {
                thisStartTime = lastTaskStartTime + lastTaskDuration;
            }
        }
        
        // TODO If start time is within a category time, try to fit a task between startTime and category end time. If nothing is found continue

        // If there are no more prioritized tasks, checks and sets if there is any more tasks
        // that could be scheduled in a time window later in the day.
        // (For example a category in the future)
        if (prioritizedTasks.length === 0) {
            //Out of tasks :(
            console.log('No more regular tasks for the day');
            let minTime = -1;
            //Check if breakfastRecipe been scheduled and in window
            if (!hasBreakfastBeenScheduled && thisStartTime < calculateMinutesFromTime(breakfastEndTime)) {
                console.log('getting breakfast min time');
                minTime = calculateMinutesFromTime(breakfastStartTime);
            }
            //Check if lunchRecipe been scheduled and in window
            if (!hasLunchBeenScheduled && thisStartTime < calculateMinutesFromTime(lunchEndTime)) {
                const minLunchTime = calculateMinutesFromTime(lunchStartTime);
                if (minTime !== -1) {
                    minTime = Math.min(minLunchTime, minTime);
                } else {
                    minTime = minLunchTime;
                }
            }
            //Check if dinnerRecipe been scheduled and in window
            if (!hasDinnerBeenScheduled && thisStartTime < calculateMinutesFromTime(dinnerEndTime)) {
                const minDinnerTime = calculateMinutesFromTime(dinnerStartTime);
                if (minTime !== -1) {
                    minTime = Math.min(minDinnerTime, minTime);
                } else {
                    minTime = minDinnerTime;
                }
            }

            // Check there is no category tasks later in the day
            const categoriesNotPast = categoriesScheduledToday.filter(category => {
                if (category.static === false) {
                    // Fetch non null windows for today
                    const validTimeWindows: DateTimeWindow[] = getNonNullTimeWindows(category).filter(window => window.date == today);
                    // Filter if not every window is in the past
                    return validTimeWindows.length > 0 && !validTimeWindows.every(window => calculateMinutesFromTime(window.endTime) < thisStartTime);
                } else {
                    return !(calculateMinutesFromTime(category.endTime) < thisStartTime);
                }
            });

            if (categoriesNotPast.length > 0) {
                console.log('getting categories min time');
                // We only want the min time of categories that actually have tasks to be scheduled
                const categorysWithTasks = categoriesNotPast.filter((category) => category.categoryId && tasksByCategory[category.categoryId] && tasksByCategory[category.categoryId].length > 0);
                const categoryStartTimes = categorysWithTasks.map(category => {
                    if (category.static === false) {
                        // Fetch non null windows for today
                        const validTimeWindows: DateTimeWindow[] = getNonNullTimeWindows(category).filter(window => window.date == today);
                        // Fetch all time windows in the future
                        return validTimeWindows.filter(window => calculateMinutesFromTime(window.endTime) > thisStartTime)
                            .map(window => calculateMinutesFromTime(window.startTime));
                    } else {
                        return [ calculateMinutesFromTime(category.startTime) ];
                    }
                }).flat();
                //If minTime is not -1 that means an earlier check set it to something new
                if (minTime !== -1) {
                    categoryStartTimes.push(minTime);
                }
                minTime = Math.min(...categoryStartTimes);
            }

            if (minTime > thisStartTime) {
                console.log(`Resseting start time to ${calculateTimeFromMinutes(minTime)}`);
                thisStartTime = minTime;
            }
        }
        
        if (thisEndTime > currentMinutes) {
            // Resets start time if current time of day is later than start time
            if (thisStartTime < currentMinutes) {
                thisStartTime = currentMinutes;
            }

            const duration = thisEndTime - thisStartTime;
            let slotFilled = false;

            if (!slotFilled && !hasBreakfastBeenScheduled && breakfastRecipe) {
                handleMealScheduling(
                    breakfastUnownedIngredients,
                    breakfastRecipe,
                    thisStartTime,
                    duration,
                    breakfastStartTime,
                    breakfastEndTime,
                    i,
                    () => i++,
                    (taskId) => { 
                        slotFilled = true;
                        breakfastAssociatedTaskId = taskId;
                        hasBreakfastBeenScheduled = true;
                    },
                    MealType.BREAKFAST
                );
            }

            if (!slotFilled && !hasLunchBeenScheduled && lunchRecipe) {
                handleMealScheduling(
                    lunchUnownedIngredients,
                    lunchRecipe,
                    thisStartTime,
                    duration,
                    lunchStartTime,
                    lunchEndTime,
                    i,
                    () => i++,
                    (taskId) => {
                        slotFilled = true;
                        lunchAssociatedTaskId = taskId;
                        hasLunchBeenScheduled = true;
                    },
                    MealType.LUNCH
                );
            }

            if (!slotFilled && !hasDinnerBeenScheduled && dinnerRecipe) {
                handleMealScheduling(
                    dinnerUnownedIngredients,
                    dinnerRecipe,
                    thisStartTime,
                    duration,
                    dinnerStartTime,
                    dinnerEndTime,
                    i,
                    () => i++,
                    (taskId) => {
                        slotFilled = true;
                        dinnerAssociatedTaskId = taskId;
                        hasDinnerBeenScheduled = true;
                    },
                    MealType.DINNER
                );
            }

            const categoriesWithinTime = categoriesScheduledToday.filter((category) =>
                isTimeWithinCategoryWindow(category, thisStartTime, today));

            if (!slotFilled) {
                // Gets all category tasks within time, even for overlapping
                // TODO find a better way to do this so that its not sorting every time
                // Split tasks up into prioritized category tasks and regular category tasks
                if (categoriesWithinTime.length > 0) {
                    // Check for priotized tasks, unprioritized tasks will happen with the rest of the tasks
                    const prioritizedCategories = categoriesWithinTime.filter(category => category.prioritize !== false);
                    const categoryTasks = getSortedTasks(
                        prioritizedCategories.map((category) => category.categoryId && tasksByCategory[category.categoryId] ? tasksByCategory[category.categoryId] : []).flat(1),
                        sortEntries,
                        today,
                        goals
                    );

                    for (let unplannedTaskIndex = 0; unplannedTaskIndex < categoryTasks.length; unplannedTaskIndex++) {
                        const unplannedTask = categoryTasks[unplannedTaskIndex];
                        if (unplannedTask.duration && unplannedTask.duration <= duration) {  
                            addToTodaysTasks(i+1, setScheduledTime(unplannedTask, today, calculateTimeFromMinutes(thisStartTime)));
                            // Because we merge tasks from this map inside for loop we need to remove it from the map for next for loop iteration. That way it isnt picked again
                            const taskCategory = unplannedTask.category ? unplannedTask.category : 'None';
                            const taskByCategoryIndex = tasksByCategory[taskCategory].findIndex((task) => task.id === unplannedTask.id);
                            tasksByCategory[taskCategory].splice(taskByCategoryIndex, 1);
                            slotFilled = true;
                            break;
                        }
                    }
                }
            }

            if (!slotFilled) {
                for (let unplannedTaskIndex = 0; unplannedTaskIndex < prioritizedTasks.length; unplannedTaskIndex++) {
                    const unplannedTask = prioritizedTasks[unplannedTaskIndex];
                    // TODO prolly a better way to go about this, this is so that we can ignore restricted category tasks
                    let ignoreTask = false;
                    // Handle unprioritized but restricted category tasks
                    if (unplannedTask.category) {
                        // Find the category to check if its restricted or not
                        const category = categories.find((c) => c.categoryId === unplannedTask.category);
                        if (category && category.restrict !== false) {
                            // If so, is the category scheduled during the time and does it fit
                            if (categoriesWithinTime.some(category => category.categoryId === unplannedTask.category) && unplannedTask.duration && unplannedTask.duration <= duration) {
                                prioritizedTasks.splice(unplannedTaskIndex, 1);
                                addToTodaysTasks(i+1, setScheduledTime(unplannedTask, today, calculateTimeFromMinutes(thisStartTime)));
                                slotFilled = true;
                                // Need to remove it also from category tasks (TODO I think? I dont actually know if this is necessary)
                                const taskByCategoryIndex = tasksByCategory[unplannedTask.category].findIndex((task) => task.id === unplannedTask.id);
                                tasksByCategory[unplannedTask.category].splice(taskByCategoryIndex, 1);
                                break;
                            } else {
                                // If not ignore it for now
                                ignoreTask = true;
                            }
                        }
                    }

                    if (!ignoreTask && unplannedTask.duration && unplannedTask.duration <= duration) {
                        prioritizedTasks.splice(unplannedTaskIndex, 1);
                        addToTodaysTasks(i+1, setScheduledTime(unplannedTask, today, calculateTimeFromMinutes(thisStartTime)));
                        slotFilled = true;
                        break;
                    }
                }
            }
        }
    }

    return {
        todaysTasks,
        breakfastAssociatedTaskId,
        lunchAssociatedTaskId,
        dinnerAssociatedTaskId
    };
}

export function getTodaysTasks(tasks: Task[], today: string) {
    const array = tasks.filter(task => isScheduledTaskToday(task, today));
    return array;
}