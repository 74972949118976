import { TextField } from '@mui/material';
import { DataStore } from 'aws-amplify/datastore';
import { ReleaseNotes } from 'models';
import React, { useCallback, useEffect, useState } from 'react';
import EditButton from './EditButton';
import { updateReleaseNotes } from 'helpers/DataStoreHelper';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

type Props = {
    releaseNotes: ReleaseNotes,
    date: string,
    canEdit: boolean,
    pushError: (error: string) => void
}

export default function ReleaseNoteComponent({ releaseNotes, date, canEdit, pushError }: Props) {
    const [ isEditing, setIsEditing ] = useState(false);
    const [ currentDescription, setCurrentDescription ] = useState<string>(releaseNotes && releaseNotes.description
        ? releaseNotes.description
        : 'Below issues have been released this month.');

    useEffect(() => setCurrentDescription(releaseNotes && releaseNotes.description
        ? releaseNotes.description
        : 'Below issues have been released this month.'),
    [releaseNotes]);

    const submitNewReleaseNotes = useCallback(() => {
        const newReleaseNotes = {
            description: currentDescription,
            date
        };
        const newReleaseObj = new ReleaseNotes(newReleaseNotes);
        DataStore.save(newReleaseObj)
            .then(() => console.log('successfully saved release notes'))
            .catch(() => console.error('error saving issues'));
    }, [ currentDescription ]);

    return <>
        <EditButton
            isEditing={isEditing}
            canEdit={canEdit}
            onEdit={() => setIsEditing(true)}
            onSave={() => {
                setIsEditing(false);
                if (releaseNotes && releaseNotes.id) {
                    updateReleaseNotes(releaseNotes.id, { ...releaseNotes, description: currentDescription }, pushError);
                } else {
                    submitNewReleaseNotes();
                }
            }}
        />
        {isEditing ?
            <TextField
                variant="standard"
                style={{ width: '100%', marginLeft: '15px' }}
                value={currentDescription}
                onChange={(event) => setCurrentDescription(event.target.value)}
                multiline
                rows={4} />
            : <div style={{textAlign: 'left', fontSize: '16px', marginLeft: '15px'}}>
                <ReactMarkdown>
                    {currentDescription}
                </ReactMarkdown>
            </div>}
    </>;
}