
const ALPHABET_SIZE = 26;

// https://medium.com/whisperarts/lexorank-what-are-they-and-how-to-use-them-for-efficient-list-sorting-a48fc4e7849f
export function generateTaskRank(rank1: string|null|undefined, rank2: string|null|undefined) {
    rank1 = rank1 ? rank1 : 'aaa';
    rank2 = rank2 ? rank2 : 'zzz';

    // Validate string length is the same (or make it the same not sure if thats necessary)
    while (rank1.length != rank2.length) {
        if (rank1.length > rank2.length) {
            rank2 += 'z';
        } else {
            rank1 += 'a';
        }
    }

    const rankDiff = getRankDiff(rank1, rank2);

    // Something went wrong if rank2 is less than rank1
    if (rankDiff < 0) {
        return null;
    }

    let newString = '';
    // For each character, get the average and add the new char to the string
    /*for (var index = 0; index < rank1.length; index++ ) {
        const charCode1 = rank1.charCodeAt(index);
        const charCode2 = rank2.charCodeAt(index);

        const newCode = (charCode1 + charCode2) / 2;
        newString += String.fromCharCode(newCode);
    }*/

    // TODO originally this was <=, did I mess it up?
    // If the diff is the same just add a new char
    if (rankDiff === 0) {
        /// add middle char from alphabet
        // TODO should probably make sure ALPHABET_SIZE / 2 is an int
        newString = rank1 + String.fromCharCode('a'.charCodeAt(0) + ALPHABET_SIZE / 2);
    } else {
        const diffToAdd = rankDiff / 2;
        // This variable is to keep track in case the new element needs to go up one place
        // If the new code is greater than z than should set it to the remainder and add
        // this offset will be added to the next place
        let offset = 0;

        for (let place = 0; place < rank1.length; place++) {
            // formula: x = difference / (size^place - 1) % size;
            // where size is size of alphabet
            // i.e. difference = 110, size = 10, we want place 2 (middle),
            // then x = 110 / 10^(2 - 1) % 10 = 110 / 10 % 10 = 11 % 10 = 1
            const diffInSymbols = diffToAdd / Math.pow(ALPHABET_SIZE, place) % (ALPHABET_SIZE);
            
            // Get the rank 1 char code and add the diff + offset
            const rank1CharCode = rank1.charCodeAt(rank1.length - place - 1);
            let newElementCode = rank1CharCode + Math.floor(diffInSymbols) + offset;
            
            // Reset offset after it has been added
            offset = 0;

            /// if newElement is greater then 'z', carry over place
            if (newElementCode > 'z'.charCodeAt(0)) {
                offset++;
                newElementCode -= ALPHABET_SIZE;
            }
            
            newString += String.fromCharCode(newElementCode);
        }

        // Reverse string since we add to string in reverse
        newString = newString.split('').reverse().join('');
    }

    // If the new String is the same as rank1 or rank2, we need to add an extra char
    if (newString === rank1 || newString === rank2) {
        const newCode = ('a'.charCodeAt(0) + 'z'.charCodeAt(0)) / 2;
        newString += String.fromCharCode(newCode);
    }

    return newString;
}

// rank2 - rank1
// https://medium.com/whisperarts/lexorank-what-are-they-and-how-to-use-them-for-efficient-list-sorting-a48fc4e7849f
export function getRankDiff(rank1: string, rank2: string) {
    // Get a list of charCodes for each string
    const firstCodes = [];
    const secondCodes = [];
    for (let index = 0; index < rank1.length; index++) {
        firstCodes.push(rank1.charCodeAt(index));
        secondCodes.push(rank2.charCodeAt(index));
    }

    let difference = 0;
    for (let i = firstCodes.length - 1; i >= 0; i--) {
        /// Codes of the elements of positions
        const firstCode = firstCodes[i];
        let secondCode = secondCodes[i];

        // i.e. ' a < b '
        // If the second code is less and we arent in last digit carry over a digit
        if (secondCode < firstCode && i > 0) {
            /// ALPHABET_SIZE = 26 for now
            secondCode += ALPHABET_SIZE;
            secondCodes[i - 1] -= 1;
        }

        // formula: x = a * size^0 + b * size^1 + c * size^2
        // Since the rank is base 26, gets the appropriate place value
        const powRes = Math.pow(ALPHABET_SIZE, rank1.length - i - 1);
        // Add diff * place value to total difference
        difference += (secondCode - firstCode) * powRes;
        //console.log(`Difference at place ${i} for ranks ${rank1} and ${rank2}: ${difference}`);
    }

    return difference;
}

// https://medium.com/whisperarts/lexorank-what-are-they-and-how-to-use-them-for-efficient-list-sorting-a48fc4e7849f
export function generateRanks(numberOfTasks: number) {
    // startPos and endPos need to be the same length
    const startPos = 'aaa';
    const endPos = 'zzz';
    const startCode = startPos.charCodeAt(0);
    const endCode = endPos.charCodeAt(0);
    const diffInOneSymb = endCode - startCode;

    // x = a + b * size + c * size^2
    // TODO why do we use just the first charCode for this?
    // Shouldnt it be each position?
    const totalDiff = diffInOneSymb + diffInOneSymb * ALPHABET_SIZE + diffInOneSymb * ALPHABET_SIZE * ALPHABET_SIZE;
    
    // Average out the diff between all tasks
    const diffForOneItem = Math.floor(totalDiff / (numberOfTasks + 1));

    /// x = difference / size^(place - 1) % size
    const diffForSymbols = [
        diffForOneItem % ALPHABET_SIZE,
        Math.floor(diffForOneItem / ALPHABET_SIZE) % ALPHABET_SIZE,
        Math.floor(diffForOneItem / (Math.pow(ALPHABET_SIZE, 2))) % ALPHABET_SIZE
    ];

    // Keeps track of all of the ranks
    const rankResults = [];
    let lastAddedElement = startPos;

    // For each task, add the diff to the last added element
    for (let ind = 0; ind < numberOfTasks; ind++) {
        // This variable is to keep track in case the new element needs to go up one place
        // If the new code is greater than z than should set it to the remainder and add
        // this offset will be added to the next place
        let offset = 0;
        let currentTaskRank = '';
        // For each place add diff
        for (let index = 0; index < startPos.length; index++) {
            const diffInSymbols = diffForSymbols[index];
            const lastElementCharCode = lastAddedElement.charCodeAt(startPos.length - 1 - index);
            let newElementCode = lastElementCharCode + diffInSymbols;
            
            if (offset != 0) {
                newElementCode += 1;
                offset = 0;
            }

            /// if new code is greater than z we need to carry it over
            if (newElementCode > 'z'.charCodeAt(0)) {
                offset += 1;
                newElementCode -= ALPHABET_SIZE;
            }

            const symbol = String.fromCharCode(newElementCode);
            currentTaskRank += symbol;
        }

        /// reverse element cuz we are calculating from the end
        currentTaskRank = currentTaskRank.split('').reverse().join('');
        rankResults.push(currentTaskRank);
        lastAddedElement = currentTaskRank;
    }

    return rankResults;
}