export const taskTypes = {
    SCHEDULED: 'Scheduled',
    ASAP: 'ASAP',
    GROCERY: 'Grocery',
};

export const taskPriorities = {
    HIGH: 'High',
    MEDIUM: 'Medium',
    LOW: 'Low'
};

export const CALENDAR_TASK_OFFSET = 65;