import { Chip } from '@mui/material';
import { testIds } from 'constants/TestConstants';
import { Category } from 'models';
import React from 'react';

type Props = {
    categoryId: string | undefined | null,
    categories: Category[]
}

export default function CategoryChip({ categoryId, categories }: Props) {
    if (categoryId && categories) {
        const thisCategory = categories.filter((category) => category.categoryId === categoryId)[0];
        return thisCategory ? <Chip sx={{ backgroundColor: thisCategory.color }} label={thisCategory.name} data-testid={testIds.categoryChip}/> : <></>;
    } else {
        return <></>;
    }
}