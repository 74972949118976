import { Button, Card, CardActionArea, LinearProgress, List } from '@mui/material';
import { DataStore } from 'aws-amplify/datastore';
import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';
import GoalListItem from './GoalListItem';
import AddIcon from '@mui/icons-material/Add';
import { Goal, Preference, Task } from 'models';
import { getFirstDayOfThisMonth, getFirstDayOfThisYear, getThisDayOfWeek } from 'helpers/TimeHelper';
import { updateGoal } from 'helpers/DataStoreHelper';
import { useRef } from 'react';
import { GoalType } from 'models';
import { testIds } from 'constants/TestConstants';
import { isTaskComplete } from 'helpers/TaskHelpers';
import { TasksById } from 'models/CommonTypes';
import { GoalUpdates } from 'models/DataStoreUpdateTypes';

type Props = {
    goals: Goal[],
    username: string,
    pushError: (error: string) => void,
    type: GoalType,
    tasks: Task[],
    tasksById: TasksById,
    today: string,
    preferences: Preference,
    completeTask: (taskId: string) => void
}

type GoalUpdatesById = {
    [id: string]: GoalUpdates
}

export default function GoalComponent({ goals, username, pushError, type, tasks, tasksById, today, preferences, completeTask }: Props) {
    const [ currentGoals, setCurrentGoals ] = useState(goals);
    const goalUpdates = useRef<GoalUpdatesById>({});
    const [ unsaved, setUnsaved ] = useState(false);

    useEffect(() => {
        setCurrentGoals(goals);
    }, [ goals ]);

    const getCorrectDate = useCallback(() => {
        if (type === GoalType.DAY) {
            return today;
        } else if (type === GoalType.WEEK) {
            return getThisDayOfWeek(today, preferences.startOfWeek);
        } else if (type === GoalType.MONTH) {
            return getFirstDayOfThisMonth(today);
        } else {
            return getFirstDayOfThisYear(today);
        }
    }, [ type, today, preferences ]);

    const addGoal = useCallback(() => {
        const newGoal = new Goal({
            owner: username,
            type,
            date: getCorrectDate(),
            description: ''
        });
        DataStore.save(newGoal).catch((error) => pushError(error));

        const newCurrentGoals = [ ...currentGoals ];
        newCurrentGoals.push(newGoal);
        setCurrentGoals(newCurrentGoals);
    }, [ currentGoals, setCurrentGoals, type ]);

    const updateGoalLocal = useCallback((id: string, newGoalUpdates: GoalUpdates) => {
        const newUpdates = { ...goalUpdates.current };
        newUpdates[id] = {
            ...newUpdates[id],
            ...newGoalUpdates
        };
        goalUpdates.current = newUpdates;
        setUnsaved(true);
    }, [ ]);

    const deleteGoal = useCallback((id: string) => {
        const newCurrentGoals = [ ...currentGoals ].filter((goal) => goal.id !== id);
        setCurrentGoals(newCurrentGoals);
        DataStore.delete(Goal, goal => goal.id.eq(id))
            .then((response) => console.log(response))
            .catch((error) => pushError(error));
    }, [ currentGoals, setCurrentGoals ]);

    return <div data-testid={testIds.goalComponent}>
        <List>
            {unsaved && <><Button
                style={{backgroundColor: 'green'}}
                onClick={() => {
                    Object.keys(goalUpdates.current).forEach((goalId) => {
                        updateGoal(goalId, goalUpdates.current[goalId], pushError);
                    });
                    setUnsaved(false);
                    goalUpdates.current = {};
                }}
            >
                save
            </Button><br/></>}
            {currentGoals.map((goal) =>
                <Card key={goal.id} style={{ margin: '5px', padding: '15px' }}>
                    <LinearProgress variant="determinate" color="primary"
                        value={goal.associatedTasks ?
                            ((goal.associatedTasks.filter((taskId) => taskId && isTaskComplete(tasksById[taskId], today)).length / goal.associatedTasks.length) * 100)
                            : 100}
                    />
                    <GoalListItem
                        goal={goal}
                        updateGoal={(newGoal) => updateGoalLocal(goal.id, newGoal)}
                        deleteFunc={() => deleteGoal(goal.id)}
                        tasks={tasks}
                        tasksById={tasksById}
                        today={today}
                        completeTask={completeTask}
                    />
                </Card>
            )}
        </List>
        <Card style={{ width: 250, padding: 5, margin: 5, textAlign: 'center', display: 'inline-block'}}>
            <CardActionArea
                onClick={addGoal}
                style={{ minWidth: 250 }}
            >
                <AddIcon style={{ position: 'relative', top: '50%' }} fontSize="large" />
            </CardActionArea>
        </Card>
    </div>;
}