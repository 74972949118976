import React, { ErrorInfo, ReactNode } from 'react';

interface Props {
    children?: ReactNode;
}
  
interface State {
    error?: Error|null;
    errorInfo?: ErrorInfo|null;
}

export class ErrorBoundary extends React.Component {
    constructor(props: Props) {
        super(props);
        this.state = { error: null, errorInfo: null } as State;
    }
    
    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        });
        // You can also log error messages to an error reporting service here
    }
    
    render() {
        const { errorInfo, error } = this.state as State;
        const { children } = this.props as Props;

        if (errorInfo) {
        // Error path
            return (
                <div>
                    <h2>Something went wrong.</h2>
                    <details style={{ whiteSpace: 'pre-wrap' }}>
                        {error && error.toString()}
                        <br />
                        {errorInfo.componentStack}
                    </details>
                </div>
            );
        }
        // Normally, just render children
        return children;
    }  
}
  