import { Card } from '@mui/material';
import { testIds } from 'constants/TestConstants';
import { Category } from 'models';
import React from 'react';

type Props = {
    children: string | JSX.Element | JSX.Element[],
    category: Category
}

export default function CategoryWrapper({ children, category }: Props) {

    return <Card
        style={{
            display: 'inline-block',
            justifyContent: 'center',
            verticalAlign: 'top',
            backgroundColor: category && category.color ? category.color : '',
            margin: 5
        }}
        data-testid={testIds.categoryWrapper}
    >
        <div style={{ display: 'block', width: '100%', maxHeight: '200px' }}>
            <h4>{category ? category.name : ''}</h4>
            <br/>
        </div>
        {children}
    </Card>;
}