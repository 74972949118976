import React, { useCallback } from 'react';
import { Button } from '@mui/material';

type Props = {
    isMod: boolean
}

export function PullTaskButton({ isMod }: Props) {
    const pullTask = useCallback(() => {
        console.log('pull task');
    }, []);

    if (isMod) {
        return <Button 
            style={{width: '100px', display: 'inline-block', float: 'right', textAlign:'right'}}
            onClick={pullTask}
        >
            Pull Task
        </Button>;
    } else {
        return <></>;
    }
}