import React from 'react';
import ReactMarkdown from 'react-markdown';
import { TextField } from '@mui/material';

type Props = {
    isEditing: boolean,
    current: string,
    setCurrent: (newCurrent: string) => void,
    label?: string,
    multiline?: boolean
};

export function EditableMarkdown({ isEditing, current, setCurrent, label, multiline = true }: Props) {
    return isEditing ? 
        <TextField
            variant="standard"
            style={{ width: '90%', margin: '5px' }}
            label={label}
            value={current}
            onChange={(event) => setCurrent(event.target.value)}
            multiline={multiline}
            rows={4} />
        : <div style={{ textAlign: 'left', fontSize: '16px', paddingLeft: '50px', overflowWrap: 'break-word' }}>
            <ReactMarkdown>{current}</ReactMarkdown>
        </div>;
}