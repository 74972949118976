import { Button, TableCell, TableRow } from '@mui/material';
import { getTaskColor } from 'helpers/ColorHelper';
import { isSmallDevice } from 'helpers/StyleHelper';
import { isTaskComplete } from 'helpers/TaskHelpers';
import { Task } from 'models';
import React, { useMemo } from 'react';

export type Props = {
    task: Task,
    today: string,
    screenWidth: number,
    completeLocalTask: (id: string, complete: boolean) => void
}

export default function PastTask({ task, today, screenWidth, completeLocalTask }: Props) {
    const completed = useMemo(() => isTaskComplete(task), [task]);
    
    return (<TableRow
        style={{backgroundColor: getTaskColor(task, today)}}
        key={task.id}
    >
        <TableCell>{task.taskName}</TableCell>
        <TableCell align="right">{task.scheduledDate}</TableCell>
        { !isSmallDevice(screenWidth) && <TableCell align="right">{task.scheduledTime}</TableCell>}
        <TableCell align="right">
            <Button
                variant="outlined"
                onClick={() => completeLocalTask(task.id, !completed)}
            >
                {completed ? 'Uncomplete' : 'Complete'}
            </Button>
        </TableCell>
    </TableRow>);
}