import React from 'react';
import 'App.css';
import { useErrorHook } from 'common/ErrorHook';
import { ErrorPopUps } from 'common/ErrorPopUps';
import { useHistory } from 'react-router-dom';
import { AppBar, Grid, Toolbar, Typography } from '@mui/material';
import { AppBarProfile } from 'common/AppBarProfile';
import { BarChart, CalendarToday, ListAlt } from '@mui/icons-material';
import { isSmallDevice } from 'helpers/StyleHelper';
import AccountManagementCard from 'accountManagement/AccountManagementCard';
import { testIds } from 'constants/TestConstants';

type Props = {
    username: string,
    triggerAuth: () => void
}

export default function InfoPage({ username, triggerAuth }: Props) {
    const { errors, pushError, cancelError } = useErrorHook([]);
    const history = useHistory();
    const fontSize = isSmallDevice(document.documentElement.clientWidth) ? '15px' : '25px';
    
    if (username) {
        history.push('/');
    }

    const mainPageGridItem = <Grid item xs={6}>
        <h1><span style={{ color: '#5BD5D6' }}>Scry</span> Portal</h1>
        <div style={{ fontSize: '25px' }}>
            Manage your life, <span style={{color: '#5BD5D6'}}>plot your future.</span><br/>
        </div>
    </Grid>;

    const loginGridItem = <Grid item xs={isSmallDevice(document.documentElement.clientWidth) ? 12 : 6} data-testid={testIds.accountManagementCard}>
        <AccountManagementCard
            triggerAuth={triggerAuth}
            pushError={pushError}
        />
    </Grid>;

    return <div className="Info">
        <Typography>
            <header className="Info-header">
                { errors && <ErrorPopUps errors={errors} cancelError={cancelError} /> }
                <AppBar
                    position="fixed"
                    style={{backgroundColor: '#303538', maxWidth:'100%'}}
                >
                    <Toolbar>
                        <AppBarProfile isBetaUser={false} triggerAuth={triggerAuth} submitAnIssue={true} username={username}/>
                    </Toolbar>
                </AppBar>
                <Toolbar/>
                {isSmallDevice(document.documentElement.clientWidth) ?
                    <>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            alignContent="center"
                        >
                            {mainPageGridItem}
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            alignContent="center"
                        >
                            {loginGridItem}
                        </Grid>
                    </>
                    : <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        alignContent="center"
                    >
                        {mainPageGridItem}
                        {loginGridItem}
                    </Grid>
                }
            </header>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                style={{ minHeight: '25vh' }}
            >
                <Grid item xs={4}>
                    <ListAlt/>
                    <h2>Manage your goals</h2>
                    <div style={{ fontSize: fontSize }}>
                    Manage the things you want to accomplish.<br/>
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <CalendarToday/>
                    <h2>Plan your time</h2>
                    <div style={{ fontSize: fontSize }}>
                    Automatically schedule your day based on your tasks and preferences.
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <BarChart/>
                    <h2>Analyze your life</h2>
                    <div style={{ fontSize: fontSize }}>
                    Keep track of your progress and accomplishments.<br/>
                    </div>
                </Grid>
            </Grid>
            <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/zSJ_MOVPbbk?si=J3ltIgyNzM7slEet"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen></iframe>
        </Typography>
    </div>;
}