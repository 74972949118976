import { AppBar, Box, Button, Card, Chip, CircularProgress, MenuItem, Select, TextField, Typography } from '@mui/material';
import React, { useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import { UpvoteButton } from './common/UpvoteButton';
import { AppBarProfile } from 'common/AppBarProfile';
import { upvoteIssue } from './common/IssueCommonFunctions';
import { Issue } from 'models';
import { DataStore } from 'aws-amplify/datastore';
import CompleteButtons from './common/CompleteButtons';
import ModeratorButtons from './common/ModeratorButtons';
import { issueTags, issueTypes } from 'constants/IssueConstants';
import { PullTaskButton } from './common/PullTaskButton';
import EditButton from './common/EditButton';
import { useErrorHook } from 'common/ErrorHook';
import { ErrorPopUps } from 'common/ErrorPopUps';
import { useMemo } from 'react';
import { DeleteButton } from 'common/DeleteButton';
import { testIds } from 'constants/TestConstants';
import { updateIssue } from 'helpers/DataStoreHelper';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { EditableMarkdown } from 'common/EditableMarkdown';

export default function IndividualIssue({ ...props }) {
    const params = new URLSearchParams(window.location.search);
    const type = params.get('type');
    const id = params.get('id');
    const [ issue, setIssue ] = useState({});
    const [ loading, setLoading ] = useState(true);
    const [ isEditing, setIsEditing ] = useState(false);
    const { errors, pushError, cancelError } = useErrorHook([]);

    const [ currentComment, setCurrentComment ] = useState('');
    const [ currentDescription, setCurrentDescription ] = useState('');
    const [ currentTitle, setCurrentTitle ] = useState('');
    const [ currentType, setCurrentType ] = useState('');
    const [ currentTags, setCurrentTags ] = useState([]);

    const history = useHistory();

    const { username, isMod, triggerAuth, isBetaUser } = props;
    const color = useMemo(() => issue && issue.completed ? '#E3FCBF' : 'paper', [ issue ]);
    const isCurrentUser = useMemo(() => issue && issue.user === username, [ issue, username ]);

    useEffect(() => {
        DataStore.query(Issue, id)
            .then((response) => {
                console.log(response);
                setIssue(response);
                setCurrentTitle(response.title);
                setCurrentDescription(response.description);
                setCurrentType(response.type);
                setCurrentTags(response.tags || []);
                setLoading(false);
            }).catch((error) => {
                pushError(error);
                setLoading(true);
            });
    }, []);

    const upvote = useCallback(() => {
        const newIssue = upvoteIssue({ ...issue }, username);
        updateIssue(newIssue.id, newIssue, pushError);
        setIssue({ ...newIssue });
    }, [username, issue, type]);

    const deleteTag = useCallback((index) => {
        const newTags = [ ...currentTags ];
        newTags.splice(index, 1);
        setCurrentTags(newTags);
    }, [ currentTags, setCurrentTags ]);

    const addTag = useCallback((tag) => {
        const newTags = [ ...currentTags ];
        newTags.push(tag);
        setCurrentTags(newTags);
    }, [ currentTags, setCurrentTags ]);

    const approve = useCallback(() => {
        const newIssue = { ...issue };
        newIssue.approved = true;
        updateIssue(newIssue.id, { approved: true }, pushError);
        setIssue(newIssue);
    }, [issue, type]);

    const setComplete = useCallback((shouldComplete) => {
        const newIssue = { ...issue };
        const issueUpdate = { completed: shouldComplete, dateCompleted: new Date().toISOString() };
        updateIssue(newIssue.id, issueUpdate, pushError);
        setIssue({ ...newIssue, ...issueUpdate });
    }, [issue, id, type]);

    const decline = useCallback(() => {
        DataStore.delete(Issue, post => post.id.eq(issue.id))
            .then((response) => {
                console.log(response);
                history.push('/issues/moderate');
            })
            .catch((error) => pushError(error));
    }, [issue, id, type]);

    const comment = useCallback(() => {
        const oldComments = issue.comments ? issue.comments : [];
        const newComments = [ ...oldComments, { username, comment: currentComment} ];
        const newIssue = { ...issue, comments: newComments };
        setCurrentComment('');
        updateIssue(newIssue.id, newIssue, pushError);
        setIssue({ ...newIssue });
    }, [issue, type, username, currentComment]);

    const renderTagChip = useCallback((tag, index) => {
        if (isEditing) {
            return <Chip
                style={{ backgroundColor: color }}
                key={tag}
                label={tag}
                onDelete={() => deleteTag(index)}
                data-testid={testIds.chip}
            />;
        } else {
            return <Chip
                style={{ backgroundColor: color }}
                key={tag}
                label={tag}
                data-testid={testIds.chip}
            />;
        }
    }, [ isEditing, currentTags ]);

    if (loading) {
        return <div className="App">
            <header className="App-header">
                <CircularProgress />
            </header>
        </div>;
    }

    if (!issue) {
        return <div className="App">
            <header className="App-header">
                Issue does not exist.
            </header>
        </div>;
    }

    if (!issue.approved && !isMod && !isCurrentUser) {
        return <div className="App">
            <header className="App-header">
                Issue being reviewed.
            </header>
        </div>;
    }

    return <div className="App">
        <Typography>
            <header className="App-header">
                <AppBar position="fixed" style={{ top: 0 }}>
                    <Box style={{ position:'absolute', left:10 }}>
                        <Button onClick={() => history.push('/')} color="primary" variant="contained">
                            Home
                        </Button>
                    </Box>
                    <AppBarProfile username={username} isBetaUser={isBetaUser} triggerAuth={triggerAuth}/>
                </AppBar>
                {!issue.approved &&
                    <Alert severity="error">Issue under review by moderator. This may take up to a few days.</Alert>
                }
                {issue.completed &&
                    <Alert severity="success">Issue has been completed! If you are still having issues please submit a new issue that links to this one.</Alert>
                }
                <ErrorPopUps errors={errors} cancelError={cancelError} />
                <Card style={{ width: '80%', marginTop: '50px', backgroundColor: color }}>
                    <Button color="primary" onClick={() => history.push('/issues')} style={{float:'left'}}>
                        Back to Issues
                    </Button>
                    <EditButton
                        isEditing={isEditing}
                        canEdit={isCurrentUser}
                        onEdit={() => setIsEditing(true)}
                        onSave={() => {
                            setIsEditing(false);
                            updateIssue(issue.id, {
                                ...issue,
                                description: currentDescription,
                                title: currentTitle,
                                type: currentType,
                                tags: currentTags
                            }, pushError);
                        }}
                    />
                    <UpvoteButton issue={issue} username={username} upvote={upvote} />
                    <DeleteButton deleteFunc={decline} hide={!isMod && !isCurrentUser} confirm={true} />
                    <CompleteButtons isComplete={issue.completed} isMod={isMod} setComplete={setComplete}/>
                    <ModeratorButtons isApproved={issue.approved} isMod={isMod} approve={approve} decline={decline} />
                    <PullTaskButton isMod={isMod}/>
                    {isEditing ? 
                        <TextField
                            variant="standard"
                            style={{ width: '100%', margin: '5px' }}
                            value={currentTitle}
                            onChange={(event) => setCurrentTitle(event.target.value)}
                            multiline
                            rows={4} />
                        : <h1>{currentTitle}</h1>}
                    {isEditing && <Select
                        variant="standard"
                        value={''}
                        data-testid={testIds.selector}
                        onChange={(event) => {
                            event.preventDefault();
                            addTag(event.target.value);
                        }}>
                        {Object.values(issueTags).map((tag) =>
                            <MenuItem key={tag} value={tag}>{tag}</MenuItem>)}
                    </Select>}
                    {currentTags.map((tag, index) => renderTagChip(tag, index))}
                    {isEditing ?
                        <Select
                            variant="standard"
                            value={currentType}
                            data-testid={testIds.selector}
                            onChange={(event) => {
                                setCurrentType(event.target.value);
                            }}>
                            <MenuItem value={issueTypes.BUG}>Bug</MenuItem>
                            <MenuItem value={issueTypes.FEATURE}>Feature</MenuItem>
                        </Select>
                        : <Chip
                            label={currentType}
                            data-testid={testIds.chip}
                        />
                    }
                    <EditableMarkdown
                        isEditing={isEditing}
                        current={currentDescription}
                        setCurrent={setCurrentDescription}
                    />
                </Card>
                <h1>Comments</h1>
                {issue.comments && issue.comments.map((comment, index) => (
                    <Card key={'comment' + index} style={{ width: '80%', marginBottom: '30px', textAlign: 'left' }}>
                        <h4 style={{ margin:5 }}>{comment.username}</h4>
                        <div style={{textAlign: 'left', fontSize: '16px', paddingLeft: '50px'}}><ReactMarkdown>{comment.comment}</ReactMarkdown></div>
                    </Card>
                ))}
                <Card style={{ width: '80%', marginBottom: '30px', textAlign: 'left' }}>
                    <TextField
                        variant="standard"
                        style={{ width: '100%', margin: '5px' }}
                        value={currentComment}
                        onChange={(event) => setCurrentComment(event.target.value)}
                        multiline
                        rows={4} />
                    <Button
                        style={{float: 'right'}}
                        onClick={comment}
                        disabled={!username}
                    >
                        Submit
                    </Button>
                </Card>
            </header>
        </Typography>
    </div>;
}