import { IconButton, Tooltip, Button, Modal, Card } from '@mui/material';
import { testIds } from 'constants/TestConstants';
import React, { useMemo, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { modalStyle } from 'constants/StylingConstants';

type Props = {
    deleteDisabled?: boolean,
    tooltip?: string|null,
    deleteFunc: () => void,
    confirm?: boolean,
    hide?: boolean
}

export function DeleteButton({ deleteDisabled = false, tooltip, deleteFunc, confirm = false, hide = false }: Props) {
    const [ modalOn, setModalOn ] = useState(false);

    const confirmModal = useMemo(() => <Modal
        open={true}
        onClose={close}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{maxHeight: '90%', overflow: 'auto'}} //TODO this looks odd
    >
        <Card style={modalStyle}>
            {'Are you sure you want to delete?'}
            <Button onClick={() => {
                deleteFunc();
                setModalOn(false);
            }}>Yes</Button>
            <Button onClick={() => {
                setModalOn(false);
            }}>No</Button>
        </Card>
    </Modal>, []);

    const deleteButton = useMemo(() => <IconButton
        onClick={() => {
            if (confirm) {
                setModalOn(true);
            } else {
                deleteFunc();
            }
        }}
        size="large"
        disabled={deleteDisabled}
        data-testid={testIds.deleteButton}
    >
        <DeleteIcon/>
    </IconButton>, [ deleteDisabled, deleteFunc ]);

    if (hide) {
        return <></>;
    }

    return <>
        {modalOn && confirmModal}
        {tooltip ?
            <Tooltip title={tooltip}>
                <div style={{ display: 'inline' }}>
                    {deleteButton}
                </div>
            </Tooltip>
            : deleteButton
        }
    </>;
}