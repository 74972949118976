import React, { useEffect, useState } from 'react';
import { TextField } from '@mui/material';

type Props = {
    name: string,
    label: string,
    value: string|number|null|undefined,
    onChangeEvent: (value: string) => void,
    isNumber?: boolean,
    style?: React.CSSProperties,
    error?: boolean
};

// This component makes it so that the cursor does not reset on text input
// TODO this might not be necessary?
export function InputTextField({name, label, value, onChangeEvent, isNumber=false, style={}, error=false}: Props) {
    const [ localValue, setLocalValue ] = useState(value || '');
    // If value is updated externally make sure local value is also updated
    useEffect(() => {
        if (localValue !== value) {
            setLocalValue(value || '');
        }
    }, [value]);

    return <TextField
        variant="standard"
        name={name}
        label={label}
        value={localValue}
        style={style}
        error={error}
        onChange={(event) => {
            if (isNumber && isNaN(parseInt(event.target.value))) {
                if (event.target.value === '') {
                    setLocalValue('0');
                    onChangeEvent('0');
                } else {
                    console.error(`Not able to update duration, input ${event.target.value} is not a number.`);
                }
            } else {
                setLocalValue(event.target.value);
                onChangeEvent(event.target.value);
            }
        }} />;
}