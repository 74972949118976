import { Button, Slide } from '@mui/material';
import Alert from '@mui/material/Alert';
import React, { useEffect } from 'react';

type Props = {
    unsavedChanges: boolean,
    onSave: () => void,
    disabled?: boolean
}

export function UnsavedChangesPopup({ unsavedChanges, onSave, disabled }: Props) {
    // Ctrl s will save
    useEffect(() => {
        const handler = (e: KeyboardEvent) => {
            if (e.ctrlKey && e.key === 's') {
                e.preventDefault();
                onSave();
            }
        };
        window.addEventListener('keydown', handler);
    
        return () => {
            window.removeEventListener('keydown', handler);
        };
    }, []);

    return <Slide direction="down" in={unsavedChanges}>
        <Alert severity="warning" style={{
            position: 'fixed',
            width: '370px',
            top: '10%',
            zIndex: 100,
            left: '50vw',
            marginLeft: '-185px'
        }}>
            Unsaved changes
            <Button
                disabled={disabled}
                style={{ backgroundColor: '#4CB330', marginLeft:'100px' }}
                variant="outlined"
                onClick={onSave}
            >
                Save
            </Button>
        </Alert>
    </Slide>;
}