import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { CategoryDays } from 'models/CommonTypes';

type Props = {
    boxDay: string,
    currentDays: CategoryDays|null|undefined,
    handleCategoryChange: (name: string, value: string|number|boolean|null) => void
}

export default function DayOfWeekBox({ boxDay, currentDays, handleCategoryChange }: Props) {
    return (<FormControlLabel
        control={<Checkbox
            onChange={(event) => handleCategoryChange(`day-${boxDay}`, event.target.checked)}
            value={currentDays ? currentDays[boxDay] : false}
            checked={currentDays ? currentDays[boxDay] : false}
        />}
        label={boxDay}
    />);
}
