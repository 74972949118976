import { Card, Switch } from '@mui/material';
import { getOwnedIngredientsPercent } from 'helpers/MealHelpers';
import { recipeDateComparator, recipeFavoriteComparator } from 'mealprep/Recipe/RecipeComparator';
import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';
import { useMemo } from 'react';
import MealTimeWindowPreferenceAlert from './MealTimeWindowPreferenceAlert';
import RecipeScheduleBlock, { RecipeScheduleBlockType } from './RecipeScheduleBlock';
import { Fridge, MealType, Preference, Recipe } from 'models';
import { groupByField } from 'helpers/ListHelpers';

type Props = {
    recipesResponse: Recipe[],
    fridgeResponse: Fridge,
    preferences: Preference,
    setRecipeId: (id: string|null) => void,
    mealType: MealType,
    scheduledRecipeId: string
}

type RecipeIdToOwnedPercent = {
    [index: string]: number
}

export default function MealScheduleBlock({ recipesResponse, fridgeResponse, preferences, setRecipeId, mealType, scheduledRecipeId }: Props) {
    const [ scheduleMeal, setScheduleMeal ] = useState(Boolean(scheduledRecipeId));
    const [ mostEfficient, setMostEfficient ] = useState<Recipe[]>([]);
    const [ somethingNew, setSomethingNew ] = useState<Recipe[]>([]);
    const [ favorites, setFavorites ] = useState<Recipe[]>([]);
    const [ selectedRecipeId, setSelectedRecipeId ] = useState<string|null>(scheduledRecipeId);

    const recipeIdToOwnedPercent = useMemo(() => {
        const fridgeNameToIng = fridgeResponse && fridgeResponse.ingredients
            ? groupByField(fridgeResponse.ingredients, 'name', '', true)
            : {};

        return recipesResponse
            .reduce((previous, recipe) => {
                const ownedPercent = getOwnedIngredientsPercent(recipe.ingredients, fridgeNameToIng);
                return { ...previous, [recipe.id]: ownedPercent};
            }, {} as RecipeIdToOwnedPercent);
    }, [ recipesResponse, fridgeResponse ]);

    const selectRecipe = useCallback((id: string) => {
        setRecipeId(id);
        setSelectedRecipeId(id);
    }, []);

    const unSelectRecipe = useCallback(() => {
        setRecipeId(null);
        setSelectedRecipeId(null);
    }, []);

    useEffect(() => {
        const newMostEfficient = [ ...recipesResponse ].filter((recipe) => recipe.types && (recipe.types as MealType[]).includes(mealType));
        newMostEfficient.sort(function sortNumberOfOwnedIngredients(a, b) {
            const ownedA = recipeIdToOwnedPercent[a.id];
            const ownedB = recipeIdToOwnedPercent[b.id];
            return ownedB - ownedA;
        });
        setMostEfficient(newMostEfficient);

        const newSomethingNew = [ ...recipesResponse ].filter((recipe) => recipe.types && (recipe.types as MealType[]).includes(mealType));
        newSomethingNew.sort(recipeDateComparator);
        setSomethingNew(newSomethingNew);

        const newFavorites = [ ...recipesResponse ].filter((recipe) => recipe.types && (recipe.types as MealType[]).includes(mealType));
        newFavorites.sort(recipeFavoriteComparator);
        setFavorites(newFavorites);
    }, [ recipesResponse, fridgeResponse ]);


    return <>
        <Switch defaultChecked={scheduleMeal} onChange={(event) => setScheduleMeal(event.target.checked)}/> {mealType}
        <br/>
        {scheduleMeal && recipesResponse && recipesResponse.length > 0 &&
            <>
                <h4>{mealType}</h4>
                <MealTimeWindowPreferenceAlert mealType={mealType} preferences={preferences} />
                <Card style={{width: '100%', backgroundColor:'grey', overflow:'visible'}}>
                    <RecipeScheduleBlock
                        title="Most Efficient"
                        recipes={mostEfficient}
                        selectedId={selectedRecipeId}
                        select={(id) => selectRecipe(id)}
                        unselect={unSelectRecipe}
                        recipeIdToOwnedPercent={recipeIdToOwnedPercent}
                        type={RecipeScheduleBlockType.EFFICIENT}
                    />
                    <RecipeScheduleBlock
                        title="Something New"
                        recipes={somethingNew}
                        selectedId={selectedRecipeId}
                        select={(id) => selectRecipe(id)}
                        unselect={unSelectRecipe}
                        recipeIdToOwnedPercent={recipeIdToOwnedPercent}
                        type={RecipeScheduleBlockType.NEW}
                    />
                    <RecipeScheduleBlock
                        title="Favorite"
                        recipes={favorites}
                        selectedId={selectedRecipeId}
                        select={(id) => selectRecipe(id)}
                        unselect={unSelectRecipe}
                        recipeIdToOwnedPercent={recipeIdToOwnedPercent}
                        type={RecipeScheduleBlockType.FAVORITE}
                    />
                </Card>
            </>
        }
    </>;
}