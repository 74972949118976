import { AppBar, Avatar, Box, Button, Card } from '@mui/material';
import { AppBarProfile } from 'common/AppBarProfile';
import { useErrorHook } from 'common/ErrorHook';
import { ErrorPopUps } from 'common/ErrorPopUps';
import Dailys from 'dailys/Dailys';
import { Task } from 'models';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { formatTasks } from 'tasks/taskCommon/ConfigCommon';
import { DataStore } from 'aws-amplify/datastore';
import { getUrl, uploadData } from 'aws-amplify/storage';

type Props = {
    username: string,
    isBetaUser: boolean,
    triggerAuth: (username?: string) => void
}

export default function Profile({ username, isBetaUser, triggerAuth }: Props) {
    const params = new URLSearchParams(window.location.search);
    const profileUsername = params.get('username');
    const history = useHistory();
    const [ taskResponse, setTaskResponse ] = useState();
    const { errors, pushError, cancelError } = useErrorHook([]);
    const [ image, setImage ] = useState<string|undefined>();

    useEffect(() => {
        DataStore.query(Task, t => t.archived.ne(true))
            .then((response) => setTaskResponse(formatTasks(response)))
            .catch((response) => pushError(response));

        const profileImageKey = `${profileUsername}-profilepic.png`;
        const imageUrlFromLocalStorage = window.localStorage.getItem(profileImageKey);

        if (imageUrlFromLocalStorage) {
            setImage(imageUrlFromLocalStorage);
        } else {
            // Download profile pic image on load
            // TODO need to make sure if url will expire
            getUrl({
                key: profileImageKey
            }).then((storageUrl) => {
                const myRequest = new Request(storageUrl.url);
                fetch(myRequest).then(function(response) {
                    if (response.status === 200) {
                        window.localStorage.setItem(profileImageKey, storageUrl.url.toString());
                        setImage(storageUrl.url.toString());
                    }
                }).catch((err) => pushError(err));
            }).catch((err) => pushError(err));
        }
    }, []);

    const uploadProfilePic = useCallback((files: FileList|null) => {
        if (files && files.length > 0) {
            const file = files[0];
            uploadData({
                key: `${username}-profilepic.png`,
                data: file
            });
            setImage(URL.createObjectURL(file));
        }
    }, []);

    return (
        <div className="App">
            <header className="App-header">
                { errors && <ErrorPopUps errors={errors} cancelError={cancelError} /> }
                <AppBar position="fixed" style={{ top: 0 }}>
                    <Box style={{position:'absolute', left:10}}>
                        <Button onClick={() => history.push('/')} color="primary" variant="contained">
                            Home
                        </Button>
                    </Box>
                    <AppBarProfile username={username} isBetaUser={isBetaUser} triggerAuth={triggerAuth} submitAnIssue={true} />
                </AppBar>
                <Card style={{ width: '80%', marginTop: 10 }}>
                    <div style={{margin:10}}>
                        <Avatar style={{ width: '64px', height: '64px'}} src={image}>{username.charAt(0)}</Avatar>
                        { username === profileUsername &&
                            <input
                                type="file"
                                name="profilePic"
                                style={{float: 'left'}}
                                onChange={(event) => {
                                    event.preventDefault();
                                    uploadProfilePic(event.target.files);
                                }}
                            />
                        }
                    </div>
                    Profile Page for {profileUsername}
                    <p style={{ fontSize: '16px', paddingBottom:'12px' }}>This is a work in progress</p>
                    { username === profileUsername && taskResponse &&
                        <div style={{ paddingBottom: '12px' }}>
                            <h2>Daily Streaks</h2>
                            <p style={{ fontSize: '12px', paddingBottom:'12px' }}>This only visible to you at the moment.</p>
                            <Dailys
                                tasks={taskResponse}
                                isBetaUser={isBetaUser}
                            />
                        </div>
                    }
                </Card>
            </header>
        </div>);
}