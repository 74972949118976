import { Button, Card, List, ListItem, Modal } from '@mui/material';
import { modalStyle } from 'constants/StylingConstants';
import { getCompletedColor } from 'helpers/ColorHelper';
import { Task } from 'models';
import React, { useState } from 'react';

type Props = {
    handleClose: () => void,
    associateTask: (taskId: string) => void,
    tasks: Task[],
    associatedTasks: string[]
}

export default function AssociatedTaskModal({ handleClose, associateTask, tasks, associatedTasks }: Props) {
    const [ selectedTask, setSelectedTask ] = useState<string|undefined>();

    return <Modal
        style={{display: 'flex'}}
        open={true}
        onClose={handleClose}
    >
        <Card style={modalStyle}>
            <h3>Choose task to associate with goal</h3>
            <List style={{ paddingBottom: '20px' }}>
                {tasks && tasks.map((task) => <ListItem
                    button
                    style={{ backgroundColor: getCompletedColor(Boolean(task.completed)) }}
                    key={task.id + 'blocker'}
                    selected={selectedTask === task.id}
                    onClick={() => setSelectedTask(task.id)}
                    disabled={associatedTasks.includes(task.id)}
                >
                    {task.taskName}
                </ListItem>)}
            </List>
            <div style={{ position: 'sticky', bottom: 0, background: 'white' }}>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleClose}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        if (selectedTask) {
                            associateTask(selectedTask);
                        }
                    }}
                >
                    Associate
                </Button>
            </div>
        </Card>
    </Modal>;
}