import { Card } from '@mui/material';
import { styled } from '@mui/system';


// Below are just set by eyeing what looks right
export const SettingsCard = styled(Card)(() => ({
    marginBottom: '10px',
    marginTop: '10px',
    marginRight: '10px',
    padding: '15px'
}));