import { isSmallDevice } from 'helpers/StyleHelper';

export const modalStyle : React.CSSProperties = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isSmallDevice(document.documentElement.clientWidth) ? '75%' : '50%',
    //boxShadow: 24, //not sure if this was doing anything since box shadows require more than on number
    //p: 4, //What is p lmao
    textAlign: 'center',
    maxHeight: '80%',
    overflow: 'auto',
    //paddingTop: '30px', // commenting out padding so stick is actually at bottom, not sure if padding was added for a reason
    //paddingBottom: '30px',
    minHeight: '100px'
};