import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, List, Divider, Box, Typography, Button, TextField } from '@mui/material';
import RescheduleModal from './rescheduleModal/RescheduleModal';
import { getAllHours, getCurrentDate, getDateFromString, getTimeStringFromDate } from '../helpers/TimeHelper';
import { getTodaysTasks } from './scheduling/CalendarAlgorithm';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CalendarItem from './calendarComponents/CalendarItem';
import { useErrorHook } from 'common/ErrorHook';
import { ErrorPopUps } from 'common/ErrorPopUps';
import { DEFAULT_END_TIME, DEFAULT_START_TIME } from 'constants/PreferenceConstants';
import { isSmallDevice } from 'helpers/StyleHelper';
import Alert from '@mui/material/Alert';
import ScheduleCategoryModal from './calendarComponents/ScheduleCategoryModal';
import { testIds } from 'constants/TestConstants';
import { saveTaskUpdates } from 'helpers/DataStoreHelper';
import CalendarTasks from './calendarComponents/CalendarTasks';
import CalendarCategories, { hourHeight } from './calendarComponents/CalendarCategories';
import { DraggableAreaEvent } from 'common/DraggableArea';
import { Task } from 'models';
import { TaskUpdateMap } from 'models/CommonTypes';

export default function Calendar({ ...props }) {
    const {
        tasks,
        preferences,
        today,
        setToday,
        scheduledInformation,
        recipesResponse,
        fridgeResponse,
        goals,
        username,
        isBetaUser,
        screenWidth,
        refetchTasks,
        refetchLoading
    } = props;

    const history = useHistory();
    const [ startTime, setStartTime ] = useState(getDateFromString(preferences.startTime || DEFAULT_START_TIME));
    const [ endTime, setEndTime ] = useState(getDateFromString(preferences.endTime || DEFAULT_END_TIME));
    const [ optimizedTasks, setOptimizedTasks ] = useState<Task[]>([]);
    const [ showRescheduleModal, setShowRescheduleModal ] = useState(false);
    const [ createCategoryEvent, setCreateCategoryEvent ] = useState<DraggableAreaEvent|null>(null);
    const [ localCategories, setLocalCategories ] = useState(preferences.categories || []);
    const { errors, pushError, cancelError } = useErrorHook([]);

    const timeOfDay = new Date();
    const timeOfDayMinutes = today === getCurrentDate()
        ? timeOfDay.getHours() * 60 + timeOfDay.getMinutes()
        : startTime.getHours() * 60 + startTime.getMinutes();

    useEffect(() => {
        console.log('Optimized tasks being set');
        // If there is an error we dont want to set tasks
        if (tasks && Array.isArray(tasks)) {
            setOptimizedTasks(getTodaysTasks(tasks, today));
        }
    }, [tasks, today]);

    useEffect(() => {
        if (preferences.startTime && preferences.startTime !== getTimeStringFromDate(startTime)) {
            console.log('setting start time');
            setStartTime(getDateFromString(preferences.startTime));
        }

        if (preferences.endTime && preferences.endTime !== getTimeStringFromDate(endTime)) {
            console.log('setting end time');
            setEndTime(getDateFromString(preferences.endTime));
        }
    }, [startTime, endTime, preferences.startTime, preferences.endTime]);

    const updateOptimizedTask = useCallback((taskUpdates: TaskUpdateMap, calendarTasks: Task[]) => {
        setOptimizedTasks(calendarTasks);
        saveTaskUpdates(taskUpdates, pushError, true);
    }, [ setOptimizedTasks, pushError ]);

    const handleCategoryClose = useCallback(() => {
        setCreateCategoryEvent(null);
    } , [ createCategoryEvent, setCreateCategoryEvent ]);

    // This makes it so the calendar is continuously optimized
    /*const optimizedTasks = useMemo(() => {
        return optimizeTasks(tasks, today, startTime, endTime, timeOfDayMinutes);
    }, [ tasks, today, startTime, endTime, timeOfDayMinutes ]);*/

    return <>
        { showRescheduleModal &&
            <RescheduleModal
                updateOptimizedTask={updateOptimizedTask}
                tasks={tasks}
                today={today}
                startTime={startTime}
                endTime={endTime}
                timeOfDayMinutes={timeOfDayMinutes}
                reftechLoading={refetchLoading}
                close={() => setShowRescheduleModal(false)}
                preferences={preferences}
                scheduledInformation={scheduledInformation}
                recipesResponse={recipesResponse}
                fridgeResponse={fridgeResponse}
                username={username}
                isBetaUser={isBetaUser}
                screenWidth={screenWidth}
                pushError={pushError}
                goals={goals}
                categories={localCategories}
            />
        }
        { createCategoryEvent &&
            <ScheduleCategoryModal
                handleClose={handleCategoryClose}
                event={createCategoryEvent}
                preferences={preferences}
                localCategories={localCategories}
                pushError={pushError}
                today={today}
                updateLocalCategories={setLocalCategories}
            />
        }
        { errors && <ErrorPopUps errors={errors} cancelError={cancelError} /> }
        { (!preferences.startTime || !preferences.endTime) &&
            <Alert severity={'warning'}>No start/end time set, using default of {DEFAULT_START_TIME} - {DEFAULT_END_TIME}</Alert>
        }
        <div data-testid={testIds.calendar} style={{ display: 'flex', justifyContent: 'center' }}>
            <Box
                position="relative"
                style = {{ width: '60vw', minWidth: '375px', overflow: 'hidden'}}
            >
                <Card style={{width: '100%'}}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '72px' // NOTE: To get tasks to line up with time a uniform height is required
                    }}>
                        <TextField
                            variant="standard"
                            type="date"
                            defaultValue={today}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(event) => {
                                history.push(`?date=${event.target.value}`);
                                setToday(event.target.value);
                            }}
                        />
                        {
                        // Date is too large on small screens without this
                            isSmallDevice(screenWidth) ?
                                <Typography variant="h5" component="h5" display="inline">
                                    {today}
                                </Typography>
                                : <Typography variant="h4" component="h4" display="inline">
                                    {today}
                                </Typography>
                        }
                        <Button
                            onClick={() => {
                                refetchTasks();
                                setShowRescheduleModal(true);
                            }}
                            color="primary"
                            variant="contained"
                        >
                            Schedule
                        </Button>
                    </div>
                    <List>
                        {getAllHours().map((value) => (
                            <Box
                                key={'' + value}
                                height={hourHeight}
                            >
                                <CalendarItem time={value} startTime={startTime} endTime={endTime} timeOfDay={timeOfDay} />
                                <Divider />
                            </Box>
                        ))}
                    </List>
                    <ArrowForwardIcon style = {{top: timeOfDayMinutes+60, position: 'absolute', left: 0}}/>
                    <div style={{ display: 'flex'}}>
                        <CalendarCategories
                            categoriesInput={localCategories}
                            today={today}
                            onCreate={(event: DraggableAreaEvent) => setCreateCategoryEvent(event)}
                            isBetaUser={isBetaUser}
                        />
                        <CalendarTasks
                            tasks={optimizedTasks}
                            today={today}
                            timeOfDayMinutes={timeOfDayMinutes}
                            startTime={startTime}
                            categories={preferences.categories || []}
                        />
                    </div>
                </Card>
            </Box>
        </div>
    </>;
}