import { Button, IconButton, ListItem, ListSubheader } from '@mui/material';
import { InputTextField } from 'common/InputTextField';
import React, { useMemo, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { useCallback } from 'react';
import AssociatedTaskModal from './AssociateTaskModal';
import { isTaskComplete } from 'helpers/TaskHelpers';
import { Goal, Task } from 'models';
import { TasksById } from 'models/CommonTypes';
import { notEmpty } from 'helpers/TypesHelper';
import { GoalUpdates } from 'models/DataStoreUpdateTypes';
import { testIds } from 'constants/TestConstants';

type Props = {
    goal: Goal,
    updateGoal: (updates: GoalUpdates) => void,
    deleteFunc: () => void,
    tasks: Task[],
    tasksById: TasksById,
    today: string,
    completeTask: (taskId: string) => void
}

export default function GoalListItem({ goal, updateGoal, deleteFunc, tasks, tasksById, today, completeTask }: Props) {
    const [ goalDescription, setGoalDescription ] = useState(goal.description);
    const [ associatedTasks, setAssociatedTasks ] = useState<string[]>(
        goal.associatedTasks ? goal.associatedTasks.filter(notEmpty) : []
    );
    const [ associateTaskModalOpen, setAssociateTaskModalOpen ] = useState(false);

    const addAssociatedTask = useCallback((task: string) => {
        const newTasks = [ ...associatedTasks, task ];
        setAssociatedTasks(newTasks);
        updateGoal({ associatedTasks: newTasks });
        setAssociateTaskModalOpen(false);
    }, [ associatedTasks, setAssociateTaskModalOpen, setAssociatedTasks ]);

    const deleteAssociatedTask = useCallback((task: string) => {
        const newTasks = [ ... associatedTasks ].filter(taskid => taskid !== task);
        setAssociatedTasks(newTasks);
        updateGoal({ associatedTasks: newTasks });
        setAssociateTaskModalOpen(false);
    }, [ associatedTasks, setAssociateTaskModalOpen, setAssociatedTasks ]);

    // Prolly dont need this useMemo here
    const associateTaskComponents = useMemo(() => associatedTasks.map((task) =>
        tasksById[task] &&
            <ListItem
                key={task}
                style={{ textDecorationLine: isTaskComplete(tasksById[task], today) ? 'line-through' : '', fontSize: '15px', width: '100%', display: 'inline-block', margin: '0px', padding: '0px'}}
            >
                <div style={{float: 'left'}}>
                    {isTaskComplete(tasksById[task], today) 
                        ? <s>- {tasksById[task].taskName}</s>
                        : '- ' + tasksById[task].taskName}
                </div>
                <div style={{ float: 'right' }}>
                    <Button
                        data-testid={testIds.completeButton}
                        onClick={() => completeTask(task)}>
                        ✔
                    </Button>
                    <Button onClick={() => deleteAssociatedTask(task)}>
                        x
                    </Button>
                </div>
            </ListItem>
    ), [tasksById, associatedTasks, today, completeTask]);

    return <>
        {associateTaskModalOpen &&
            <AssociatedTaskModal
                handleClose={() => setAssociateTaskModalOpen(false)}
                associateTask={(taskId) => addAssociatedTask(taskId)}
                tasks={tasks}
                associatedTasks={associatedTasks}
            />
        }
        <ListItem style={{margin: '0px', padding: '0px'}}>
            <InputTextField
                name="goalDescription"
                label="Goal Description"
                style={{width:'65%'}}
                value={goalDescription}
                onChangeEvent={(value) => {
                    setGoalDescription(value);
                    updateGoal({ description: value });
                }}
            />
            <IconButton
                style={{width: '100px', display: 'inline-block', float: 'right', textAlign:'right', color:'red'}}
                onClick={deleteFunc}
                size="large">
                <DeleteIcon/>
            </IconButton>
        </ListItem>
        <ListSubheader>Associated Tasks</ListSubheader>
        {associateTaskComponents}
        <ListItem>
            <Button style={{width:'100%'}} onClick={() => {
                setAssociateTaskModalOpen(true);
            }}>+</Button>
        </ListItem>
    </>;
}