import { notEmpty } from 'helpers/TypesHelper';
import { AmountPerTimeframe, Task, TimeframeType } from 'models';
import { TaskUpdateMap, TasksById } from 'models/CommonTypes';
import { generateTaskRank } from './TaskRankCommon';
import { TaskUpdates } from 'models/DataStoreUpdateTypes';
import { convertToDateTimes } from 'dailys/DailysCommon';

export function addTaskToParent(
    parentTask: Task,
    parentTaskIndex: number,
    task: Task,
    changeTask: (id: string, taskUpdates: TaskUpdates) => void,
    localTasks: Task[],
    taskById: TasksById,
    subTaskIndex?: number,
    taskUpdates?: TaskUpdateMap
) {
    // If subtask index isnt provided add to the beginning
    if (!subTaskIndex) {
        subTaskIndex = 0;
    }

    const taskBelowId = findTaskBelow(parentTask, parentTaskIndex, subTaskIndex, localTasks, taskById);

    // If the task below is the targeted task, then dont need to move it :)
    if (taskBelowId !== task.id) {
        moveTask(task.id, taskBelowId, localTasks, taskById, changeTask);
    }

    // Update task found to include our task in its subtasks
    const newSubtasks = parentTask.subTasks ? [ ...parentTask.subTasks.filter(notEmpty) ] : [ ];
    // If subTaskIndex is end of list push instead of splice
    if (subTaskIndex >= newSubtasks.length) {
        console.log(`task with id ${task.id} should go to end of list`);
        newSubtasks.push(task.id);
    } else {
        newSubtasks.splice(subTaskIndex, 0, task.id);
    }
    changeTask(parentTask.id, { subTasks: newSubtasks });

    // Update parent in local tasks
    const actualParentTaskIndex = localTasks.findIndex(tempTask => tempTask.id === parentTask.id);
    let parentTaskUpdate;

    // Task updates are not rendered unless they have to, since changing subtasks causes rerender we should apply all task updates locally
    if (taskUpdates && taskUpdates[parentTask.id]) {
        parentTaskUpdate = applyTaskUpdates(parentTask, taskUpdates[parentTask.id]);
        parentTaskUpdate = { ...parentTaskUpdate, subTasks: newSubtasks };
    } else {
        parentTaskUpdate = { ...parentTask, subTasks: newSubtasks };
    }

    localTasks.splice(actualParentTaskIndex, 1, parentTaskUpdate);
}

function findTaskBelow(
    parentTask: Task,
    parentTaskIndex: number,
    subTaskIndex: number,
    localTasks: Task[],
    taskById: TasksById
) {
    // should maybe just make this recursive, while loop doesnt seem to work
    for(let currentSubtaskIndex=subTaskIndex; currentSubtaskIndex <= Number.MAX_SAFE_INTEGER; currentSubtaskIndex++) {
        if (!parentTask.subTasks || parentTask.subTasks.length < 1) {
            return getTaskIdBelow(parentTaskIndex, localTasks);
        } else if (currentSubtaskIndex >= parentTask.subTasks.length) {
            const lastSubtaskId = parentTask.subTasks[parentTask.subTasks.length - 1];
            const subtaskIndex = localTasks.findIndex(tempTask => tempTask.id === lastSubtaskId);
            return getTaskIdBelow(subtaskIndex, localTasks);
        } else {
            const nextSubtaskId = parentTask.subTasks[currentSubtaskIndex];
            if (nextSubtaskId && taskById[nextSubtaskId]) {
                return nextSubtaskId;
            } else {
                console.log(`subtask with id ${nextSubtaskId} does not exist`);
            }
        }
    }
}

function getTaskIdBelow(taskAbove: number, localTasks: Task[]) {
    if (taskAbove >= localTasks.length - 1) {
        // End of list
        return null;
    } else {
        return localTasks[taskAbove + 1].id;
    }
}

// NOTE: This method assumes that we always want to insert right above the taskIdBelow
// Idk if theres some edge case to that
export function moveTask(
    taskId: string,
    taskIdBelow: string|null|undefined,
    localTasks: Task[],
    taskById: TasksById,
    changeTask: (id: string, taskUpdates: TaskUpdates) => void,
) {
    // delete old task
    const taskIndex = localTasks.findIndex((task) => task.id === taskId);
    localTasks.splice(taskIndex, 1);

    // Else just change rank
    let taskBelowRank;
    let taskAboveRank;
    let targetIndex;
    // If index is at end of list
    if (!taskIdBelow) {
        // Add to the end of the list
        taskBelowRank = null;
        taskAboveRank = localTasks[localTasks.length - 1].rank;
        targetIndex = localTasks.length;
    } else {
        taskBelowRank = taskById[taskIdBelow].rank;
        targetIndex = localTasks.findIndex((task) => task.id === taskIdBelow);
        taskAboveRank = targetIndex > 0 ? localTasks[targetIndex - 1].rank : null;
    }

    const rank = generateTaskRank(taskAboveRank, taskBelowRank);
    // add task at new location
    localTasks.splice(targetIndex, 0, { ...taskById[taskId], rank });
    if (rank) {
        changeTask(taskId, { rank });
    }
}

export function applyTaskUpdates(currentTask: Task, taskUpdates: TaskUpdates) {
    return { ...currentTask, ...taskUpdates };
}

export function timeframeToMinutes(timeframe: AmountPerTimeframe) {
    if (timeframe.timeFrameType == TimeframeType.HOUR) {
        return timeframe.timeFrameValue * 60;
    } else if (timeframe.timeFrameType == TimeframeType.MINUTE) {
        return timeframe.timeFrameValue;
    } else {
        throw 'Unknown timeframe type';
    }
}

export function setScheduledTime(task:Task, today:string, time:string) {
    if (task.daily && task.daily.enabled) {
        const dateTimes = task.daily.dateTimes ? convertToDateTimes(task.daily.dateTimes) : {};
        let result;
        if (task.daily.dateTimes) {
            result = {
                dateTimes: dateTimes[today],
                time
            };
        } else {
            result = {
                time
            };
        }
        return {
            ...task,
            daily: {
                ...task.daily,
                dateTimes: JSON.stringify({
                    ...dateTimes,
                    [today]: result,
                })
            }
        };
    } else {
        return {
            ...task,
            scheduledTime: time,
            scheduledDate: today
        };
    }
}