import { Button, Card, Modal, CircularProgress } from '@mui/material';
import { modalStyle } from 'constants/StylingConstants';
import { updateCurrentUser } from 'helpers/DataStoreHelper';
import React, { useState } from 'react';
import { useCallback } from 'react';

type Props = {
    handleClose: () => void,
    username: string,
    isBetaUser: boolean,
    pushError: (error: string) => void
}

export default function BetaModal({ handleClose, username, isBetaUser, pushError }: Props) {
    const [ loading, setLoading ] = useState(false);

    const opt = useCallback((option: boolean) => {
        updateCurrentUser({ beta: option }, username, pushError).then(() => {
            console.log(`Opting ${option ? 'in' : 'out'} beta`);
            setLoading(true);
            // TODO this is to ensure that datastore syncs before refresh, a query may be better
            setTimeout(function(){
                window.location.reload();
            }, 1000);
        }).catch((error => pushError(error)));
    }, [username]);

    if (loading) {
        return <Modal
            open={true}
            onClose={handleClose}
        >
            <Card style={modalStyle}>
                <h3>Syncing with backend.</h3>
                <div style={{ alignContent: 'center', width: '100%'}}>
                    <CircularProgress/>
                </div>
            </Card>
        </Modal>;
    }

    return <Modal
        open={true}
        onClose={handleClose}
    >
        <Card style={modalStyle}>
            <h3>Sign up for Beta?</h3>
            <p>Signing up for the beta will allow you to use the brand new features we are developing in addition to helping us catch bugs before they are deployed to the rest of the world.</p>
            <p>Note that features will often not work as intended, so sign up at your own risk and please submit any issues you may see.</p>
            <Button
                variant="contained"
                color="secondary"
                onClick={handleClose}
            >
                Cancel
            </Button>
            <Button
                variant="contained"
                color="primary"
                onClick={() => {
                    if (isBetaUser) {
                        opt(false);
                    } else {
                        opt(true);
                    }
                }}
            >
                { isBetaUser ? 'Opt Out' : 'Opt In' }
            </Button>
        </Card>
    </Modal>;
}