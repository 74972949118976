import { Task } from 'models';
import { TaskMap, TaskUpdateMap, TasksById } from 'models/CommonTypes';
import { findTaskInMapDFS, removeTaskFromParent } from './ConfigCommon';
import { Item } from 'react-nestable';
import { addTaskToParent, moveTask } from './TaskCommonFunctions';

export function onNestableChange(localTasks: Task[],
    taskMap: TaskMap,
    taskById: TasksById,
    result: {
        items: Item[];
        dragItem: Item;
        targetPath: number[];
    },
    changeTask: () => void,
    taskUpdates: TaskUpdateMap,
    pushError: (error: string) => void
) {
    const newLocalTasks = [ ...localTasks ];
    const dragItem = result.dragItem;
    const taskHeirarchy = findTaskInMapDFS(dragItem.id, taskMap);

    // If old task had a parent remove it from the parent
    if (taskHeirarchy && taskHeirarchy.length > 1) {
        const parentId = taskHeirarchy[1];
        const parentTaskIndex = newLocalTasks.findIndex((task) => task.id === parentId);
        removeTaskFromParent(dragItem.id, parentId, parentTaskIndex, taskById, changeTask, newLocalTasks);
    }

    // if new task has a parent, add it as a child
    if (result.targetPath.length > 1) {
        let newParentId: string|undefined|null;
        let currentItems = result.items;
        // Iterate through items to get the direct parent of the dragItem
        for (let i = 0; i < result.targetPath.length - 1; i++) {
            const currParent = currentItems[result.targetPath[i]];
            newParentId = currParent.id;
            currentItems = currParent.children;
        }
        
        // TODO what happens when parentid is not there
        if (newParentId) {
            const targetParentIndex = newLocalTasks.findIndex((task) => task.id === newParentId);
            const subTaskIndex = result.targetPath[result.targetPath.length - 1];
            addTaskToParent(taskById[newParentId], targetParentIndex, taskById[dragItem.id], changeTask, newLocalTasks, taskById, subTaskIndex, taskUpdates);
        } else {
            pushError('Cannot find parent task that item was dragged to.');
        }
    } else {
        const taskBelowId = result.targetPath[0] >= result.items.length - 1
            ? null : result.items[result.targetPath[0] + 1].id;
        moveTask(dragItem.id, taskBelowId, newLocalTasks, taskById, changeTask);
    }

    return newLocalTasks;
}