import { isString } from 'helpers/TextHelper';
import { calculateMinutesFromTime } from 'helpers/TimeHelper';
import { getRankDiff } from './TaskRankCommon';

export function durationComparator(a, b) {
    const aDuration = isString(a) ? parseInt(a) : a;
    const bDuration = isString(b) ? parseInt(b) : b;
    return bDuration - aDuration;
}

export function dueDateComparator(a, b) {
    if (!a || a === 'null') {
        return 1;
    }
    if (!b || b === 'null') {
        return -1;
    }
    return new Date(a) - new Date(b);
}

export function taskRankComparator(a, b) {
    // If no rank, assume its last
    var aTaskRank = a.rank ? a.rank : 'zzz';
    var bTaskRank = b.rank ? b.rank : 'zzz';

    // Make ranks equal length
    while (aTaskRank.length != bTaskRank.length) {
        if (aTaskRank.length > bTaskRank.length) {
            bTaskRank += 'a';
        } else {
            aTaskRank += 'a';
        }
    }

    return getRankDiff(bTaskRank, aTaskRank);
}

export function taskStartTimeComparator(a, b, today) {
    const startTimeMinutesA = getStartTime(a, today);
    const startTimeMinutesB = getStartTime(b, today);
    return startTimeMinutesA - startTimeMinutesB;
}

function getStartTime(task, today) {
    // Default to end of day
    let startTime = calculateMinutesFromTime('23:59');
    if (task.daily.enabled && task.daily.dateTimes[today]) {
        if (task.daily.dateTimes[today].time) {
            startTime = calculateMinutesFromTime(task.daily.dateTimes[today].time);
        }
    } else {
        if (task.scheduledTime) {
            startTime = calculateMinutesFromTime(task.scheduledTime);
        }
    }
    return startTime;
}