import React from 'react';
import { Button, ListItem } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { UpvoteButton } from './UpvoteButton';
import ModeratorButtons from './ModeratorButtons';
import { isSmallDevice } from 'helpers/StyleHelper';
import IssueTagChip from './IssueTagChip';
import { DeleteButton } from 'common/DeleteButton';
import { issueTypes } from 'constants/IssueConstants';
import { Issue } from 'models';

type Props = {
    issueType: issueTypes,
    issue: Issue,
    username: string,
    deleteIssue: () => void,
    isModerator: boolean,
    approve: () => void,
    upvote: () => void 
}

export function IssueListItem({
    issueType,
    issue,
    username,
    deleteIssue,
    isModerator,
    approve,
    upvote
}: Props) {
    const history = useHistory();

    return (
        <ListItem>
            <Button
                style={{width: '100%', display: 'inline-block', float: 'left', textAlign: 'left'}}
                onClick={() => history.push({
                    pathname: '/individualIssue',
                    search: `?type=${issueType}&id=${encodeURIComponent(issue.id)}`
                })}
            >
                <div className="long-and-truncated" style={{ fontSize: isSmallDevice(document.documentElement.clientWidth) ? '11px' : '14px' }}>
                    {issue.tags && issue.tags.map((tag) => <IssueTagChip key={tag} tag={tag ? tag : 'Unkown Tag'}/>)}
                    <b>{issue.title}</b>
                    {issue.description}
                </div>
            </Button>
            <UpvoteButton issue={issue} username={username} upvote={upvote} />
            <ModeratorButtons isApproved={Boolean(issue.approved)} isMod={isModerator} approve={approve} decline={deleteIssue} />
            <DeleteButton deleteFunc={deleteIssue} hide={!isModerator && issue.user !== username} confirm={true} />
        </ListItem>
    );
}