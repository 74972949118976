import { Button, Card, CardActionArea } from '@mui/material';
import { getSelectedColor } from 'helpers/ColorHelper';
import React, { useMemo, useState } from 'react';
import DoneIcon from '@mui/icons-material/Done';
import { getLatestDate } from 'mealprep/Recipe/RecipeComparator';
import { Recipe } from 'models';

export enum RecipeScheduleBlockType {
    EFFICIENT = 'EFFICIENT',
    NEW = 'NEW',
    FAVORITE = 'FAVORITE'
}

type RecipeIdOwnedPercentMap = {
    [index: string]: number
}

type Props = {
    title: string,
    selectedId: string|null,
    select: (recipeId: string) => void,
    unselect: () => void,
    recipes: Recipe[],
    recipeIdToOwnedPercent: RecipeIdOwnedPercentMap,
    type: RecipeScheduleBlockType
}

export default function RecipeScheduleBlock({ title, selectedId, select, unselect, recipes, recipeIdToOwnedPercent, type }: Props) {
    const [ recipesIndex, setRecipesIndex ] = useState(0);

    const contextText = useMemo(() => {
        if (type === RecipeScheduleBlockType.EFFICIENT) {
            return recipes[recipesIndex] ? recipeIdToOwnedPercent[recipes[recipesIndex].id] + '% owned' : '';
        } else if (type === RecipeScheduleBlockType.FAVORITE) {
            if (recipes[recipesIndex]) {
                const recipe = recipes[recipesIndex];
                return recipe.scheduledDays ? 'Scheduled ' + recipe.scheduledDays.length + ' times' : 'Never Scheduled';
            }
        } else if (type === RecipeScheduleBlockType.NEW) {
            if (recipes[recipesIndex]) {
                return recipes[recipesIndex].scheduledDays ? 'Last scheduled ' + getLatestDate(recipes[recipesIndex]).toDateString() : 'Never Scheduled';
            }
        } else {
            return '';
        }
    }, [type]);

    return <div style={{padding:5, margin:5, display: 'inline-block', overflow:'visible', verticalAlign: 'top'}}>
        {title}<br/>
        <div style={{display: 'flex'}}>
            <Button
                disabled={recipesIndex === 0}
                onClick={() => {
                    setRecipesIndex(recipesIndex - 1);
                    unselect();
                }}
            >
                {'<'}
            </Button>
            <Card style={{width:150, minHeight:150, verticalAlign: 'top', backgroundColor: getSelectedColor(recipes[recipesIndex] && selectedId === recipes[recipesIndex].id)}}>
                <CardActionArea
                    onClick={() => {
                        if (selectedId === recipes[recipesIndex].id) {
                            unselect();
                        } else {
                            select(recipes[recipesIndex].id);
                        }
                    }}
                    style={{ minWidth: 150, minHeight: 150 }}
                >
                    {recipes[recipesIndex] && selectedId === recipes[recipesIndex].id && <DoneIcon/>}
                    {recipes[recipesIndex] ? recipes[recipesIndex].name : 'None'}
                    <br/>
                    {contextText}
                    <br/>
                    {recipes[recipesIndex] ? 'Duration: ' + recipes[recipesIndex].duration + ' min' : ''}
                </CardActionArea>
            </Card>
            <Button
                disabled={recipes.length === 0 || recipesIndex === recipes.length - 1}
                onClick={() => {
                    setRecipesIndex(recipesIndex + 1);
                    unselect();
                }}
            >
                {'>'}
            </Button>
        </div>
    </div>;
}