import { Button, Card, CircularProgress, List, ListItemButton, Modal } from '@mui/material';
import { DataStore } from 'aws-amplify/datastore';
import { modalStyle } from 'constants/StylingConstants';
import { testIds } from 'constants/TestConstants';
import { Task } from 'models';
import React, { useEffect, useMemo, useState } from 'react';
import { convertDailys } from 'tasks/taskCommon/ConfigCommon';

type Props = {
    handleClose: () => void,
    blockTask: (taskId: string) => void,
    pushError: (error: string) => void,
    currentTaskId: string
};

export default function BlockedModal({ handleClose, blockTask, pushError, currentTaskId }: Props) {
    const [ selectedTask, setSelectedTask ] = useState<string>();
    const [ tasks, setTasks ] = useState<Task[]|undefined>();

    useEffect(() => {
        DataStore.query(Task, t => t.archived.ne(true))
            .then((response) => {
                setTasks(convertDailys(response));
            })
            .catch((response) => {
                console.error(response);
                pushError(response);
            });
    }, []);

    const modalContent = useMemo(() => { 
        if(!tasks) {
            return <div style={{width:'100%'}}><CircularProgress data-testid={testIds.circularProgress}/></div>;
        } else {
            return <div>
                <h3>Choose blocking task</h3>
                <List>
                    {tasks && tasks.map((task: Task) => <ListItemButton
                        key={task.id + 'blocker'}
                        selected={selectedTask === task.id}
                        onClick={() => setSelectedTask(task.id)}
                        disabled={task.id == currentTaskId}
                    >
                        {task.taskName}
                    </ListItemButton>)}
                </List>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleClose}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        if (selectedTask) {
                            blockTask(selectedTask);
                        }
                    }}
                >
                    Block
                </Button>
            </div>;
        }
    }, [ tasks, selectedTask ]);

    return <Modal
        open={true}
        onClose={handleClose}
    >
        <Card style={modalStyle}>
            {modalContent}
        </Card>
    </Modal>;
}