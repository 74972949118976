import React from 'react';
import 'App.css';
import MainPage from 'MainPage';
import { Redirect } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { testIds } from 'constants/TestConstants';

export default function App({ username, triggerAuth, isBetaUser, user } ) {

    if (!username) {
        return <div data-testid={testIds.redirect}>
            <Redirect to='/info'/>
        </div>;
    }

    return <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div className="App" data-testid={testIds.app}>
            <header className="App-header">
                <MainPage username={username} triggerAuth={triggerAuth} isBetaUser={isBetaUser} user={user}/>
            </header>
        </div>
    </LocalizationProvider>;
}
