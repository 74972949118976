import { Table, TableRow, TableCell, TableBody, TableHead } from '@mui/material';
import { isSmallDevice } from 'helpers/StyleHelper';
import React from 'react';
import PastTask from './PastTask';
import { Task } from 'models';

type Props = {
    pastTasks: Task[],
    completeLocalTask: (id: string, complete: boolean) => void,
    today: string,
    screenWidth: number
}

export default function PastTasks({ pastTasks, completeLocalTask, today, screenWidth }: Props) {
    return <Table>
        <TableHead>
            <TableRow>
                <TableCell>Task Name</TableCell>
                <TableCell align="right">Scheduled Date</TableCell>
                { !isSmallDevice(screenWidth) && <TableCell align="right">Scheduled Time</TableCell>}
                <TableCell align="right">Complete?</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {pastTasks.map((task) => <PastTask
                key={'past' + task.id}
                task={task}
                today={today}
                screenWidth={screenWidth}
                completeLocalTask={completeLocalTask}
            />)}
        </TableBody>
    </Table>;
}