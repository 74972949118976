import { Button, Card, Chip, MenuItem, Select, TextField } from '@mui/material';
import { InputTextField } from 'common/InputTextField';
import { IngredientUnit } from 'constants/MealConstants';
import { getIngredientColor } from 'helpers/ColorHelper';
import { calculateUnitizedAmount, getFridgeNameToAmount } from 'mealprep/Common/IngredientCommon';
import { MealType } from 'models';
import { Ingredient } from 'models';
import React, { useCallback, useMemo, useState } from 'react';
import { testIds } from 'constants/TestConstants';
import { DeleteButton } from 'common/DeleteButton';
import { EditableMarkdown } from 'common/EditableMarkdown';
import { Edit } from '@mui/icons-material';
import { ButtonSelect } from 'common/ButtonSelect';

export default function RecipePage({ recipe, fridgeIngredients, nutritionFacts, updateRecipe, deleteRecipe, pushError }) {
    const [ name, setName ] = useState(recipe.name || '');
    const [ duration, setDuration ] = useState(recipe.duration || '');
    const [ servings, setServings ] = useState(recipe.servings || 1);
    const [ types, setTypes ] = useState(recipe.types || []);
    const [ selectedType ] = useState('');
    const [ description, setDescription ] = useState(recipe.description || '');
    const [ currentIngredientName, setCurrentIngredientName ] = useState('');
    const [ currentIngredientAmount, setCurrentIngredientAmount ] = useState(1);
    const [ currentIngredientUnit, setCurrentIngredientUnit ] = useState(IngredientUnit.UNIT);
    const [ ingredients, setIngredients ] = useState(recipe.ingredients || []);
    const [ unsaved, setUnsaved ] = useState(false);
    const [ isEditing, setIsEditing ] = useState(false);

    const nutrititionInfo = useMemo(() => {
        const nameToNutritionFact = nutritionFacts.reduce((prevNutrition, currentNutrition) => {
            const nutritionFactName = currentNutrition.name ? currentNutrition.name.toLowerCase() : '';
            return { ...prevNutrition, [nutritionFactName]: currentNutrition };
        }, {});

        let totalCal = 0.0;
        let totalFat = 0.0;
        let totalProtein = 0.0;
        let totalSugar = 0.0;
        ingredients.forEach((ingredient) => {
            if (ingredient && ingredient.name && nameToNutritionFact[ingredient.name.toLowerCase()]) {
                const ingredientNutrition = nameToNutritionFact[ingredient.name.toLowerCase()];
                totalCal += calculateUnitizedAmount(ingredient, ingredientNutrition, 'calories');
                totalFat += calculateUnitizedAmount(ingredient, ingredientNutrition, 'totalFat');
                totalProtein += calculateUnitizedAmount(ingredient, ingredientNutrition, 'protein');
                totalSugar += calculateUnitizedAmount(ingredient, ingredientNutrition, 'sugar');
            }
        });

        return {
            totalCal,
            totalFat,
            totalProtein,
            totalSugar
        };
    }, [ ingredients, nutritionFacts]);

    const deleteIngredient = useCallback((index) => {
        const newIngredients = [...ingredients];
        newIngredients.splice(index, 1);
        setIngredients(newIngredients);
        setUnsaved(true);
    }, [ingredients]);

    const deleteType = useCallback((index) => {
        const newTypes = [...types];
        newTypes.splice(index, 1);
        setTypes(newTypes);
        setUnsaved(true);
    }, [ingredients]);

    const fridgeNameToAmount = useMemo(() => getFridgeNameToAmount(fridgeIngredients),
        [ fridgeIngredients ]);

    return <Card
        style={{ width:500, minHeight:500, padding:20, margin:5, display: 'inline-block', overflow:'visible', verticalAlign: 'top'}}
        data-testid={testIds.recipeComponent}
    >
        {unsaved && <><Button
            style={{backgroundColor: 'green'}}
            onClick={() => {
                updateRecipe(recipe.id, {
                    name,
                    duration,
                    ingredients,
                    types,
                    servings,
                    description
                }, pushError);
                setUnsaved(false);
            }}
        >
            save
        </Button><br/></>}
        <DeleteButton deleteFunc={deleteRecipe}/>
        <TextField
            name="name"
            label="Recipe Name"
            variant="outlined"
            value={name}
            onChange={(event) => {
                setName(event.target.value);
                setUnsaved(true);
            }}
        />
        <br/>
        <TextField
            style={{ width: 100 }}
            name="duration"
            label="Time to cook" 
            value={duration}
            onChange={(event) => {
                setDuration(parseInt(event.target.value));
                setUnsaved(true);
            }}
        />
        <Card style={{width: 150, display: 'inline-block'}}>
            <ButtonSelect selectedType={selectedType} buttonText="+ Meal Type" onChange={(event) => {
                const newTypes = [...types];
                newTypes.push(event.target.value);
                setTypes(newTypes);
                setUnsaved(true);
            }}>
                {Object.keys(MealType).map((type) => <MenuItem key={type} value={type}>{type}</MenuItem>)}
            </ButtonSelect>
            {types.map((type, index) => <Chip
                key={type}
                label={type}
                onDelete={() => deleteType(index)}
            />)}
        </Card>
        <TextField
            name="servings"
            style={{ width: 100 }}
            label="Servings" 
            value={servings}
            onChange={(event) => {
                setServings(parseInt(event.target.value));
                setUnsaved(true);
            }}
        />
        <br/>
        <Button color="primary" onClick={() => setIsEditing(!isEditing)} style={{float:'left'}}>
            <Edit/>
        </Button>
        <EditableMarkdown
            isEditing={isEditing}
            current={description}
            setCurrent={(newDescription) => {
                setDescription(newDescription);
                setUnsaved(true);
            }}
            label="Description"
        />
        <br/>
        <InputTextField
            name="ingredientName"
            label="Ingredient Name"
            style={{width:'45%'}}
            value={currentIngredientName}
            onChangeEvent={(value) => setCurrentIngredientName(value)}
        />
        <InputTextField
            name="ingredientAmount"
            label="Ingredient Amount"
            style={{width:'15%'}}
            value={currentIngredientAmount}
            onChangeEvent={(value) => setCurrentIngredientAmount(parseFloat(value))}
            isNumber={true}
        />
        <Select
            variant="standard"
            style={{width:'15%'}}
            value={currentIngredientUnit}
            label="Unit"
            onChange={(event) => setCurrentIngredientUnit(event.target.value)}>
            {Object.keys(IngredientUnit).map((type) => <MenuItem key={type} value={type}>{type}</MenuItem>)}
        </Select>
        <Button
            style={{width:'20%'}}
            onClick={() => {
                const newIngredient = new Ingredient({
                    amount: currentIngredientAmount,
                    name: currentIngredientName,
                    unit: currentIngredientUnit
                });
                const newIngredients = [ ...ingredients ];
                newIngredients.unshift(newIngredient);
                setIngredients(newIngredients);
                setCurrentIngredientAmount(1);
                setCurrentIngredientName('');
                setCurrentIngredientUnit('');
                setUnsaved(true);
            }}
        >
            Add
        </Button>
        <br/>
        {ingredients.map((ingredient, index) => {
            const color = getIngredientColor(fridgeNameToAmount, ingredient);
            const ingName = ingredient.name.toLowerCase();
            const ingAmount = fridgeNameToAmount[ingName] < ingredient.amount 
                ? fridgeNameToAmount[ingName]+'/'+ingredient.amount
                : ingredient.amount;
            const ingUnits = !ingredient.unit ? 'x' : IngredientUnit[ingredient.unit];
            return <Chip
                style={{ backgroundColor: color }}
                key={ingName}
                label={ingAmount + ingUnits + ' ' + ingName}
                onDelete={() => deleteIngredient(index)}
            />;
        })}
        <br/>
        {servings && servings > 1
            ? <div data-testid={testIds.nutritionInfo}>
                <p style={{fontSize: '20px', padding:'0px', margin: '0px'}}>Nutrition Info</p>
                <p style={{fontSize: '15px', padding:'0px', margin: '0px'}}>Calories: {nutrititionInfo.totalCal/servings}g per serving</p>
                <p style={{fontSize: '15px', padding:'0px', margin: '0px'}}>Total Fat: {nutrititionInfo.totalFat/servings}g per serving</p>
                <p style={{fontSize: '15px', padding:'0px', margin: '0px'}}>Protein: {nutrititionInfo.totalProtein/servings}g per serving</p>
                <p style={{fontSize: '15px', padding:'0px', margin: '0px'}}>Sugar: {nutrititionInfo.totalSugar/servings}g per serving</p>
            </div>
            :<>
                <p style={{fontSize: '20px', padding:'0px', margin: '0px'}}>Nutrition Info</p>
                <p style={{fontSize: '15px', padding:'0px', margin: '0px'}}>Calories: {nutrititionInfo.totalCal}g</p>
                <p style={{fontSize: '15px', padding:'0px', margin: '0px'}}>Total Fat: {nutrititionInfo.totalFat}g</p>
                <p style={{fontSize: '15px', padding:'0px', margin: '0px'}}>Protein: {nutrititionInfo.totalProtein}g</p>
                <p style={{fontSize: '15px', padding:'0px', margin: '0px'}}>Sugar: {nutrititionInfo.totalSugar}g</p>
            </>
        }
    </Card>;
}