import React, { useCallback, useMemo, useRef } from 'react';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Tooltip } from '@mui/material';
import { useState } from 'react';
import confetti from 'canvas-confetti';
import { Block } from '@mui/icons-material';
import BlockedModal from './BlockedModal';
import { getBlockedIconColor } from 'helpers/ColorHelper';
import { testIds } from 'constants/TestConstants';
import { DeleteButton } from 'common/DeleteButton';

type Props = {
    completed: boolean|string|null,
    blocked?: boolean,
    handleConfigChange: (name: string, value: string|boolean|null) => void,
    scheduledDate: string|boolean,
    deleteTask: () => void,
    showBlocked?: boolean,
    deleteDisabled?: boolean
    pushError: (error: string) => void,
    blockedOnName?: string|null|undefined,
    completionEffectEnabled?: boolean|null|undefined,
    onComplete: () => void,
    taskId: string
}

export default function TaskIconButtons({
    completed,
    blocked = false,
    handleConfigChange,
    scheduledDate,
    deleteTask,
    showBlocked = true,
    deleteDisabled = false,
    pushError,
    blockedOnName,
    completionEffectEnabled,
    onComplete,
    taskId
}: Props) {
    const [ localCompleted, setLocalCompleted ] = useState(completed);
    const [ localBlocked, setLocalBlocked ] = useState(blocked);
    const [ blockModalOpen, setBlockModalOpen ] = useState(false);
    const completeButtonRef = useRef<HTMLButtonElement | null>(null);

    const complete = useCallback(() => {
        if (completionEffectEnabled !== false) {
            const originX = completeButtonRef.current 
                ? completeButtonRef.current.getBoundingClientRect().x/document.documentElement.clientWidth
                : 0.5;
            const originY = completeButtonRef.current 
                ? completeButtonRef.current.getBoundingClientRect().y/document.documentElement.clientHeight + 0.1 
                : 0.5;
            confetti({
                origin: {
                    x: originX,
                    y: originY,
                }
            });
        }

        handleConfigChange('completed', scheduledDate || 'true');
        setLocalCompleted(scheduledDate || 'true');
        onComplete();
    }, [ completeButtonRef, localCompleted ]);

    const block = useCallback((taskId: string) => {
        handleConfigChange('blockedOn', taskId);
        setLocalBlocked(true);
        setBlockModalOpen(false);
    }, []);

    const unblock = useCallback(() => {
        handleConfigChange('blockedOn', '');
        setLocalBlocked(false);
        setBlockModalOpen(false);
    }, []);

    const blockColor = useMemo(() => getBlockedIconColor(localBlocked), [localBlocked]);

    const blockButton = useMemo(() => {
        const button = <IconButton
            style={{ color: blockColor }}
            onClick={() => {
                if (localBlocked) {
                    unblock();
                } else {
                    setBlockModalOpen(true);
                }
            }}
            size="large">
            <Block/>
        </IconButton>;

        if (blockedOnName) {
            return <Tooltip title={`Blocked on ${blockedOnName}`}>{button}</Tooltip>;
        } else {
            return button;
        }
    }, [ blockedOnName, localBlocked ]);
    
    return <>
        {blockModalOpen &&
            <BlockedModal
                handleClose={() => setBlockModalOpen(false)}
                blockTask={(taskId: string) => block(taskId)}
                pushError={pushError}
                currentTaskId={taskId}
            />
        }
        <DeleteButton
            deleteDisabled={deleteDisabled}
            tooltip={deleteDisabled ? 'Remove all subtasks before deleting.' : null}
            deleteFunc={deleteTask}
        />
        {!localCompleted
            ? <IconButton ref={completeButtonRef} onClick={() => complete()} size="large" data-testid={testIds.completeButton}>
                <DoneIcon/>
            </IconButton>
            : <IconButton
                onClick={() => {
                    handleConfigChange('completed', null);
                    setLocalCompleted(null);
                }}
                size="large"
                data-testid={testIds.uncompleteButton}
            >
                <CloseIcon/>
            </IconButton>}
        {showBlocked && blockButton}
    </>;
}