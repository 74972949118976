import { CalendarSortTypes } from 'constants/PreferenceConstants';
import { taskPriorities } from 'constants/TaskConstants';
import { isDueDateClose } from 'helpers/TaskHelpers';
import { Goal, GoalType, SortEntry, Task } from 'models';
import { dueDateComparator, durationComparator } from 'tasks/taskCommon/TaskComparator';

type GroupedTasks = {
    [index: string|number]: Task[]
}

export function getSortedTasks(tasks: Task[],
    sortEntries: (SortEntry|null)[],
    today: string,
    goals?: Goal[])
{

    // Copy so that nothing silly happens with recursion
    const newSortEntries = sortEntries && sortEntries.length > 0 ? [ ...sortEntries ] : [];
    const newTasks = [ ...tasks ];
    
    // 1. pop off top sort order
    const sortEntry = newSortEntries.shift();
    // 2. group tasks by sort order
    const groupedTasks = getGroupedTasks(sortEntry, newTasks, today, goals);
    // 3. sort groups by sort order
    const sortedGroups = sortGroups(sortEntry, Object.keys(groupedTasks));
    
    if (newSortEntries.length === 0) {
        // convert grouped tasks back to list and return
        const sortedArray: Task[] = [];
        sortedGroups.forEach(group => {
            if (groupedTasks[group]) {
                groupedTasks[group].forEach(task => sortedArray.push(task));
            }
        });
        return sortedArray;
    } else {
        // convert grouped tasks back to list and return
        const sortedArray: Task[] = [];
        sortedGroups.forEach(group => {
            // for each grouped tasks, call getSortedTasks
            if (groupedTasks[group]) {
                const thisGroup = getSortedTasks(groupedTasks[group], newSortEntries, today, goals);
                thisGroup.forEach(task => sortedArray.push(task));
            }
        });
        return sortedArray;
    }
}

function getGroupedTasks(sortEntry: SortEntry|null|undefined, tasks: Task[], today: string, goals?: Goal[]): GroupedTasks {
    const validSortType = sortEntry && sortEntry.type ? sortEntry.type : null;
    
    switch(validSortType) {
    case CalendarSortTypes.PRIORITY: 
        return groupByPriority(tasks, today);
    case CalendarSortTypes.DURATION: 
        return groupByDuration(tasks);
    case CalendarSortTypes.DUEDATE: 
        return groupByDueDate(tasks);
    case CalendarSortTypes.GOALS: 
        return groupByGoals(tasks, goals);
    case CalendarSortTypes.AMOUNTSCHEDULED: 
        return groupByAmountScheduled(tasks);
    default:
        console.error('Sort Type not found');
        return { 'none': tasks };
    }
}

function sortGroups(sortEntry: SortEntry|null|undefined, groups: string[]) : string[] {
    const validSortType = sortEntry && sortEntry.type ? sortEntry.type : null;

    switch(validSortType) {
    case CalendarSortTypes.PRIORITY:
        return prioritySortOrder(sortEntry?.ascending);
    case CalendarSortTypes.DURATION:
        return sortEntry?.ascending ? groups.sort(durationComparator).reverse() : groups.sort(durationComparator);
    case CalendarSortTypes.DUEDATE:
        return sortEntry?.ascending ? groups.sort(dueDateComparator).reverse() : groups.sort(dueDateComparator);
    case CalendarSortTypes.GOALS:
        return priorityGroup(sortEntry?.ascending);
    case CalendarSortTypes.AMOUNTSCHEDULED:
        return sortEntry?.ascending ? groups.sort() : groups.sort().reverse();
    default:
        console.error('Sort Type not found');
        return groups;
    }
}

function groupByPriority(tasks: Task[], today: string) {
    const highPriorityTasks = tasks.filter(task =>
        (task.priority && task.priority === taskPriorities.HIGH) 
        || (isDueDateClose(task, today))
    );                  
    const highTaskIds = highPriorityTasks.map(task => task.id);

    const mediumPriorityTasks = tasks.filter(task => 
        !highTaskIds.includes(task.id)
        && task.priority && task.priority === taskPriorities.MEDIUM
    );
    const mediumTaskIds = mediumPriorityTasks.map(task => task.id);

    const lowPriorityTasks = tasks.filter(task =>
        !highTaskIds.includes(task.id)
        && !mediumTaskIds.includes(task.id)
        && task.priority && task.priority === taskPriorities.LOW
    );
    const lowTaskIds = lowPriorityTasks.map(task => task.id);
    
    // Check for tasks not included in the list above
    const foundTaskIds = new Set([ ...highTaskIds, ...mediumTaskIds, ...lowTaskIds ]);
    const unknownTasks = tasks.filter(task => !foundTaskIds.has(task.id));

    return {
        [taskPriorities.HIGH]: highPriorityTasks,
        [taskPriorities.MEDIUM]: mediumPriorityTasks,
        [taskPriorities.LOW]: lowPriorityTasks,
        Unknown: unknownTasks
    };
}

function groupByDuration(tasks: Task[]): GroupedTasks {
    return tasks.reduce((prev, current) => {
        const duration = current.duration ? current.duration : -1;
        if (prev[duration]) {
            prev[duration] = [ ...prev[duration], current ];
        } else {
            prev[duration] = [ current ];
        }
        return prev;
    }, {} as GroupedTasks);
}

function groupByDueDate(tasks: Task[]) {
    return tasks.reduce((prev, current) => {
        const dueDate = current.dueDate && current.dueDateDate ? current.dueDateDate : 'null';
        if (prev[dueDate]) {
            prev[dueDate] = [ ...prev[dueDate], current ];
        } else {
            prev[dueDate] = [ current ];
        }
        return prev;
    }, {} as GroupedTasks);
}

function groupByGoals(tasks: Task[], goals?: Goal[]) {
    const tempTasks = [ ...tasks ];

    if (!goals) {
        return {
            ['None']: tempTasks
        };
    }

    // 1. create list for each goal type
    const goalTasks = {
        [GoalType.DAY]: [],
        [GoalType.WEEK]: [],
        [GoalType.MONTH]: [],
        [GoalType.YEAR]: [],
    } as GroupedTasks;
    // 2. iterate through each goal
    goals.forEach((goal) => {
        goal.associatedTasks && goal.associatedTasks.forEach((taskId) => {
            const taskIndex = tempTasks.findIndex((task) => task.id === taskId);
            // if not gound index will be -1
            if (taskIndex !== -1) {
                goalTasks[goal.type].push({ ...tempTasks[taskIndex] });
                tempTasks.splice(taskIndex, 1);
            }
        });
    });
    // 3. Return list for each goal
    return {
        ...goalTasks,
        ['None']: tempTasks
    };
}

function groupByAmountScheduled(tasks: Task[]) {
    return tasks.reduce((prev, current) => {
        const amountScheduled = current.datesAssigned ? current.datesAssigned.length : 0;
        if (prev[amountScheduled]) {
            prev[amountScheduled] = [ ...prev[amountScheduled], current ];
        } else {
            prev[amountScheduled] = [ current ];
        }
        return prev;
    }, {} as GroupedTasks);
}

function prioritySortOrder(ascending: boolean|undefined|null) {
    return ascending === true
        ? [taskPriorities.LOW, taskPriorities.MEDIUM, taskPriorities.HIGH, 'Unknown']
        : [taskPriorities.HIGH, taskPriorities.MEDIUM, taskPriorities.LOW, 'Unknown'];
}

function priorityGroup(ascending: boolean|undefined|null) {
    return ascending === false
        ? [GoalType.YEAR, GoalType.MONTH, GoalType.WEEK, GoalType.DAY, 'None']
        : [GoalType.DAY, GoalType.WEEK, GoalType.MONTH, GoalType.YEAR, 'None'];
}