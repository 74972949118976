import React, { useState } from 'react';
import { SettingsCard } from 'preferences/Common/SettingsCard';
import { InputTextField } from 'common/InputTextField';
import TimeWindowInput from 'preferences/TimeWindowInput';
import { AmountPerTimeframe, Preference, SortEntry, TimeframeType } from 'models';
import { MenuItem, Select } from '@mui/material';
import { DayOfWeek } from 'models';
import Prioritization from 'preferences/Prioritization';

type Props = {
    preferences: Preference,
    updatePreferences: (field: string, value: string|number|SortEntry[]|AmountPerTimeframe) => void,
    isBetaUser: boolean
}

export default function SchedulingPreferences({
    preferences,
    updatePreferences,
    isBetaUser
}: Props) {
    const [currentStartOfWeek, setCurrentStartOfWeek] = useState(preferences.startOfWeek ? DayOfWeek[preferences.startOfWeek] : null);

    return <div style={{ width: '75%'}}>
        <h1>Time Preferences</h1>
        <h3>Scheduling Window</h3>
        Sets the time window when the scheduler will schedule tasks, any time outside of this window will not schedule tasks.
        <div style={{display: 'flex'}}>
            <SettingsCard>
                <TimeWindowInput
                    setStartTime={(startTime) => {
                        updatePreferences('startTime', startTime);
                    }}
                    setEndTime={(endTime) => {
                        updatePreferences('endTime', endTime);
                    }}
                    startTime={preferences.startTime || '00:00'} 
                    endTime={preferences.endTime || '23:99'}
                    title="Scheduling Time Window"
                />
            </SettingsCard>
        </div>
        <h3>Start of Week</h3>
        Used for determining which week day should be considered the start of the week (Only used for goals at the moment).
        <div style={{display: 'flex'}}>
            <SettingsCard>
                <Select
                    variant="standard"
                    value={currentStartOfWeek ? currentStartOfWeek : DayOfWeek.MONDAY}
                    onChange={(event) => {
                        updatePreferences('startOfWeek', event.target.value);
                        const newDayOfWeek : DayOfWeek = DayOfWeek[event.target.value as keyof typeof DayOfWeek];
                        setCurrentStartOfWeek(newDayOfWeek);
                    }}>
                    {Object.keys(DayOfWeek).map(dayOfWeek => <MenuItem key={dayOfWeek} value={dayOfWeek}>{dayOfWeek}</MenuItem>)}
                </Select>
            </SettingsCard>
        </div>
        <h3>Breaks</h3>
        Sets details about when the scheduler should create breaks. Keep empty/set to 0 to have no breaks.
        <div style={{display: 'flex'}}>
            <SettingsCard>
                <InputTextField
                    name="breakFrequency"
                    label="How often (in minutes)" 
                    value={preferences.breakFrequency}
                    onChangeEvent={(value) => updatePreferences('breakFrequency', value)}
                    isNumber={true}
                />
            </SettingsCard>
            <SettingsCard>
                <InputTextField
                    name="breakTime"
                    label="How long of breaks (in minutes)" 
                    value={preferences.breakTime}
                    onChangeEvent={(value) => updatePreferences('breakTime', value)}
                    isNumber={true}
                />
            </SettingsCard>
        </div>
        <h1>Task Preferences</h1>
        <h3>Archive Time</h3>
        Sets the days before the scheduler will automatically archive completed tasks. Archiving does not delete the tasks from the system but removes them from your dashboards.
        <div style={{display: 'flex'}}>
            <SettingsCard>
                <InputTextField
                    name="archiveDays"
                    label="Days until completed tasks are archived" 
                    value={preferences.archiveTime || 3}
                    onChangeEvent={(value) => updatePreferences('archiveTime', parseInt(value))}
                    isNumber={true}
                />
            </SettingsCard>
        </div>
        {isBetaUser &&<>
            <h3>Coins per 30 minutes</h3>
            Configure how many coins per 30 minutes of task time should be given on completion of task.
            <div style={{display: 'flex'}}>
                <SettingsCard>
                    <InputTextField
                        name="coinsPerTask"
                        label="Coins per task" 
                        value={preferences.coinsPerTask ? preferences.coinsPerTask.amount : 1}
                        onChangeEvent={(value) => updatePreferences('coinsPerTask', {
                            amount: parseInt(value),
                            timeFrameType: TimeframeType.MINUTE,
                            timeFrameValue: 30
                        })}
                        isNumber={true}
                    />
                </SettingsCard>
            </div>
        </>}
        <h1>Prioritization</h1>
        Configure how the scheduler prioritizes tasks when scheduling them. 
        <div style={{display: 'flex'}}>
            <Prioritization
                preferences={preferences}
                updatePreferences={updatePreferences}
            />
        </div>
    </div>;
}
