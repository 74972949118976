import { DataStore } from 'aws-amplify/datastore';
import { taskTypes } from 'constants/TaskConstants';
import { lastRepeatedScheduledDate } from 'helpers/TaskHelpers';
import { Daily, Task } from 'models';
import { generateRanks, generateTaskRank } from './TaskRankCommon';

export function handleConfigChange(variableName, value, changeTask, state={}, today) {
    const newTask = {};

    if (variableName === 'daily') {
        newTask.daily = handleDaily(state, value);
    } else if (variableName === 'type' && value === taskTypes.SCHEDULED) {
        newTask.daily = handleDaily(state, false);
        newTask.scheduledDate = today;
        newTask[variableName] = value;
    } else if (variableName === 'completed' && state.type === taskTypes.SCHEDULED
                    && state.frequencyInDays) {
        const lastDate = lastRepeatedScheduledDate(state.scheduledDate,
            state.frequencyInDays, today);
        newTask.daily = handleDailyCompleted(state, lastDate, value);
    } else if (variableName === 'completed' && state.daily && state.daily.enabled) {
        newTask.daily = handleDailyCompleted(state, today, value);
    } else {
        newTask[variableName] = value;
    }

    changeTask(newTask);
    return newTask;
}

function handleDaily(state, value) {
    if (!state.daily || state.daily.enabled === undefined) {
        return {
            enabled: value,
            dateTimes: {},
        };
    } else {
        return {
            ...state.daily,
            enabled: value
        };
    }
}

export function handleDailyCompleted(state, today, value) {
    const dateTimes = state.daily.dateTimes ? state.daily.dateTimes : {};

    const newDayConfig = dateTimes[today] ?
        {
            ...dateTimes[today],
            completed: value
        }
        : {
            completed: value
        };

    return {
        ...state.daily,
        dateTimes: {
            ...dateTimes,
            [today]: newDayConfig
        }
    };
}

export function generateTaskMap(tasks, saveTasks, pushError) {
    var rankMissing = false;

    // Loop 1: Get tasks by id for easy reference, and check if ranks are missing
    const workingTasksById = tasks.reduce(function(acc, task) {
        if (!task.rank) {
            rankMissing = true;
        }

        acc[task.id] = task;
        return acc;
    }, {});

    const processedTasks = [];
    const workingTaskMap = {};
    const workingCategoryMap = {};

    // We dont want completed tasks here
    tasks.forEach((task) => {
        // Generating task map node
        if (!processedTasks.includes(task.id)) {
            const taskMapResponse = generateTaskMapNode(task, workingTaskMap, processedTasks, workingTasksById);
            workingTaskMap[task.id] = taskMapResponse.thisTaskMap;
            taskMapResponse.tasksToRemove.forEach((taskId) => {
                delete workingTaskMap[taskId];
            });
            processedTasks.push(...taskMapResponse.tasksProcessed);
        }

        // Adding to category map
        if (!task.completed && isTaskGroomed(task)) {
            const category = task.category || 'None';
            workingCategoryMap[category] = workingCategoryMap[category]
                ? [ ...workingCategoryMap[category], task.id]
                : [ task.id ];
        }
    });

    // Once we have taskMap, if there was a missing rank lets generate ranks
    if (rankMissing) {
        console.log('Rank missing, generating new ranks');
        // Generate ranks for total task size
        const ranks = generateRanks(tasks.length);
        const rankUpdates = {};

        // Javascript is pass by reference for objects
        var indexObj = { index: 0 };
        tasks.forEach((task) => {
            // Set rank in task id to task map
            addRankToTask(workingTaskMap, workingTasksById, task, ranks, indexObj, rankUpdates);
            // If task map has results interate through results
        });
        // Save rank updates
        saveTasks(rankUpdates, pushError);
    }

    return {
        tasksById: workingTasksById,
        taskMap: workingTaskMap,
        categoryMap: workingCategoryMap
    };
}

export function generateTaskMapNode(task, workingTaskMap, processedTasks, tasksById) {
    // Adding this cus i made an oopsy where a task could have a subtask of itself
    // Theoretically shouldnt happen after thats fixed
    const subTasks = task.subTasks ? task.subTasks.filter((taskId) => taskId !== task.id)
        : null;

    if (subTasks && subTasks.length > 0) {
        const tasksToRemove = [];
        const tasksProcessed = [ task.id ];
        const thisTaskMap = {};
        task.subTasks.forEach((subTaskId) => {
            if (tasksById[subTaskId]) {
                const subTask = tasksById[subTaskId];
                // If task has already been processed, just get the node from the map
                if (processedTasks.includes(subTask.id)) {
                    thisTaskMap[subTask.id] = workingTaskMap[subTask.id];
                    tasksToRemove.push(subTask.id);
                    // If it hasnt generate the node
                } else {
                    const subTaskResponse = generateTaskMapNode(subTask, workingTaskMap, processedTasks, tasksById);
                    thisTaskMap[subTask.id] = subTaskResponse.thisTaskMap;
                    tasksProcessed.push(...subTaskResponse.tasksProcessed);
                }
            } else {
                console.log(`Subtask does not exist with id ${subTaskId}. This is likely due to the task being deleted.`);
            }
        });
        return { tasksToRemove, tasksProcessed, thisTaskMap};
    } else {
        return { tasksToRemove: [], tasksProcessed: [ task.id ], thisTaskMap: {} };
    }
}

export function isTaskGroomed(task) {
    return (task.duration && task.priority)
    || task.type === taskTypes.SCHEDULED
    || (task.subTasks && task.subTasks.length > 0);
}

export function addRankToTask(workingTaskMap, tasksById, task, ranks, currentIndex, taskUpdates) {
    if (workingTaskMap[task.id]) {
        tasksById[task.id].rank = ranks[currentIndex.index];
        taskUpdates[task.id] = { rank: ranks[currentIndex.index] };
        currentIndex.index = currentIndex.index + 1;
        if (Object.keys(workingTaskMap[task.id]).length > 0) {
            // TODO get order for subtasks
            Object.keys(workingTaskMap[task.id]).forEach((taskId) => {
                addRankToTask(
                    workingTaskMap[task.id],
                    tasksById,
                    tasksById[taskId],
                    ranks,
                    currentIndex,
                    taskUpdates
                );
            });
        }
    }
}

export function formatTasks(tasks) {
    return tasks.map(task => {
        // Get the actual task for blocked tasks
        let blockedOnTask;
        if (task.blockedOn && task.blockedOn != '') {
            blockedOnTask = tasks.find((otherTask) => otherTask.id == task.blockedOn);
        }

        return ({
            ...task,
            blockedOnTask,
            daily: {
                ...task.daily,
                dateTimes: JSON.parse(task.daily.dateTimes),
            }
        });
    });
}

// Amplify does not have a way to just save plain json, so dailys are a string
export function convertDailys(tasks) {
    return tasks.map(task => ({
        ...task,
        daily: {
            ...task.daily,
            dateTimes: JSON.parse(task.daily.dateTimes),
        }
    }));
}

export function validateTasks(taskUpdates) {
    const validationErrors = [];
    Object.values(taskUpdates).forEach((task) => {
        if (task.type === taskTypes.SCHEDULED && !task.scheduledTime) {
            validationErrors.push('Task is a scheduled task without a scheduled time.');
        }

        if (task.dueDate && (!task.dueDateDate || task.dueDateDate.length < 1)) {
            validationErrors.push('Task has due date enabled but no due date.');
        }
    });

    return validationErrors;
}

export async function createNewTask(user, pushError, taskAbove, taskBelow) {
    // TODO For now, until we can gaurantee topTask will be populated correctly
    //  only populate taskRank if its passed. Eventually should remove this so that
    //  accounts with zero tasks still get ranked
    var taskRank;
    if (taskBelow || taskAbove) {
        const rankAbove = taskAbove && taskAbove.rank ? taskAbove.rank : 'aaa';
        const rankBelow = taskBelow && taskBelow.rank ? taskBelow.rank : 'zzz';
        taskRank = generateTaskRank(rankAbove, rankBelow);
    }

    const daily = new Daily({
        enabled: false,
        dateTimes: '{}',
    });
    const newTask = {
        taskName: '',
        type: taskTypes.ASAP,
        daily,
        user,
        rank: taskRank
    };
    const newTaskObj = new Task(newTask);
    return DataStore.save(newTaskObj)
        .then(() => {
            return { ...newTaskObj, daily: {
                enabled: false,
                dateTimes: {}
            }};
        })
        .catch((error) => pushError(error));

}

// Dunno if DFS or BFS is better
// Returns list from id to top level
export function findTaskInMapDFS(id, taskMap, index = 0) {
    const currentLayerIds = Object.keys(taskMap);
    // Does taskMap include if in keys
    if (currentLayerIds.includes(id)) {
        return [ id ];
    } else {
        var newResult;
        currentLayerIds.forEach((layerId) => {
            const result = findTaskInMapDFS(id, taskMap[layerId], index + 1);
            if (result) {
                result.push(layerId);
                newResult = result;
                return;
            }
        });
        return newResult;
    }
}

export function getTaskMapLayerFromId(id, taskMap) {
    const currentLayerIds = Object.keys(taskMap);
    // Does taskMap include if in keys
    if (currentLayerIds.includes(id)) {
        return taskMap;
    } else {
        var newResult;
        currentLayerIds.forEach((layerId) => {
            const result = getTaskMapLayerFromId(id, taskMap[layerId]);
            if (result) {
                newResult = result;
                return;
            }
        });
        return newResult;
    }
}

export function removeTaskFromParent(id, parentTaskId, parentTaskIndex, taskById, changeTask, newLocalTasks) {
    const parentSubtasks = taskById[parentTaskId].subTasks.filter((taskId) => taskId !== id);
    changeTask(parentTaskId, { subTasks: parentSubtasks });
    newLocalTasks.splice(parentTaskIndex, 1, { ...taskById[parentTaskId], subTasks: parentSubtasks });
}