import React, { useState } from 'react';
import { Button, Select, SelectChangeEvent } from '@mui/material';

type Props = {
    selectedType: string,
    onChange: (event: SelectChangeEvent) => void,
    buttonText: string,
    children: string | JSX.Element | JSX.Element[]
};

export function ButtonSelect({ selectedType, onChange, buttonText, children }: Props) {
    const [ selectOpen, setSelectOpen ] = useState(false);

    return <div>
        <Button onClick={() => setSelectOpen(!selectOpen)}>
            {buttonText}
        </Button>
        <Select
            open={selectOpen}
            value={selectedType}
            style={{ display: 'none !important', visibility: 'hidden', position: 'absolute' }}
            onClose={() => setSelectOpen(false)}
            onChange={onChange}>
            {children}
        </Select>
    </div>;
}