import { calculateMinutesFromTime } from 'helpers/TimeHelper';

export function startTimeComparator(a, b) {
    const startTimeMinutesA = calculateMinutesFromTime(a.startTime || '00:00');
    const startTimeMinutesB = calculateMinutesFromTime(b.startTime || '00:00');
    if (startTimeMinutesA == startTimeMinutesB) {
        const scheduledEndTimeA = calculateMinutesFromTime(a.endTime || '00:00');
        const scheduledEndTimeB = calculateMinutesFromTime(b.endTime || '00:00');
        const durationA = scheduledEndTimeA - startTimeMinutesA;
        const durationB = scheduledEndTimeB - startTimeMinutesB;
        return durationB - durationA;
    }
    return startTimeMinutesA - startTimeMinutesB;
}