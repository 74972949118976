import React, { useState } from 'react';
import { Card } from '@mui/material';
import { getTaskColor } from '../helpers/ColorHelper';
import TaskIconButtons from './taskComponents/TaskIconButtons';
import { handleConfigChange } from './taskCommon/ConfigCommon';
import { Task } from 'models';

type Props = {
    task: Task,
    deleteTask: () => void,
    changeTask: (name: string, value: string|number|null) => void,
    pushError: (error: string) => void,
    blockedOnTask?: Task
}

export default function CompletedTask({ task, deleteTask, changeTask, pushError, blockedOnTask }: Props) {
    const [ stateTask, setStateTask ] = useState(task);

    return <Card style={{minWidth:200, minHeight:20, padding:5, margin:5, backgroundColor: getTaskColor({ ...stateTask, blockedOnTask })}}>
        <TaskIconButtons
            completed={true}
            deleteTask={deleteTask}
            handleConfigChange={(variableName: string, value: string|boolean|null) => {
                setStateTask({ ...stateTask, [variableName]: value });
                handleConfigChange(variableName, value, changeTask, stateTask);
            }}
            scheduledDate={true} // Since completed variable gets set to this we can just set to true for completed task
            pushError={pushError}
            blockedOnName={blockedOnTask ? blockedOnTask.taskName : null}
            completionEffectEnabled={false} // TODO Does it make sense to just not have completion effects for already completed tasks
            onComplete={() => {
                // Nothing right now :(
            }}
            taskId={task.id}
        />
        {stateTask.taskName}
    </Card>;
}