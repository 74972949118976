import { Chip } from '@mui/material';
import { testIds } from 'constants/TestConstants';
import React from 'react';

export function BetaChip() {
    return <Chip
        label="Beta User"
        style ={{ backgroundColor: '#F06058', float: 'left'}}
        data-testid={testIds.betaChip}
    />;
}