import * as React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

type Props = {
    item: string,
    index: number,
    children: string | JSX.Element | JSX.Element[]
};

export default function DraggableListItem({ item, index, children }: Props) {
    return <Draggable draggableId={item+ '-' + index} index={index}>
        {(provided, snapshot) => (
            <ListItem
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={{
                    background: snapshot.isDragging ? 'rgb(235,235,235)' : '',
                    ...provided.draggableProps.style
                }}
            >
                <ListItemText primary={(index + 1) + '. ' + item} />
                {children}
            </ListItem>
        )}
    </Draggable>;
}
