import React from 'react';
import ScheduledInputs from './ScheduledInputs';
import { Ingredient, Preference, Task } from 'models';
import { taskTypes } from 'constants/TaskConstants';
import ASAPInputs from './ASAPInputs';
import GroceryInputs from './GroceryInputs';

type Props = {
    task: Task,
    preferences: Preference,
    isParentTask: boolean,
    handleConfigChange: (name: string, value: Ingredient[]|string|boolean) => void
}

export default function TaskTypeInputs({ task, preferences, isParentTask, handleConfigChange }: Props) {
    const {
        type,
        scheduledDate,
        scheduledTime,
        frequencyInDays,
        daily,
        priority,
        category,
        dueDate,
        dueDateDate,
        ingredients
    } = task;

    // No type inputs if parent task
    if (isParentTask) {
        return <></>;
    }

    if (type === taskTypes.SCHEDULED) {
        return <ScheduledInputs
            scheduledDate={scheduledDate}
            scheduledTime={scheduledTime}
            frequencyInDays={frequencyInDays}
            handleConfigChange={handleConfigChange}
        />;
    } else if(!type || type === taskTypes.ASAP) {
        return <ASAPInputs
            handleConfigChange={handleConfigChange}
            daily={daily}
            priority={priority}
            categoryId={category}
            preferences={preferences}
            dueDate={dueDate}
            dueDateDate={dueDateDate}
        />;
    } else if(type === taskTypes.GROCERY) {
        return <GroceryInputs
            taskIngredients={ingredients}
            priority={priority}
            handleConfigChange={handleConfigChange}
        />;
    } else {
        return <></>;
    }
}